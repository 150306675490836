import { Link, useLocation } from "react-router-dom";
import { AuthData } from "./AuthWrapper";
// import { presidentEmail, recruitingEmail, board, interviewCommittee, roundNames } from "../data/data";
import { ad_logo, ad_logo_stacked_front } from "../data/svg";
import { navBar } from "../data/text";
import { roundNames } from "../data/data";

export const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const { logout } = AuthData();
  const { user } = AuthData();

  return (
    <main>
      <div className="flex flex-col px-4 lg:px-8">
        <Link to="/" className="absolute w-full flex justify-center -mx-4 lg:hidden">
          <div className="size-9 pt-10">{ad_logo}</div>
        </Link>
        <Link to="/" className="flex items-center absolute hidden lg:flex pt-10 space-x-4">
          <div className="size-14 flex justify-center">{ad_logo}</div>
          <div className="w-[88px]">{ad_logo_stacked_front}</div>
        </Link>
        <div className="flex flex-col space-y-3 justify-center h-screen">
          {navBar.map(
            (page, idx) => (
              <div
                key={idx}
                onClick={page['page'] === "Sign Out" ? logout : null}
                className={`${page['access'].has(user.position) 
                  || (page['route'] === '/applicant-schedule' && user.position === "Applicant" && user.round > 0 && roundNames[user.round].includes("Interview")) 
                  || ((page['route'] === "/member-schedule" || page['route'] === "/applicantgrading") && user.position !== "Applicant" && page['people'].has(user.email.split('@')[0])) 
                  ? '' : 'hidden'}`}
              >
                <Link to={page['route']} className="group flex items-center space-x-4 justify-center lg:justify-start">
                  <div className="relative w-8 h-10 flex items-center justify-center">
                    <div className={`absolute opacity-100 group-hover:opacity-0 duration-200 text-ad-dark ${pathname === page['route'] ? "hidden" : ""}`}>{page['icon']}</div>
                    <div className={`absolute ${pathname === page['route'] ? 'opacity-100 text-ad-dark group-hover:text-ad-blue' : 'text-ad-blue'} opacity-0 group-hover:opacity-100 duration-200 `}>{page['hoverIcon']}</div>
                  </div>
                  <div className={`uppercase font-kanit text-ad-dark group-hover:text-ad-blue duration-200 hidden lg:flex ${pathname === page['route'] ? 'font-bold' : ''}`}>{page['page']}</div>
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </main>
  );
};
