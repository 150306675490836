import React from "react";
import { Outlet } from "react-router-dom";
import { AuthWrapper } from "./AuthWrapper";

export const Layout = () => {
  return (
    <AuthWrapper>
      <Outlet />
    </AuthWrapper>
  );
};
