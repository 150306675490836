import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getApplicantsByRound } from "../libs/firebaseCalls";
import { Loading } from "./Loading.js";
import {
  getExecutive,
  continueApplicants,
  delayApplicants,
  rejectApplicants,
  bringBackApplicant,
} from "../libs/firebaseCalls";
import { IoFlag } from "react-icons/io5";
import { Comments } from "./Comments";
import "../css/Search.css";
import "../css/BoardPage.css";
import { Popup } from "./Popup.js";
import { ViewFlags } from "./ViewFlags.js";
import { MovedOn } from "./MovedOn.js";

export const Selection = () => {
  const [applicants, setApplicants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selects, setSelects] = useState([]);
  const [exec, setExec] = useState(null);
  const [maxes, setMaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addCont, setAddCont] = useState(0);
  const [addRej, setAddRej] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [bringBackUniqname, setBringBackUniqname] = useState("");
  const [popupLoading, setPopupLoading] = useState(false);
  const [popupErrorMessage, setPopupErrorMessage] = useState("");
  const [isComments, setIsComments] = useState(false);
  const [commentApplicant, setCommentApplicant] = useState("");
  const [commentRound, setCommentRound] = useState("");
  const [actionListOpen, setActionListOpen] = useState(false);
  const [flagsOpen, setFlagsOpen] = useState(false);
  const [flagIndex, setFlagIndex] = useState(-1);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [classFilter, setClassFilter] = useState("None");
  const [roleFilter, setRoleFilter] = useState("None");
  const [movedOnOpen, setMovedOnOpen] = useState(false);

  const listUpRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const yearClasses = {
    Freshman: currentMonth >= 6
      ? new Set([`Winter ${currentYear + 3}`, `Spring ${currentYear + 4}`, `Winter ${currentYear + 4}`]) //winter
      : new Set([`Winter ${currentYear + 2}`, `Spring ${currentYear + 3}`]), //spring
    Sophomore: currentMonth >= 6
      ? new Set([`Winter ${currentYear + 2}`, `Spring ${currentYear + 3}`])
      : new Set([`Winter ${currentYear + 1}`, `Spring ${currentYear + 2}`]),
    Junior: currentMonth >= 6
      ? new Set([`Winter ${currentYear + 1}`, `Spring ${currentYear + 2}`])
      : new Set([`Winter ${currentYear}`, `Spring ${currentYear + 1}`]),
    Senior: currentMonth >= 6
      ? new Set([`Winter ${currentYear}`, `Spring ${currentYear + 1}`])
      : new Set([`Winter ${currentYear - 1}`, `Spring ${currentYear}`]),
  };

  useEffect(() => {
    const fetchExecutive = async () => {
      setLoading(true);
      const execData = await getExecutive();
      setExec(execData);
      setSortConfig({ key: 'round' + execData.round, direction: 'descending' })
    };
    fetchExecutive();
  }, []);

  useEffect(() => {
    if (!exec) return;

    const getApps = async () => {
      const applicantList = await getApplicantsByRound(exec.round);
      const updatedApplicantList = await Promise.all(
        applicantList.map(async (app) => ({
          ...app,
          checked: false,
        }))
      );
      setApplicants(updatedApplicantList);
      setLoading(false);
    };

    const findMaxes = () => {
      if (!exec.roundScoringCriteria) return;
      const maxes = Object.values(exec.roundScoringCriteria).map(scoringDetails => {
        return scoringDetails.reduce(
          (curMax, curCriteria) => curMax + curCriteria.max,
          0
        );
      });
      setMaxes(maxes);
    };

    findMaxes();
    getApps();
  }, [exec]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const initialSort = (a, b) => {
    if (a.delay && !b.delay) {
      return 1;
    } else if (!a.delay && b.delay) {
      return -1;
    } else {
      return b.normScores[exec.round] - a.normScores[exec.round];
    }
  };

  const handleSort = (key) => {
    let direction = 'descending';
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedApplicants = () => {
    if (sortConfig.key) {
      return [...applicants].sort((a, b) => {
        if (a.delay && !b.delay) {
          return -1;
        } else if(!a.delay && b.delay) {
          return 1;
        }
        const order = sortConfig.direction === 'ascending' ? 1 : -1;
        if (sortConfig.key.startsWith('round')) {
          const round = parseInt(sortConfig.key.slice(-1));
          return order * (a.normScores[round] - b.normScores[round]);
        } else if (sortConfig.key === "name") {
          const nameA = a.first + a.last;
          const nameB = b.first + b.last;
          if (nameA < nameB) {
            return -order;
          } else if (nameA > nameB) {
            return order;
          } else {
            return 0;
          }
        } else if (sortConfig.key === "class") {
          const seasonA = a.class.split(" ")[0];
          const seasonB = b.class.split(" ")[0];
          const yearA = parseInt(a.class.split(" ")[1]);
          const yearB = parseInt(b.class.split(" ")[1]);
          if (yearA === yearB) {
            if (seasonA === seasonB) {
              return 0;
            } else if (seasonA === "Spring" && seasonB === "Winter") {
              return -order;
            } else {
              return order;
            }
          } else {
            return order * (yearA - yearB);
          }
        } else {
          const valueA = a[sortConfig.key];
          const valueB = b[sortConfig.key];
          if (valueA < valueB) {
            return -order;
          } else if (valueA > valueB) {
            return order;
          } else {
            return 0;
          }
        }
      });
    } else {
      return [...applicants].sort(initialSort);
    }
  };

  const filteredApplicants = getSortedApplicants().filter((applicant) => {
    const fullName = `${applicant.first} ${applicant.last}`.toLowerCase();
    const uniqname = applicant.id.split('@')[0];

    if (roleFilter !== "None") {
      const role = applicant.isAnalyst ? "Analyst" : "Designer";
      if (role !== roleFilter) {
        return false;
      }
    }
    if (classFilter !== "None") {
      if (classFilter === "Freshman/Sophomore") {
        if (!yearClasses["Freshman"].has(applicant.class) && !yearClasses["Sophomore"].has(applicant.class)) {
          return false
        }
      } else {
        if (!yearClasses[classFilter].has(applicant.class)) {
          return false;
        }
      }
    }
    return fullName.includes(searchQuery.toLowerCase()) || uniqname.includes(searchQuery.toLowerCase());
  });

  const toggleContinue = () => {
    continueApplicants(selects);
    setApplicants(filteredApplicants.filter((applicant) => {
      return !selects.includes(applicant.id);
    }));
    setAddCont(addCont + selects.length);
    clear();
  };

  const toggleDelay = () => {
    delayApplicants(selects, applicants);
    setApplicants(filteredApplicants.map((applicant) => {
      if (selects.includes(applicant.id)) {
        applicant.delay = !applicant.delay;
      }
      return applicant;
    }).sort(initialSort));
    clear();
  };

  const toggleReject = () => {
    rejectApplicants(selects);
    setApplicants(filteredApplicants.filter((applicant) => {
      return !selects.includes(applicant.id);
    }));
    setAddRej(addRej + selects.length);
    clear();
  };

  const clear = () => {
    setSelects([]);
    filteredApplicants.forEach((applicant) => {
      applicant.checked = false;
    });
  };

  const select = (applicantId) => {
    const updatedApplicants = applicants.map((item) => {
      if (item.id === applicantId) {
        if (!item.checked) {
          setSelects([...selects, applicantId]);
        } else {
          setSelects(selects.filter((id) => id !== applicantId));
        }
        item.checked = !item.checked;
      }
      return item;
    });
    setApplicants(updatedApplicants);
  };

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const submitReturnApplicant = async () => {
    setPopupLoading(true);
    try {
      const applicantEmail = bringBackUniqname + '@umich.edu';
      const applicantExists = applicants.some(applicant => applicant.id === applicantEmail);
      if (!applicantExists) {
        const newApplicant = await bringBackApplicant(applicantEmail);
        setApplicants((prevApplicants) => [...prevApplicants, newApplicant]);
        if (newApplicant.oldRound < 0) {
          setAddRej((prevAddRej) => prevAddRej - 1);
        } else {
          setAddCont((prevAddCont) => prevAddCont - 1);
        }
        setPopupErrorMessage("");
        setPopupOpen(false);
      } else {
        setPopupErrorMessage("Applicant already in round");
      }
    } catch (e) {
      console.error("Bringing back applicant failed:", e);
      setPopupErrorMessage("Couldn't find applicant uniqname");
    } finally {
      setPopupLoading(false);
    }
  };

  const openIsComment = (email, round) => {
    setCommentApplicant(email.split('@')[0]);
    setCommentRound(round);
    setIsComments(true);
  };

  const toggleActionsOpen = () => {
    setActionListOpen(!actionListOpen);
  };

  const removeFlagOuter = (applicantIndex, flagIndex) => {
    let newApplicants = [...applicants]; // Create a copy of the applicants array
    let newFlags = newApplicants[applicantIndex].flags.filter((_, i) => i !== flagIndex)
    newApplicants[applicantIndex] = {
      ...newApplicants[applicantIndex], // Create a copy of the applicant object
      flags: newFlags // Filter out the flag
    };
    setApplicants(newApplicants);
    if (!newFlags || !newFlags.length) {
      setFlagsOpen(false);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listUpRef.current && !listUpRef.current.contains(event.target) && actionListOpen) {
        setActionListOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionListOpen]);

  const handleClassFilterChange = (selectedOptions) => {
    setClassFilter(selectedOptions);
  };

  const handleRoleFilterChange = (selectedOptions) => {
    setRoleFilter(selectedOptions);
  };

  return (
    <div className="selection-page font-kanit text-ad-dark">
      {loading ? (
        <Loading></Loading>
      ) : (
        <>
          {popupOpen && (
            <Popup>
              {popupLoading ? (
                <Loading customContainerClass={"h-[100%] p-16 flex flex-row justify-center align-center"}></Loading>
              ) : (
                <div className="flex flex-col gap-[20px]">
                  <input
                    type="text"
                    value={bringBackUniqname}
                    onChange={(e) => setBringBackUniqname(e.target.value)}
                    placeholder="Applicant Uniqname"
                    className="p-[10px] border-solid border-grey-700 border-2 rounded-lg"
                  />
                  {popupErrorMessage && (
                    <div style={{ color: "red", fontSize: "20px" }}>
                      <b>{popupErrorMessage}</b>
                    </div>
                  )}
                  <button onClick={submitReturnApplicant}>
                    Bring Back Applicant
                  </button>
                  <button onClick={togglePopup}>Close</button>
                </div>
              )}
            </Popup>
          )}

          {isComments &&  //convert to popup too
            <div>
              <Popup closePopup={() => { setIsComments(false) }} customContainerClass="relative w-[30%] bg-white px-12 py-16 rounded-xl shadow-light overflow-hidden overflow-y-scroll" className="popupContainer min-h-0">
                <Comments
                  applicant={commentApplicant}
                  round={commentRound}
                />
              </Popup>
            </div>
          }

          {movedOnOpen &&
            <>
              <Popup closePopup={() => { setMovedOnOpen(false) }} customContainerClass="relative bg-white px-12 py-16 rounded-xl shadow-light overflow-hidden overflow-y-scroll">
                <MovedOn></MovedOn>
              </Popup>
            </>
          }
          <>
            <div className="stats-container">
              <h2 className="font-kanit text-ad-dark">Continued: {exec && exec.selectionStats && exec.selectionStats[exec.round] && exec.selectionStats[exec.round].continued + addCont}</h2>
              <h2 className="font-kanit text-ad-dark">Rejected: {exec && exec.selectionStats && exec.selectionStats[exec.round] && exec.selectionStats[exec.round].rejected + addRej}</h2>
              <h2 className="font-kanit text-ad-dark">Remaining: {exec && exec.selectionStats && exec.selectionStats[exec.round] && applicants.length - (exec.selectionStats[exec.round].continued + exec.selectionStats[exec.round].rejected)}</h2>
              <h2 className="font-kanit text-ad-dark">Selected: {selects.length}</h2>
              <h2 className="font-kanit text-ad-dark">Delayed: {applicants.filter(applicant => applicant.delay === true).length}</h2>
            </div>
            <div className="search-page-bar-wrapper my-[30px]">
              <input
                className="search-page-bar"
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
                placeholder="Search for people..."
              />
            </div>
            <button onClick={() => setMovedOnOpen(!movedOnOpen)} className="w-[300px]">See Passed Applicants</button>
            <div className="flex flex-row gap-[30px] mb-[10px]">
              <div className="flex flex-row gap-[10px]">
                <label className="flex flex-col justify-center"><b>Filter by Class:</b></label>
                <select value={classFilter} onChange={(e) => handleClassFilterChange(e.target.value)}>
                  <option value={"Freshman"}>
                    Freshman
                  </option>
                  <option value={"Sophomore"}>
                    Sophomore
                  </option>
                  <option value={"Junior"}>
                    Junior
                  </option>
                  <option value={"Senior"}>
                    Senior
                  </option>
                  <option value={"Freshman/Sophomore"}>
                    Freshman/Sophomore
                  </option>
                  <option value={"None"}>
                    None
                  </option>
                </select>
              </div>
              <div className="flex flex-row gap-[10px]">
                <label className="flex flex-col justify-center"><b>Filter by Role:</b></label>
                <select value={roleFilter} onChange={(e) => handleRoleFilterChange(e.target.value)}>
                  <option value={"Analyst"}>
                    Analyst
                  </option>
                  <option value={"Designer"}>
                    Designer
                  </option>
                  <option value={"None"}>
                    None
                  </option>
                </select>
              </div>
              <button className="h-fit w-fit p-[5px]" onClick={() => { setRoleFilter("None"); setClassFilter("None") }}>Clear Filters</button>
            </div>
            <div className="selection-main-content-grid">
              {filteredApplicants.length ? (
                <>
                  <div className="selection-table-header">
                    <div className="table-column">
                      RANK
                    </div>
                    <div className="table-column">
                      PFP
                    </div>
                    <div className="table-column" onClick={() => handleSort('name')}>
                      NAME {sortConfig.key === 'name' && (
                        <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                      )}
                    </div>
                    <div className="table-column" onClick={() => handleSort('class')}>
                      YEAR {sortConfig.key === 'class' && (
                        <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                      )}
                    </div>
                    <div className="table-column" onClick={() => handleSort('isAnalyst')}>
                      ROLE {sortConfig.key === 'isAnalyst' && (
                        <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                      )}
                    </div>
                    {maxes.map((_, idx) => (
                      <div className="table-column" key={idx} onClick={() => handleSort('round' + idx)}>
                        R{idx} {sortConfig.key === 'round' + idx && (
                          <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                        )}
                      </div>
                    ))}
                    <div className="table-column">SELECT</div>
                  </div>
                  <div className="search-applicants-grid">
                    {filteredApplicants.map((applicant, index) => (
                      <div key={index} className="selection-applicant-row" style={{ backgroundColor: applicant.delay && "orange" }}>
                        <>
                          {flagsOpen && flagIndex === index && applicant.flags && (
                            <Popup customContainerClass="popupContent min-w-[500px]">
                              <ViewFlags flags={applicant.flags} applicant={applicant} removeFlagOuter={removeFlagOuter} index={index} />
                              <button onClick={() => {
                                setFlagsOpen(false);
                              }}> &times; </button>
                            </Popup>
                          )}
                        </>
                        <div className="rank">{index + 1}</div>
                        <div className="applicant-info flex flex-row justify-center">
                          <div className="relative">
                            {applicant.flags && applicant.flags.map((flag, idx) => {
                              return (
                                idx < 3 && (
                                  <div
                                    key={idx}
                                    className={`absolute hover:cursor-pointer z-[0]`}
                                    style={{ right: 4 - idx * 4, top: 4 - idx * 4, zIndex: 4 - idx }}
                                    onClick={() => {
                                      setFlagIndex(index)
                                      setFlagsOpen(true);
                                    }}
                                  >
                                    <IoFlag fill={flag.color} stroke="black" strokeWidth={20} size="25px"></IoFlag>
                                  </div>
                                )
                              )
                            })}
                            <a
                              href={applicant.resumeURL}
                              target="_blank"
                              rel="noopener noreferrer">
                              <img
                                src={applicant.pfpURL}
                                alt={"pfp"}
                                className="profile-picture h-auto aspect-square relative z-[0]"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="applicant-info">
                          <b>
                            <Link
                              className="search-score text-clip overflow-hidden max-w-[15vw]"
                              to={`/feedback/${applicant.id.split('@')[0]}`}
                            >
                              {applicant.first} {applicant.last}
                            </Link>
                          </b>
                        </div>
                        <div className="search-score">{applicant.class}</div>
                        <div className="search-score">{applicant.isAnalyst ? "Analyst" : "Designer"}</div>
                        {applicant.normScores.map((score, idx) => (
                          <div key={idx} className="flex flex-row justify-center">
                            {score ? (
                              <div className="selection-open-comment w-fit" onClick={() => openIsComment(applicant.id, idx)}>
                                <b>{score}/{maxes[idx]}</b>
                              </div>
                            ) : ""}
                          </div>
                        ))}
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              style={{ width: "20px", height: "20px" }}
                              checked={applicant.checked}
                              onChange={() => select(applicant.id)}
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="action-buttons">
                    <div>
                      <button className="action-button bringBack" onClick={togglePopup}>
                        BRING BACK APPLICANT
                      </button>
                    </div>
                    <div className="inner-action-buttons">
                      <button className="action-button continue" onClick={toggleContinue}>
                        CONTINUE
                      </button>
                      <button className="action-button delay" onClick={toggleDelay}>
                        DELAY
                      </button>
                      <button className="action-button reject" onClick={toggleReject}>
                        REJECT
                      </button>
                      <button className="action-button cancel" onClick={clear}>
                        CLEAR
                      </button>
                    </div>
                  </div>
                  <div ref={listUpRef} className="list-up">
                    <button className="small-action-button" onClick={toggleActionsOpen}>
                      ACTIONS
                    </button>
                    {actionListOpen && (
                      <div className="list-up-other">
                        <button className="small-action-button continue" onClick={toggleContinue}>
                          CONTINUE
                        </button>
                        <button className="small-action-button delay" onClick={toggleDelay}>
                          DELAY
                        </button>
                        <button className="small-action-button reject" onClick={toggleReject}>
                          REJECT
                        </button>
                        <button className="small-action-button cancel" onClick={clear}>
                          CLEAR
                        </button>
                      </div>
                    )}
                  </div>

                </>
              ) : (
                <b><h2 className="font-kanit text-ad-dark">No Applicants Found</h2></b>
              )}
            </div>
          </>
        </>
      )}
    </div>
  );
};
