import React, { useState, useEffect } from "react";
import { uploadApplicantScores } from "../libs/firebaseCalls";
import { Loading } from "./Loading.js";
// import "../css/FeedbackFeedbackView.css";

export const FeedbackFeedbackView = ({
  applicantScores,
  applicantComment,
  executive,
  name,
  applicantEmail,
  graderEmail,
  handleClose
}) => {
  const scoringDetails = executive.roundScoringCriteria[executive.round];
  let maxScore = 1;
  let tempScores = [];
  if (scoringDetails) {
    maxScore = scoringDetails.reduce(
      (curMax, curCriteria) => curMax + curCriteria.max,
      0
    );
    let gradedIndex = 0;
    for (let i = 0; i < scoringDetails.length; i++) {
      let applicantGrade = 0;
      if (gradedIndex < applicantScores.length) {
        applicantGrade = applicantScores[gradedIndex];
        gradedIndex += 1;
      }
      tempScores.push(applicantGrade);
    }
  }


  const [curScores, setCurScores] = useState(tempScores ? tempScores : Array(executive.roundScoringCriteria[executive.round].length).fill(0));
  const [curComment, setCurComment] = useState(applicantComment || "");
  const [scoreChange, setScoreChange] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (curScores.length !== 0) {
      const newTotalScore = curScores.reduce(
        (accumulator, curValue) => accumulator + curValue,
        0
      );
      setTotalScore(newTotalScore);
    }
  }, [scoreChange, curScores]);

  const handleSliderChange = (index, newScore) => {
    let temp = [...curScores];
    temp[index] = parseInt(newScore);
    setCurScores(temp);
    setScoreChange(!scoreChange);
  };

  const handleSubmit = async () => {
    const submit = async () => {
      await uploadApplicantScores(applicantEmail, graderEmail, curScores, curComment);
    }
    setLoading(true);
    await submit();
    setLoading(false);
    handleClose();
  };

  if (loading) {
    return <Loading customContainerClass={"flex flex-col justify-center p-16"} />
  }

  return (
    <div className="space-y-4">
      <div className="flex bold justify-between pr-6 space-x-2">
        <div className="text-2xl uppercase">{name}</div>
        <div className="text bg-ad-light-blue w-fit h-fit rounded-full py-1 px-3">{totalScore}/{scoringDetails ? maxScore : 1}</div>
      </div>

      <div className="space-y-2">
        {curScores.map((_, i) => (
          <div key={i} className="feedbackRow">
            <div><b>{scoringDetails[i].criteria}</b></div>
            <div className="flex flex-row items-center space-x-1">
              <input
                type="range"
                min={0}
                max={scoringDetails[i].max}
                value={curScores[i]}
                onChange={(event) => handleSliderChange(i, event.target.value)}
                className="w-full h-2 rounded-full px-1 appearance-none cursor-pointer bg-ad-blue/80"
              />
              <div className="w-14 text-right bold">{curScores[i]}/{scoringDetails[i].max}</div>
            </div>
          </div>
        ))}
        <div className="feedbackRow">
          <div className="bold">Comments:</div>
          <textarea
            value={curComment}
            onChange={(e) => setCurComment(e.target.value)}
            className="commentInput h-[80px]"
          />
        </div>
      </div>

      <div className="flex items-center pt-4">
        <div className="bg-ad-white border-2 border-ad-blue w-full text-center rounded-full text-ad-blue uppercase bold px-4 py-1 hover:cursor-pointer hover:text-white hover:bg-ad-blue duration-200" onClick={handleSubmit}>
          Submit
        </div>
      </div>
    </div>
  );
};
