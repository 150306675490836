import React, { useEffect, useState } from "react";
import { getAllApplicantScores, getExecutive } from "../libs/firebaseCalls";
import { Loading } from "./Loading.js"
// import "../css/Comments.css";

export const Comments = ({ applicant, round }) => {
  const [feedback, setFeedback] = useState([]);
  const [exec, setExec] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const execData = await getExecutive();
        setExec(execData);
        const scoresData = await getAllApplicantScores(`${applicant}@umich.edu`, round);
        setFeedback(scoresData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [applicant, round]);

  if (loading) {
    return <Loading customContainerClass="commentsContainer flex flex-col justify-center items-center p-16"></Loading>;
  }

  if (!exec.roundScoringCriteria) {
    return <div>No scoring criteria found.</div>;
  }

  return (
    <div className="space-y-4">
      <div className="bold uppercase">{applicant} round {round}</div>
      {feedback.length === 0 ? (
        <div>No feedback available.</div>
      ) : (
        feedback.map((f, idx0) => {
          const totalScore = f.scores.reduce((accumulator, curr) => accumulator + curr, 0);
          return (
            <div key={idx0}>
              {/* <div><b>Grader:</b> {f.graderEmail.split('@')[0]}</div> */}
              <div>Score: {totalScore} / {exec.roundScoringCriteria[round].reduce((accumulator, curr) => curr.max + accumulator, 0)}</div>
              {f.scores.map((score, idx1) => {
                const criteria = exec.roundScoringCriteria[round][idx1];
                return (
                  <div key={idx1}>
                    {criteria ? `${criteria.criteria}: ${score} / ${criteria.max}` : `Score: ${score}`}
                  </div>
                );
              })}
              <div>Comment: {f.comment}</div>
              {/* <div className="h-[25px] w-[100%]"></div> */}
            </div>
          );
        })
      )}
      {/* <div className="h-[25px] w-[100%]"></div> */}
    </div>
  );
};

export default Comments;
