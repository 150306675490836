import { React, useState } from "react"
import { AuthData } from "./AuthWrapper.js";
import { Loading } from "./Loading.js"
import { submitFlag } from "../libs/firebaseCalls.js";
import "../css/Flags.css"

export const Flags = ({applicantUniqname}) => {
  const { user } = AuthData();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [color, setColor] = useState("yellow");

  const handleSubmit = async() => {
    setLoading(true);
    try{
      await submitFlag(user.email, applicantUniqname + '@umich.edu', color, comment);
      setColor("yellow");
      setComment("");
    }catch(e){
      console.error(e);
    }finally{
      setLoading(false);
    }
  };

  if (loading) {
    return (<Loading customContainerClass={"w-[100%] h-[100%]"}/>);
  }

  return (
    <>
      <div className="flagsFormContainer">
        <b>{applicantUniqname}</b>
        <select value={color} onChange={(e) => setColor(e.target.value)} style={{ backgroundColor: color, padding:"5px" }}>
          <option value="red">red</option>
          <option value="yellow">yellow</option>
          <option value="green">green</option>
        </select>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="flagsComment"
          placeholder="comment"
        />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </>
  );
}