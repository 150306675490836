import { useEffect, useState } from "react"
import { IoClose } from "react-icons/io5";

export const FeedbackItem = ({
  executive,
  name,
  singleSubmitIdx,
  removeApplicant,
  submitFlags,
  setSubmitFlags,
  outerSliderChange
}) => {
  const scoringDetails = executive.roundScoringCriteria[executive.round];
  const maxScore = scoringDetails ? scoringDetails.reduce(
    (curMax, curCriteria) => curMax + curCriteria.max,
    0
  ) : 1;

  const [curScores, setCurScores] = useState(scoringDetails ? Array(scoringDetails.length).fill(0).map((_, idx) => Math.round(scoringDetails[idx].max / 2)) : []);
  const [curComment, setCurComment] = useState("");
  const [totalScore, setTotalScore] = useState(0);


  useEffect(() => {
    if (curScores.length !== 0) {
      // let newSubmitFlags = submitFlags;
      // newSubmitFlags[singleSubmitIdx] = { ...newSubmitFlags[singleSubmitIdx], scores: Array(scoringDetails.length).fill(0).map((_, idx) => Math.round(scoringDetails[idx].max / 2)) };
      const newTotalScore = curScores.reduce(
        (accumulator, curValue) => accumulator + curValue,
        0
      );
      setTotalScore(newTotalScore);
      outerSliderChange(singleSubmitIdx, curScores);
    }
  }, [curScores, outerSliderChange, singleSubmitIdx]);

  const handleSliderChange = (index, newScore) => {
    let temp = [...curScores];
    temp[index] = parseInt(newScore);
    setCurScores(temp);
    outerSliderChange(singleSubmitIdx, temp);
  };

  return (
    <div className="rounded-xl shadow bg-white px-8 py-6 overflow-y-scroll flex flex-col justify-start relative w-[350px] space-y-4">
      <div className="flex bold justify-between pr-6 space-x-2">
        <div className="text-2xl uppercase">{name}</div>
        <div className="text bg-ad-light-blue w-fit h-fit rounded-full py-1 px-3">{totalScore}/{maxScore}</div>
      </div>

      {singleSubmitIdx >= 0 &&
        <div className="absolute right-4 top-2 flex justify-center items-center hover:cursor-pointer text-ad-dark hover:text-ad-blue duration-200" onClick={() => removeApplicant(singleSubmitIdx)}>
          <IoClose className="size-6"></IoClose>
        </div>
      }
      <div className="space-y-2">
        {curScores.map((_, i) => (
          <div key={i} className="feedbackRow">
            <div><b>{scoringDetails[i].criteria}</b></div>
            <div className="flex flex-row items-center space-x-1">
              <input
                type="range"
                min={0}
                max={scoringDetails[i].max}
                value={curScores[i]}
                onChange={(event) => handleSliderChange(i, event.target.value)}
                className="w-full h-2 rounded-full px-1 appearance-none cursor-pointer bg-ad-blue/80"
              />
              <div className="w-14 text-right bold">{curScores[i]}/{scoringDetails[i].max}</div>
            </div>
          </div>
        ))}
        <div className="feedbackRow">
          <div className="bold">Comments:</div>
          <textarea
            value={curComment}
            onChange={(e) => {
              setCurComment(e.target.value);
              const newSubmitFlags = [...submitFlags];
              newSubmitFlags[singleSubmitIdx] = { ...newSubmitFlags[singleSubmitIdx], comment: e.target.value };
              setSubmitFlags(newSubmitFlags);
            }}
            className="border-2 h-[80px] w-full font-kanit"
          />
        </div>
      </div>
    </div>
  );
};