import React, { useState } from "react";
import { applicantCreation } from "../libs/firebaseCalls";
import CreatableSelect from "react-select/creatable";
import { AuthData } from "./AuthWrapper";
import { involvements, courses } from "../data/data";
import "../css/ApplicantSignUp.css";

export const ApplicantSignUp = ({ years, majors, minors }) => {
  const { user, completeSignUp, logout } = AuthData();
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [year, setYear] = useState(years[0]);
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [coursework, setCoursework] = useState([]);
  const [involvement, setInvolvement] = useState([]);
  const [links, setLinks] = useState([]);
  const [isAnalyst, setIsAnalyst] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formMajors = major.map((cur) => {
      return cur.value;
    });
    const formMinors = minor.map((cur) => {
      return cur.value;
    });
    const formCourses = coursework.map((cur) => {
      return cur.value;
    });
    const formInvolve = involvement.map((cur) => {
      return cur.value;
    });
    const formLinks = links.map((cur) => {
      return cur.value;
    });
    await applicantCreation(
      user.email,
      first,
      last,
      year,
      formMajors,
      formMinors,
      formCourses,
      formInvolve,
      formLinks,
      isAnalyst,
      completeSignUp
    );
  };

  return (
    <div className="page w-[100%] flex flex-col font-kanit">
      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col space-y-8">
          <div className="flex flex-col space-y-4">

            <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
              <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 w-full lg:w-1/2 flex justify-between">
                <label className="bold">First Name</label>
                <input
                  type="text"
                  className="border-[1px] border-solid rounded-full border-gray-300 px-3"
                  value={first}
                  required
                  onChange={(e) => setFirst(e.target.value)}
                />
              </div>
              <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 w-full lg:w-1/2 flex justify-between">
                <label className="bold">Last Name</label>
                <input
                  type="text"
                  className="border-[1px] border-solid rounded-full border-gray-300 px-3"
                  value={last}
                  required
                  onChange={(e) => setLast(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
              <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between">
                <label className="bold">Anticipated Graduation</label>
                <select value={year} className="border-[1px] border-solid border-gray-300 rounded-full w-[224px]" onChange={(e) => setYear(e.target.value)}>
                  {years.map((curYear, idx) => (
                    <option key={idx} value={curYear}>
                      {curYear}
                    </option>
                  ))}
                </select>
              </div>
              <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between">
                <label className="bold">Preferred Position</label>
                <select value={isAnalyst ? "true" : "false"} className="border-[1px] border-solid border-gray-300 rounded-full w-[224px]" onChange={(e) => setIsAnalyst(e.target.value === 'true')}>
                  <option value="true">Software Analyst</option>
                  <option value="false">UX Designer</option>
                </select>
              </div>
            </div>

            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
              <label className="bold">Major(s)</label>
              <CreatableSelect
                isMulti
                options={majors}
                value={major}
                className="w-[70%]"
                required
                onChange={(selectedOptions) => {
                  setMajor(selectedOptions);
                }}
              />
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
              <label className="bold">Minor(s)</label>
              <CreatableSelect
                isMulti
                options={minors}
                value={minor}
                className="w-[70%]"
                onChange={(selectedOptions) => {
                  setMinor(selectedOptions);
                }}
              />
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
              <label className="bold">Campus Involvements</label>
              <CreatableSelect
                isMulti
                options={involvements}
                value={involvement}
                className="w-[70%]"
                onChange={(selectedOptions) => {
                  setInvolvement(selectedOptions);
                }}
              />
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
              <label className="bold">Relevant Coursework</label>
              <CreatableSelect
                isMulti
                options={courses}
                value={coursework}
                className="w-[70%]"
                onChange={(selectedOptions) => {
                  setCoursework(selectedOptions);
                }}
              />
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
              <label className="bold">
                Relevant Links (LinkedIn, GitHub, etc)
              </label>
              <CreatableSelect
                isMulti
                options={[]}
                value={links}
                className="w-[70%]"
                onChange={(selectedOptions) => {
                  setLinks(selectedOptions);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col items-center space-y-4">
            <div onClick={handleSubmit} className="bg-ad-blue text-white w-2/3 md:w-1/2 lg:w-1/3 text-center py-2 shadow-light uppercase bold w-fit rounded-full hover:cursor-pointer hover:text-ad-dark hover:bg-white duration-100">Create Account</div>
            <div onClick={logout} className="bold uppercase text-red-500 hover:text-red-300 hover:cursor-pointer duration-100 ">Logout</div>
          </div>
        </div>
      </form>
    </div>
  );
};
