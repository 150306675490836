import React, { useState, useEffect, useCallback, useRef } from "react";
import { AuthData } from "./AuthWrapper";
import { Loading } from "./Loading.js";
import { getQuestion, submitQuestionGrade } from "../libs/firebaseCalls";
import * as pdfjsLib from 'pdfjs-dist/webpack';
import {
  PDFDocument,
  rgb,
  // StandardFonts
} from 'pdf-lib';
import '../css/GradeQuestion.css';

export const GradeQuestion = ({ exec, grader, changeTask, handleClose }) => {
  const { user } = AuthData();
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [graded, setGraded] = useState(false);
  const [anonymizedResumeURL, setAnonymizedResumeURL] = useState();

  // Using useRef to store initial values
  const userEmailRef = useRef(user.email);
  const graderRef = useRef(grader);
  const execRef = useRef(exec);
  const changeTaskRef = useRef(changeTask);

  const initialized = useRef(false);

  useEffect(() => {
    const checkTaskExists = async () => {
      setLoading(true);
      try {
        if (!Object.keys(graderRef.current.currTask).length) {
          let newGrader = graderRef.current;
          newGrader.numGraded = graderRef.current.numGraded + 1;
          const newTask = await getQuestion(newGrader, userEmailRef.current, execRef.current);
          await changeTaskRef.current(newTask);
        }
      } catch (e) {
        console.error("Task retrieval failed:", e);
      } finally {
        setLoading(false);
      }
    };

    if (!initialized.current) {
      checkTaskExists();
      initialized.current = true;
    }
  }, []);

  // const submitAndEnd = async () => {
  //   setLoading(true);
  //   try {
  //     //eslint-disable-next-line
  //     const [_, _1] = await Promise.all([
  //       await submitQuestionGrade(grader.currApplicant, user.email, grader.qidx, score, exec),
  //       await clearTask(user.email)
  //     ])
  //     changeTask({ currApplicant: "close", currTask: {} });
  //   } catch (e) {
  //     console.error("Failed to submit and end grading session:", e);
  //   } finally {
  //     setLoading(false);
  //     handleClose();
  //   }
  // };

  const extractTextWithPositions = async (pdfBytes) => {
    const pdf = await pdfjsLib.getDocument({ data: pdfBytes }).promise;
    const textItems = [];

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();

      textContent.items.forEach(item => {
        if (item.str) {
          textItems.push({
            str: item.str,
            x: item.transform[4], // X position
            y: item.transform[5], // Y position
            width: item.width, // Text width
            height: item.height // Text height
          });
        }
      });
    }

    return textItems;
  };

  const anonymizeResume = useCallback(async (resumeURL, first, last) => {
    const response = await fetch(resumeURL);
    const arrayBuffer = await response.arrayBuffer();
    const arrayBufferCopy = arrayBuffer.slice(0);
    const pdfBytes = new Uint8Array(arrayBuffer);

    const textItems = await extractTextWithPositions(pdfBytes);
    const pdfDoc = await PDFDocument.load(arrayBufferCopy);
    const pages = pdfDoc.getPages();

    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const phoneRegex = /\b(\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?|\d{3})[-.\s]?\d{3}[-.\s]?\d{4})\b/g;
    const linkedInRegex = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+/g;
    const firstNameRegex = new RegExp(`\\b${first}\\b`, 'gi');
    const lastNameRegex = new RegExp(`\\b${last}\\b`, 'gi');
    // const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    textItems.forEach(({ str, x, y, width, height }) => {
      if (str.match(emailRegex) || str.match(phoneRegex) || str.match(linkedInRegex) || str.match(firstNameRegex) || str.match(lastNameRegex)) {
        const pageIndex = Math.floor(y / pages[0].getHeight());
        const page = pages[pageIndex];
        page.drawRectangle({
          x,
          y: y - 4,
          width,
          height: height + 8,
          color: rgb(0, 0, 0),
        });

        // const textWidth = font.widthOfTextAtSize('[REDACTED]', height);
        // const centeredX = x + (width - textWidth) / 2;
        // const centeredY = y - 4 + (height + 8 - height / 2) / 2;

        // page.drawText('[REDACTED]', {
        //   x: centeredX,
        //   y: centeredY,
        //   size: height,
        //   font,
        //   color: rgb(1, 1, 1),
        // });
      }
    });

    const pdfBytesModified = await pdfDoc.save();
    const anonymizedPdfBlob = new Blob([pdfBytesModified], { type: 'application/pdf' });

    return anonymizedPdfBlob;
  }, []);

  const submitAndContinue = useCallback(async () => {
    setLoading(true);
    setGraded(false);
    try {
      console.time("full");
      let newGrader = grader;
      newGrader.numGraded = grader.numGraded + 1;
      //eslint-disable-next-line
      const [_, newTask] = await Promise.all([
        submitQuestionGrade(grader.currApplicant, user.email, grader.qidx, score, exec),
        getQuestion(newGrader, user.email, exec)
      ]);
      console.timeEnd("full");
      if (exec && exec.roundScoringCriteria && exec.roundScoringCriteria[0] && exec.roundScoringCriteria[0][grader.qidx] && exec.roundScoringCriteria[0][grader.qidx].criteria === "Resume") {
        console.time("resume anonymization");
        const anonymizedResumeBlob = await anonymizeResume(newTask.resumeURL, newTask.first, newTask.last);
        const anonymizedURL = URL.createObjectURL(anonymizedResumeBlob);
        setAnonymizedResumeURL(anonymizedURL);
        console.timeEnd("resume anonymization");
      }
      changeTask(newTask);
      setScore(0);
    } catch (e) {
      console.error("Failed to submit and get another question:", e);
    } finally {
      setLoading(false);
    }
  }, [changeTask, exec, grader, user.email, score, anonymizeResume]);

  useEffect(() => {
    if (!loading) {
      const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          submitAndContinue();
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [submitAndContinue, loading]);

  if (loading) {
    return <Loading customContainerClass="relative h-[100%] flex flex-col justify-center items-center" />;
  }

  const scoringCriteria = exec?.roundScoringCriteria?.[0]?.[grader.qidx];
  const response = grader?.currTask?.essayResponses?.[grader.qidx];

  return (
    <div className="overflow-y-auto h-full">
      {scoringCriteria && (
        <div className="relative h-full">
          <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 h-full">
            <div className={`flex flex-col max-h-[60%] lg:max-h-[100%] ${grader.currApplicant && grader.qidx === grader.currTask.essayResponses.length ? "w-[77%]" : "w-full lg:w-3/5"}`}>
              {grader.currApplicant && scoringCriteria.criteria === "Resume" ? (
                <div className="flex flex-col h-full gap-[20px]">
                  <h3 className="flex-none"><b>Resume: </b></h3>
                  <div className="flex-grow h-[100%] overflow-y-auto">
                    <div className="flex-grow h-[100%] overflow-y-auto">
                      {grader.currTask.resumeURL ? (
                        <iframe title="resumePDF" src={grader.currTask.resumeURL} width={"100%"} height={"100%"} />
                      ) : (
                        <div>Loading anonymized resume...</div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col space-y-4 h-full">
                  <div className="bold text-2xl">Prompt: {scoringCriteria.criteria}</div>
                  <div className="bg-ad-light-blue/50 overflow-y-scroll rounded-xl p-8">{response}</div>
                </div>

              )}
            </div>
            <div className={`flex flex-col justify-center items-center overflow-y-auto space-y-6 ${grader.currApplicant && grader.qidx === grader.currTask.essayResponses.length ? "w-[21%]" : "w-full lg:w-[38%]"}`}>
              <div className="flex w-full items-center lg:flex-col lg:space-y-2">
                <div className="bold uppercase w-40 text-center">Score: {score}/{scoringCriteria.max}</div>
                <input type="range" min={0} max={scoringCriteria.max} value={score}
                  onChange={(e) => setScore(parseInt(e.target.value, 10))}
                  onInput={(event) => {
                    setGraded(true)
                    event.target.style.setProperty(
                      "--slider-value",
                      ((event.target.value - event.target.min) / (event.target.max - event.target.min)) * 100 + "%"
                    );
                  }}
                  className="w-full h-2 rounded-full px-1 appearance-none cursor-pointer bg-ad-blue/80"
                />
              </div>
              <div onClick={graded ? submitAndContinue : undefined} className={`w-full z-50 bold border-2 rounded-full text-center py-2  duration-200 uppercase ${graded ? 'text-ad-blue border-ad-blue hover:cursor-pointer hover:bg-ad-blue hover:text-white' : 'text-gray-400 border-gray-200'}`}>Submit</div>
              <div className="text-center bold px-4 py-1 text-red-500 hover:text-red-300 duration-200 hover:cursor-pointer " onClick={handleClose}>End Grading Session</div>
              {/* <button onClick={submitAndEnd} className="text-lg">Submit and End Grading Session</button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GradeQuestion;
