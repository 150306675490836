import React from "react";
import { Loading } from "./Loading.js"
import "../css/Popup.css";
import { IoClose } from "react-icons/io5";


export const Popup = ({ children, closePopup, title, loading, customContainerClass, customClose }) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen backdrop-blur-sm flex justify-center items-center z-10">
      <div className={customContainerClass || "relative w-[30%] bg-white px-12 py-16 rounded-xl shadow-light overflow-hidden"}>
        {!loading ?
          (
            <div className="flex flex-col font-kanit w-[100%] h-[100%] max-h-[80vh]">
              {title && <div className="bold absolute top-9">{title}</div>}
              {children}
              {closePopup &&
                <div className={customClose || "text-ad-dark flex justify-center items-center absolute top-8 right-8 hover:cursor-pointer hover:text-ad-blue duration-200 "} onClick={closePopup}>
                  <IoClose className="size-6" />
                </div>
              }
            </div>
          )
          :
          (
            <>
              <div className="w-[100%] flex flex-row justify-center items-center">
                <Loading customContainerClass={"w-[300px] h-[300px] flex flex-row justify-center items-center"} />
              </div>
              <div className="bold text-center">Saving can take several seconds. Do not reload.</div>
            </>
          )
        }
      </div>
    </div>
  );
};
