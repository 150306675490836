import React from "react";
import { IoLink, IoLogoGithub, IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
// import "../css/ApplicantProfile.css";

export const MemberLinks = ({ links }) => {
  const renderLinks = () => {
    if (!links) {
      return (
        <div className="bg-ad-blue size-10">{links}</div>
      ); // or any other fallback value or component
    }

    const addPrefix = (url) => (url && url.indexOf('://') === -1) ? 'http://' + url : url;

    let git = addPrefix(links.find((link) => link.includes("github.com")));
    let linked = addPrefix(links.find((link) => link.includes("linkedin.com")));
    let web = links
      .filter((link) => !link.includes("linkedin.com") && !link.includes("github.com"))
      .map(addPrefix);

    return (
      <div className="flex items-center space-x-4 px-4 bg-ad-light-blue/50 rounded-full shadow-xl">
        {git && (
          <Link
            to={git}
            className="group py-2 text-ad-dark-blue hover:text-ad-blue duration-100"
            target="_blank"
            rel="noopener noreferrer"
          ><IoLogoGithub size={32}></IoLogoGithub></Link>
        )}
        {linked && (
          <Link
            to={linked}
            className="group py-2 text-ad-dark-blue hover:text-ad-blue duration-100"
            target="_blank"
            rel="noopener noreferrer"
          ><IoLogoLinkedin size={32} ></IoLogoLinkedin></Link>
        )}
        {web.map((site, idx) => (
          <Link
            to={site}
            key={idx}
            className="group py-2 text-ad-dark-blue hover:text-ad-blue duration-100"
            target="_blank"
            rel="noopener noreferrer"
          ><IoLink size={32} ></IoLink></Link>
        ))}
      </div>
    );
  };

  return renderLinks();
};

export const MemberViewProfile = ({
  pfp,
  resume,
  first,
  last,
  year,
  major,
  minor,
  links,
  number,
  handlePfpPopup,
  handleResumePopup,
}) => {
  return (
    <main>
      <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-8">
        <div className="w-[250px] h-[300px] bg-ad-light-blue rounded-3xl overflow-hidden shadow-xl" >
          <img src={pfp} alt="profilePic" className="object-cover w-full h-full"></img>
          {/* <button onClick={handlePfpPopup} className="changePfpButton">
          <b>Change Profile Picture</b>
        </button> */}
        </div >

        <div className="flex flex-col space-y-4 lg:space-y-6">
          <div className="flex flex-row flex-wrap space-x-6 items-center">
            <div className="text-4xl md:text-4xl lg:text-5xl bold w-fit">{first} {last}</div>
            <MemberLinks links={links} />
          </div>
          <div className="flex flex-col space-y-1 lg:space-y-2">
            <div className="flex space-x-2 items-center">
              {major.length > 1 ? (<div className="bold">Majors:</div>) : (<div className="bold">Major:</div>)}
              {major.length === 0 ? (
                <div className="bg-ad-light-blue/50 px-3 py-1 rounded-full">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {major.map((maj, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-full">{maj}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-center">
              {minor.length > 1 ? (<div className="bold">Minors:</div>) : (<div className="bold">Minor:</div>)}
              {minor.length === 0 ? (
                <div className="bg-ad-light-blue/50 px-3 py-1 rounded-full">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {minor.map((min, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-full">{min}</div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex space-x-2 items-center"><div className="bold">Anticipated Graduation:</div><div className="bg-ad-light-blue/50 px-3 py-1 my-1 rounded-full">{year}</div></div>
            <div className="flex space-x-2 items-center"><div className="bold">Mobile:</div><div className="bg-ad-light-blue/50 px-3 py-1 my-1 rounded-full">{number}</div></div>
          </div>

        </div>
      </div>
    </main>
  );
};
