import { useState } from "react";
import { IoFlag } from "react-icons/io5";
import { AuthData } from "./AuthWrapper";
import Loading from "./Loading";
import { removeFlag } from "../libs/firebaseCalls";

export const ViewFlags = ({ flags, applicant, removeFlagOuter, index }) => {
  const { user } = AuthData();
  console.log(user)
  const [loading, setLoading] = useState(false);

  if(loading){
    return <Loading></Loading>
  }

  return (
    <div className="max-w-[45vw] min-w-[450px] flex flex-col overflow-y-auto">
      {flags.map((flag, idx) => {
        return (
          <div key={idx}>
            <div className="flex flex-row align-center border-solid border-2 rounded-[20px] my-[10px] p-[10px] gap-[2.5%]">
              <div className="flex flex-col justify-center w-[20%]">
                <IoFlag size="100%" color={flag.color} stroke="black" strokeWidth={20} />
              </div>
              <div className="w-[70%] h-[100%] overflow-x-scroll text-left word-break">
                {flag.comment}
              </div>
              {(user.position === "President" || user.position === "Director of Recruitment") &&
                <button
                  className="w-[5%]"
                  onClick={async() => {
                    setLoading(true);
                    let newFlags = flags.filter((_, i) => i !== idx);
                    await removeFlag(applicant.id, newFlags);
                    removeFlagOuter(index, idx)
                    setLoading(false);
                  }}>
                  &times;
                </button>
              }
            </div>
            <div>{flag.member}</div>
          </div>
        )
      })}
      <br />
    </div>
  );
}