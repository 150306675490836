import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import { Link } from "react-router-dom";
import { Loading } from "./Loading.js"
import { getApplicantsByRound } from "../libs/firebaseCalls";
import { getExecutive } from "../libs/firebaseCalls";
import { defaultPfp, theme2Image } from "../data/data.js";
import { Popup } from "./Popup.js";
import { Flags } from "./Flags.js";
import { IoFlag } from "react-icons/io5";
import "../css/Search.css";
import "../css/BoardPage.css";

export const Search = () => {
  const [applicants, setApplicants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [exec, setExec] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flagApplicant, setFlagApplicant] = useState("");

  useEffect(() => {
    const fetchExecutive = async () => {
      setLoading(true);
      const execData = await getExecutive();
      setExec(execData);
    };
    fetchExecutive();
  }, []);

  useEffect(() => {
    if (!exec) return;

    const getApps = async () => {
      const applicantList = await getApplicantsByRound(exec.round);
      const updatedApplicantList = await Promise.all(
        applicantList.map(async (app) => ({
          ...app,
          checked: false,
        }))
      );
      setApplicants(updatedApplicantList);
      setLoading(false);
    };

    // if (exec.round !== 0) { //FIXME
    if (true) {
      getApps();
    } else {
      setLoading(false);
    }
  }, [exec]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredApplicants = applicants.filter((applicant) => {
    const fullName = `${applicant.first} ${applicant.last}`.toLowerCase();
    const uniqname = applicant.id.split('@')[0];
    return fullName.includes(searchQuery.toLowerCase()) || uniqname.includes(searchQuery.toLowerCase());
  });

  const LazyImage = ({ src, alt }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.5,
    });
    return (
      <img
        ref={ref}
        src={inView ? src : ""}
        alt={alt}
        className="profile-picture h-auto aspect-square"
      />
    );
  };

  if (loading || !exec) {
    return <Loading />
  }

  return (
    <>
      {/* {exec.round === 0 ?  */}
      {false ? 
        (
          <div className="font-kanit text-ad-dark">
            <div className="flex flex-col items-center space-y-4">
              <div className="bold text-3xl">Search Closed for Current Round</div>
              <div className="">
                <img src={theme2Image} alt="theme2" className="w-80" draggable={false} />
              </div>
            </div>
          </div>
        )
        :
        (
          <div className="font-kanit space-y-8">
            {flagApplicant && (
              <Popup closePopup={() => setFlagApplicant("")} title="Flags Form">
                <Flags applicantUniqname={flagApplicant}></Flags>
              </Popup>
            )}
            <div className="flex flex-col items-center space-y-4 w-full">
              <div className="bold uppercase camelcase"># applicants in round {exec && (exec.round || 0) + 1}: {(exec && exec.selectionStats && exec.selectionStats[exec.round] && exec.selectionStats[exec.round].total) || 1}</div >
              <input
                className="shadow-light rounded-full px-8 py-4 w-full sm:w-2/3 text-center"
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
                placeholder="Search for people..."
              />
            </div >
            {filteredApplicants.length ? (
              <table className="table-auto w-full">
                <thead className="sticky top-0">
                  <tr key={0} className="bg-ad-light-blue">
                    <th className="py-2 uppercase bold rounded-tl-xl"></th>
                    <th className="py-2 uppercase bold"></th>
                    <th className="py-2 uppercase bold">Applicant</th>
                    <th className="py-2 uppercase bold">Uniqname</th>
                    <th className="py-2 uppercase bold">Year</th>
                    <th className="py-2 uppercase bold">Position</th>
                    <th className="py-2 uppercase bold">Major</th>
                    <th className="py-2 uppercase bold"></th>
                    <th className="py-2 uppercase bold rounded-tr-xl"></th>
                  </tr>
                </thead>
                <tbody className="text-center px-[200px]">
                  {filteredApplicants.map((applicant, idx) => (
                    <tr key={idx} className="odd:bg-white even:bg-gray-50 border-b px-[100px]">
                      <td className="w-[50px]"></td>
                      <td className="flex justify-center py-4">
                        <a
                          href={applicant.resumeURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="size-16"><LazyImage className="object-cover" src={applicant.pfpURL || defaultPfp} alt="Profile" /></div>
                        </a>
                      </td>
                      <td className="py-4 bold">
                        <Link
                          className="hover:text-ad-blue duration-200"
                          to={`/feedback/${applicant.id.split('@')[0]}`}
                        >
                          {applicant.first} {applicant.last}
                        </Link>
                      </td>
                      <td className="py-4"> {applicant.id.split('@')[0]} </td>
                      <td className="py-4">{applicant.class}</td>
                      <td className="py-4">{applicant.isAnalyst ? "Analyst" : "Designer"}</td>
                      <td className="py-4">
                        {applicant.major.join(', ')}
                      </td>
                      <td className="px-4 py-4">
                        <div className="text-ad-dark hover:text-ad-blue duration-200 hover:cursor-pointer scale-" onClick={() => setFlagApplicant(applicant.id.split('@')[0])}>
                          <IoFlag size={20} />
                        </div>
                      </td>
                      <td className="w-[50px]"></td>
                      {/* <div className="status">
                        <Link
                          className="search-score"
                          to={`/feedback/${applicant.id.split('@')[0]}`}
                        >
                          <b>Profile</b>
                        </Link>
                      </div> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center text-3xl">No Applicants Found</div>
            )}

          </div>
        )
      }
    </>
  );
};

export default Search;
