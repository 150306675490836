import { IoBook, IoBookOutline, IoCalendar, IoCalendarOutline, IoCheckbox, IoCheckboxOutline, IoDocumentText, IoDocumentTextOutline, IoDocuments, IoDocumentsOutline, IoExit, IoExitOutline, IoHome, IoHomeOutline, IoOptions, IoOptionsOutline, IoPerson, IoPersonOutline, IoRibbon, IoRibbonOutline, IoSearch, IoSearchOutline } from "react-icons/io5";
import { boardPositions, interviewCommittee, memberPositions } from "./data";

export const navButtonSize = 22;

export const navBar = [
    {
        page: "Home",
        route: "/",
        access: new Set([...memberPositions, ...boardPositions, 'Applicant']),
        icon: <IoHomeOutline size={navButtonSize} />,
        hoverIcon: <IoHome size={navButtonSize} />,
    },
    {
        page: "Essays",
        route: "/apply",
        access: new Set(['Applicant']),
        icon: <IoDocumentTextOutline size={navButtonSize} />,
        hoverIcon: <IoDocumentText size={navButtonSize} />
    },
    {
        page: "Search",
        route: "/search",
        access: new Set([...memberPositions, ...boardPositions]),
        icon: <IoSearchOutline size={navButtonSize} />,
        hoverIcon: <IoSearch size={navButtonSize} />
    },
    {
        page: "Feedback",
        route: "/fullfeedback",
        access: new Set([...memberPositions, ...boardPositions]),
        icon: <IoRibbonOutline size={navButtonSize} />,
        hoverIcon: <IoRibbon size={navButtonSize} />
    },
    {
        page: "Schedule",
        route: "/member-schedule",
        access: new Set([...boardPositions]),
        people: new Set([...interviewCommittee]),
        icon: <IoCalendarOutline size={navButtonSize} />,
        hoverIcon: <IoCalendar size={navButtonSize} />
    },
    {
        page: "Schedule",
        route: "/applicant-schedule",
        access: new Set([]),
        icon: <IoCalendarOutline size={navButtonSize} />,
        hoverIcon: <IoCalendar size={navButtonSize} />
    },
    {
        page: "Selection",
        route: "/selection",
        access: new Set(['President', 'Director of Recruitment']),
        icon: <IoBookOutline size={navButtonSize} />,
        hoverIcon: <IoBook size={navButtonSize} />
    },
    {
        page: "Continued",
        route: "/continued",
        access: new Set(['President', 'Director of Recruitment']),
        icon: <IoCheckboxOutline size={navButtonSize} />,
        hoverIcon: <IoCheckbox size={navButtonSize} />
    },
    {
        page: "Grading",
        route: "/applicantgrading",
        access: new Set([...boardPositions]),
        people: new Set([...interviewCommittee]),
        icon: <IoDocumentsOutline size={navButtonSize} />,
        hoverIcon: <IoDocuments size={navButtonSize} />
    },
    {
        page: "Admin",
        route: "/admin",
        access: new Set(['President', 'Director of Recruitment']),
        icon: <IoOptionsOutline size={navButtonSize} />,
        hoverIcon: <IoOptions size={navButtonSize} />
    },
    {
        page: "Profile",
        route: "/profile",
        access: new Set([...memberPositions, ...boardPositions, 'Applicant']),
        icon: <IoPersonOutline size={navButtonSize} />,
        hoverIcon: <IoPerson size={navButtonSize} />
    },
    {
        page: "Sign Out",
        access: new Set([...memberPositions, ...boardPositions, 'Applicant']),
        icon: <IoExitOutline size={navButtonSize} />,
        hoverIcon: <IoExit size={navButtonSize} />
    }
];