import React from "react";
import "../css/ApplicantProfile.css";
import { MemberLinks } from "./MemberViewProfile";
import { defaultPfp } from "../data/data";

export const ApplicantViewProfile = ({
  pfp,
  resume,
  first,
  last,
  year,
  startYear,
  isAnalyst,
  major,
  minor,
  involvement,
  coursework,
  familiarTechnologies,
  knownAtlasMembers,
  links,
}) => {
  return (
    <main className="space-y-8 w-full">
      <div className="font-kanit flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-8">
        <div className="w-full max-w-[250px] h-[300px] bg-ad-light-blue/50 rounded-3xl overflow-hidden shadow-xl" >
          <img src={pfp ? pfp : defaultPfp} alt="profilePic" className="object-cover w-full h-full"></img>
        </div >

        <div className="flex flex-col space-y-4 lg:space-y-6">
          <div className="space-y-4">
            <div className="flex flex-row flex-wrap space-x-6 items-center">
              <div className="text-4xl md:text-4xl lg:text-5xl bold w-fit">{first} {last}</div>
              <MemberLinks links={links} />
            </div>

          </div>
          <div className="flex flex-col space-y-1 lg:space-y-2">
            <div className="flex space-x-2 items-start">
              {major.length > 1 ? (<div className="bold pt-2">Majors:</div>) : (<div className="bold pt-2">Major:</div>)}
              {major.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {major.map((maj, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{maj}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start">
              {minor.length > 1 ? (<div className="bold pt-2">Minors:</div>) : (<div className="bold pt-2">Minor:</div>)}
              {minor.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {minor.map((min, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{min}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start"><div className="bold pt-2">Expected Graduation:</div><div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{year}</div></div>
            <div className="flex space-x-2 items-start"><div className="bold pt-2">College Starting Semester:</div><div className="bg-ad-light-blue/50 px-3 py-1 my-1 rounded-2xl">{startYear || "None"}</div></div>

            {/* <div className="flex space-x-2 items-center"><div className="bold">Mobile:</div><div className="bg-ad-light-blue/50 px-3 py-1 my-1 rounded-full">{number}</div></div> */}

            <div className="flex space-x-2 items-start">
              <div className="bold pt-2">Coursework:</div>
              {coursework.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {coursework.map((course, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{course}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start">
              {involvement.length > 1 ? (<div className="bold pt-2">Extracurriculars:</div>) : (<div className="bold pt-2">Extracurricular:</div>)}
              {involvement.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {involvement.map((ec, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{ec}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start">
              {familiarTechnologies && familiarTechnologies.length > 1 ? (<div className="bold pt-2">Familiar Technologies:</div>) : (<div className="bold pt-2">Familiar Technologies:</div>)}
              {!familiarTechnologies || familiarTechnologies.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {familiarTechnologies.map((ec, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{ec}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start">
              {knownAtlasMembers && knownAtlasMembers.length > 1 ? (<div className="bold pt-2">Known Atlas Members:</div>) : (<div className="bold pt-2">Known Atlas Members:</div>)}
              {!knownAtlasMembers || knownAtlasMembers.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {knownAtlasMembers.map((ec, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{ec}</div>
                  ))}
                </div>
              )}
            </div>

          </div>
        </div>
      </div>

      <div className="flex flex-col flex-wrap bg-ad-light-blue/50 p-4 rounded-xl shadow-xl justify-center items-center space-y-4 ">


        <div className="flex space-x-2">
          <div className="bold ">Preferred Position:</div>
          <div className=" ">{isAnalyst ? "Software Analyst" : "UX Designer"}</div>
        </div>

        {resume ?
          <div
            onClick={() => window.open(resume, "_blank")}
            className="h-fit bg-ad-blue text-white border-2 border-ad-blue bold uppercase rounded-full hover:text-ad-blue hover:bg-transparent duration-100 hover:cursor-pointer full py-1 px-4"
            type="button"
          >View Resume</div>
          : <div>No resume uploaded</div>}

      </div>
    </main>
  );
};
