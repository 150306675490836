import { db } from "../configs/firebase";
import {
  collection,
  doc,
  where,
  orderBy,
  limit,
  query,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  startAfter,
  getCountFromServer,
  runTransaction,
  deleteDoc,
  writeBatch
} from "firebase/firestore";
import { viewsPerApplicant, board, numRounds, roundNames, defaultPfp, interviewCommittee } from "../data/data";

// NORMALIZATION BY GRADER AND QUESTION/CRITERIA
// g = #graders, a = #applicants, c = #criteria
// # reads = g * a + a
// # writes = a
export const normalize = async () => {
  const batch = writeBatch(db);
  console.time("full normalization");
  console.time("getExec");
  const executiveData = await getExecutive();
  console.timeEnd("getExec");
  const curRound = executiveData.round;
  const collectionRef = collection(db, "round" + curRound + "Results");
  console.time("read scores");
  const docsSnap = await getDocs(collectionRef);
  console.timeEnd("read scores");
  console.time("build graders");
  const graders = {} // #graders * #applicants they graded * #criteria
  docsSnap.forEach((doc) => {
    // Access each document here
    const data = doc.data();
    const grader = data.graderEmail;
    const applicant = data.applicantEmail
    const scores = data.scores;
    if (!graders[grader]) {
      graders[grader] = {}
    }
    graders[grader][applicant] = scores;
  });
  console.timeEnd("build graders");
  console.log("graders: ", graders);

  console.time("build graderAverages");
  const graderAverages = {}
  Object.keys(graders).forEach(grader => {
    const scores = Object.values(graders[grader]); // list of lists, all applicants they graded and their scores
    let means = Array(scores[0].length).fill(0);
    let counts = Array(scores[0].length).fill(0);
    scores.forEach(appScores => {
      appScores.forEach((score, idx) => {
        if (score !== -1) {
          means[idx] += score;
          counts[idx] += 1;
        }
      });
    });
    graderAverages[grader] = means.map((val, idx) => counts[idx] === 0 ? 0 : val / counts[idx]);
    console.log("mean for ", grader, graderAverages[grader]);
  });
  console.timeEnd("build graderAverages");
  console.log("graderAverages", graderAverages);

  console.time("build applicantScores");
  const applicantScores = {};
  const scoringDetails = executiveData.roundScoringCriteria[curRound];
  const desiredAverages = scoringDetails.map((data, _) => {
    return data.max / 2;
  })
  Object.keys(graders).forEach(grader => {
    const applicants = graders[grader];
    Object.keys(applicants).forEach(applicant => {
      if (!applicantScores[applicant]) {
        applicantScores[applicant] = Array(scoringDetails.length).fill(null).map(() => []); //list (#questions) of lists (scores received by that applicant for that question)
      }
      applicants[applicant].forEach((score, qidx) => {
        if (score !== -1) {
          applicantScores[applicant][qidx].push(score - graderAverages[grader][qidx] + desiredAverages[qidx]);
        }
      })
    })
  });
  console.timeEnd("build applicantScores");
  console.log("applicantScores", applicantScores);

  console.time("update applicant normscores");
  const appCollectionRef = collection(db, 'applicants')
  const q = query(appCollectionRef, where("completed", "==", true), where("round", "==", executiveData.round));
  console.time("applicants read");
  const appDocsSnap = await getDocs(q);
  console.timeEnd("applicants read");
  for (const d of appDocsSnap.docs) {
    const data = d.data();
    const applicant = d.id;
    console.time(`Applicant: ${applicant}`);
    let normScores = data.normScores;
    const scores = applicantScores[applicant];
    if(!scores || !scores.length){
      continue;
    }
    console.log(scores, applicantScores, applicant);
    const qAveScores = scores.map((qscores) => {
      return qscores.reduce((a, b) => a + b, 0) / qscores.length
    });
    const num = qAveScores.reduce((a, b) => a + b, 0);
    const factor = Math.pow(10, 2);
    const normScore = Math.round((num * factor)) / factor;
    normScores[curRound] = normScore;
    console.log(`update ${applicant}`, normScores);
    const appDocRef = doc(appCollectionRef, applicant);
    batch.update(appDocRef, { normScores: normScores });
    console.timeEnd(`Applicant: ${applicant}`);
  }
  console.timeEnd("update applicant normscores");

  // if (curRound) { //if not application round, bc setting selectionStats for round 0 is done in applicationCloseFunctions
  //   console.time("Selection Scores");
  //   const total = Object.keys(applicantScores).length;
  //   let newSelectionStats = executiveData.selectionStats;
  //   newSelectionStats.push({
  //     continued: 0,
  //     rejected: 0,
  //     total: total
  //   });
  //   batch.update(doc(collection(db, 'executiveAccess'), 'details'), { selectionStats: newSelectionStats });
  //   console.timeEnd("Selection Scores");
  // }
  await batch.commit();
  console.timeEnd("full normalization");
};

export const averageGraderScores = async () => {
  const batch = writeBatch(db);
  const executiveData = await getExecutive();
  const curRound = executiveData.round;

  const collectionRef = collection(db, "round" + curRound + "Results");
  const docsSnap = await getDocs(collectionRef);

  const graders = {};
  docsSnap.forEach((doc) => {
    const data = doc.data();
    const grader = data.graderEmail;
    const applicant = data.applicantEmail;
    const scores = data.scores;

    if (!graders[grader]) {
      graders[grader] = {};
    }
    graders[grader][applicant] = scores;
  });

  const applicantScores = {};
  Object.keys(graders).forEach(grader => {
    const applicants = graders[grader];
    Object.keys(applicants).forEach(applicant => {
      if (!applicantScores[applicant]) {
        applicantScores[applicant] = Array(applicants[applicant].length).fill(null).map(() => []);
      }
      applicants[applicant].forEach((score, qidx) => {
        if (score !== -1) {
          applicantScores[applicant][qidx].push(score);
        }
      });
    });
  });

  const appCollectionRef = collection(db, 'applicants');
  const q = query(appCollectionRef, where("completed", "==", true), where("round", "==", executiveData.round));

  const appDocsSnap = await getDocs(q);
  console.log(appDocsSnap);
  for (const d of appDocsSnap.docs) {
    const data = d.data();
    const applicant = d.id;
    let normScores = data.normScores || [];
    const scores = applicantScores[applicant];
    if (!scores || !scores.length) {
      continue;
    }
    
    const qAveScores = scores.map((qscores) => {
      return qscores.reduce((a, b) => a + b, 0) / qscores.length; // Average scores for each criterion
    });
    const factor = Math.pow(10, 2);
    const avgScore = Math.round(qAveScores.reduce((a, b) => a + b, 0) * factor) / factor; // Average score across all criteria
    
    normScores[curRound] = avgScore; // Store in normScores for the current round
    
    console.log(`update ${applicant}`, normScores);
    const appDocRef = doc(appCollectionRef, applicant);
    batch.update(appDocRef, { normScores: normScores }); // Update Firestore with normScores
  }
  await batch.commit(); // Commit all updates in a batch
};


export const incrementRound = async () => {
  const executiveData = await getExecutive();
  await updateDoc(doc(collection(db, "executiveAccess"), "details"), { round: executiveData.round + 1 })
  await selectionStatsUpdate(executiveData.round + 1);
}

export const selectionStatsUpdate = async (round) => {
  const exec = await getExecutive();
  let selectionStats = exec.selectionStats;
  const appColRef = collection(db, "applicants");
  const q = query(appColRef, where("completed", "==", true), where("round", "==", round));
  const count = await getCountFromServer(q);
  selectionStats.push({
    total: count.data().count,
    continued: 0,
    rejected: 0
  })
  console.log(selectionStats);
  await updateDoc(doc(collection(db, "executiveAccess"), "details"), { selectionStats: selectionStats });
}

// NORMALIZATION BY GRADER, slightly different than above normalization by grader and question.

// export const normalize = async () => {
//   const executiveData = await getExecutive();
//   const curRound = executiveData.round;
//   const collectionName = "round" + curRound + "Results";
//   const collectionRef = collection(db, collectionName)
//   const querySnap = await getDocs(collectionRef)
//   const graders = {}
//   querySnap.forEach((doc) => {
//     // Access each document here
//     const data = doc.data();
//     const grader = data.graderEmail;
//     const applicant = data.applicantEmail
//     const scores = data.scores;
//     if (!graders[grader]) {
//       graders[grader] = {}
//     }
//     graders[grader][applicant] = scores.reduce((acc, val) => acc + val, 0);
//   });
//   const graderAverages = {}
//   Object.keys(graders).forEach(grader => {
//     const scores = graders[grader];
//     const s = Object.values(scores);
//     const mean = s.reduce((sum, score) => sum + score, 0) / s.length;
//     graderAverages[grader] = mean;
//   });
//   const applicantScores = {};
//   const scoringDetails = executiveData.roundScoringCriteria[curRound];
//   const desiredAverage = scoringDetails.reduce((curMax, curCriteria) => curMax + curCriteria.max, 0) / 2;
//   Object.keys(graders).forEach(grader => {
//     const scores = graders[grader]
//     Object.keys(scores).forEach(applicant => {
//       if (!applicantScores[applicant]) {
//         applicantScores[applicant] = [];
//       }
//       applicantScores[applicant].push(grader[applicant] - graderAverages[grader] + desiredAverage)
//     })
//   });
//   const appCollectionRef = collection(db, 'applicants')
//   for (const applicant of Object.keys(applicantScores)) {
//     try {
//       const scores = applicantScores[applicant];
//       const normScore = scores.reduce((a, b) => a + b, 0) / scores.length;
//       const appDocRef = doc(appCollectionRef, applicant);
//       const app = await getDoc(appDocRef);
//       let normScores = app.normScores;
//       normScores[curRound] = normScore;
//       const field = {
//         normScores: normScores
//       };
//       await updateDoc(appDocRef, field);
//     } catch (e) {
//       console.log("Error updating score", e);
//     }
//   }
// };

export const uploadApplicantScores = async (applicant, grader, scores, comment) => {
  const executiveData = await getExecutive();
  const curRound = executiveData.round;
  const collectionName = "round" + curRound + "Results";
  const scoresObj = {
    applicantEmail: applicant,
    graderEmail: grader,
    scores: scores,
    comment: comment || ""
  }
  try {
    const collectionRef = collection(db, collectionName);
    let docName = applicant + grader;
    const docRef = doc(collectionRef, docName);
    await setDoc(docRef, scoresObj)
  } catch (e) {
    console.log("Error submitting comments/scores", e);
  }
};

export const getApplicantScores = async (applicant, grader, round) => {
  // needs to return a json object with scores and comment
  const collectionName = "round" + round + "Results";
  try {
    const collectionRef = collection(db, collectionName);
    const docsRef = query(
      collectionRef,
      where("applicantEmail", "==", applicant),
      where("graderEmail", "==", grader)
    );
    const docsSnap = await getDocs(docsRef);
    const doc = docsSnap.docs[0]
    const applicantScores = doc.data().scores;
    const applicantComment = doc.data().comment;
    return {
      scores: applicantScores,
      comment: applicantComment
    };
  } catch (e) {
    console.log("Error getting applicant scores", e);
  }
};

export const getAllApplicantScores = async (applicant, round) => {
  // get all feedback for a certain applicant and certain round
  const collectionName = "round" + round + "Results";
  try {
    const collectionRef = collection(db, collectionName);
    const docsRef = query(
      collectionRef,
      where("applicantEmail", "==", applicant),
    );
    const docsSnap = await getDocs(docsRef);
    const allFeedback = []
    docsSnap.docs.forEach((doc) => {
      const graderEmail = doc.data().graderEmail
      const applicantScores = doc.data().scores;
      const applicantComment = doc.data().comment;
      allFeedback.push({
        graderEmail: graderEmail,
        scores: applicantScores,
        comment: applicantComment
      });
    })
    return allFeedback;
  } catch (e) {
    console.log("Error getting applicant scores", e);
  }
}

export const getUserProfile = async (email, member) => {
  try {
    const collectionRef = member
      ? collection(db, "members")
      : collection(db, "applicants");
    const docRef = doc(collectionRef, email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw Error;
    }
  } catch (e) {
    console.log("Error getting userProfile", e);
  }
};

export const getExecutive = async () => {
  try {
    const collectionRef = collection(db, "executiveAccess");
    const docRef = doc(collectionRef, "details");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw Error;
    }
  } catch (e) {
    console.log("Error getting questions", e);
  }
};

export const updateExecutive = async (newExec) => {
  try {
    const collectionRef = collection(db, "executiveAccess");
    const docRef = doc(collectionRef, "details");
    await updateDoc(docRef, newExec);
  } catch (e) {
    console.log("Error updating executive_access", e);
  }
}

export const memberCreation = async (
  email,
  first,
  last,
  number,
  year,
  majors,
  minors,
  position,
  links,
  completeSignUp
) => {
  const memberObj = {
    first: first,
    last: last,
    class: year,
    number: number,
    major: majors,
    minor: minors,
    links: links,
    pfpURL: defaultPfp,
    position: position,
  };

  try {
    const collectionRef = collection(db, "members");
    const docRef = doc(collectionRef, email);
    await setDoc(docRef, memberObj);
    completeSignUp(email, position);
  } catch (e) {
    console.log("Error creating new member", e);
  }
};

export const memberUpdate = async (memberEmail, memberData) => {

  try {
    const collectionRef = collection(db, "members");
    const docRef = doc(collectionRef, memberEmail);
    await updateDoc(docRef, memberData);
  } catch (e) {
    console.log("error updating member");
  }
}

export const applicantCreation = async (
  email,
  first,
  last,
  year,
  majors,
  minors,
  courses,
  involvements,
  links,
  isAnalyst,
  completeSignUp
) => {
  const exec = await getExecutive();

  const applicantObj = {
    first: first,
    last: last,
    class: year,
    major: majors,
    minor: minors,
    involvement: involvements,
    coursework: courses,
    links: links,
    isAnalyst: isAnalyst,
    pfpURL: defaultPfp,
    resumeURL: "",
    round: 0,
    position: "Applicant",
    essayResponses: (exec && exec.roundScoringCriteria && exec.roundScoringCriteria[0] ? Array(exec.roundScoringCriteria[0].length - 1).fill("") : []),
    normScores: exec && exec.roundScoringCriteria ? Array(Object.keys(exec.roundScoringCriteria).length).fill(0) : [],
    delay: false,
    views: 0,
    viewsPerQuestion: exec && exec.roundScoringCriteria ? Array(exec.roundScoringCriteria[0].length).fill(0) : [],
    questionsLeft: exec && exec.roundScoringCriteria ? Array.from({ length: exec.roundScoringCriteria[0].length }, (_, index) => index) : [],
    completed: false,
  };

  try {
    const collectionRef = collection(db, "applicants");
    const docRef = doc(collectionRef, email);
    await setDoc(docRef, applicantObj);
    completeSignUp(email, "Applicant");
  } catch (e) {
    console.log("Error creating new applicant", e);
  }
};

export const applicantUpdate = async (email, applicantData) => {
  try {
    const collectionRef = collection(db, "applicants");
    const docRef = doc(collectionRef, email);
    await updateDoc(docRef, applicantData);
  } catch (e) {
    console.log("Error creating new applicant", e);
  }
}

export const getApplicantsByRound = async (curRound) => {
  try {
    const applicantsQuery = query(
      collection(db, "applicants"),
      where("round", "==", curRound),
      where("completed", "==", true)
    );
    const querySnapshot = await getDocs(applicantsQuery);
    const applicantsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return applicantsData;
  } catch (e) {
    console.log("Error when fetching users", e);
    return [];
  }
};

export const continueApplicants = async (selects) => {
  try {
    let exec = await getExecutive();
    const batch = writeBatch(db);
    selects.map(async (applicant) => {
      const docRef = doc(collection(db, "applicants"), applicant);
      batch.update(docRef, {
        round: exec.round + 1
      })
    })
    exec.selectionStats[exec.round].continued += selects.length;
    batch.update(doc(collection(db, 'executiveAccess'), "details"), exec);
    await batch.commit();
  } catch (e) {
    console.log("Continuing applicants failed:", e)
  }
};

export const delayApplicants = async (selects, applicants) => {
  const colRef = collection(db, "applicants")
  try {
    const batch = writeBatch(db);
    applicants.map(async (applicant) => {
      if (selects.includes(applicant.id)) {
        const docRef = doc(colRef, applicant.id);
        batch.update(docRef, {
          delay: !applicant.delay
        })
      }
    })
    await batch.commit();
  } catch (e) {
    console.log("Continuing applicants failed:", e);
  }
};

export const rejectApplicants = async (selects) => {
  const colRef = collection(db, "applicants")
  try {
    let exec = await getExecutive();
    const batch = writeBatch(db);
    selects.map(async (applicant) => {
      const docRef = doc(colRef, applicant);
      batch.update(docRef, {
        round: -exec.round - 1
      })
    })
    exec.selectionStats[exec.round].rejected += selects.length;
    batch.update(doc(collection(db, 'executiveAccess'), "details"), exec);
    batch.commit();
  } catch (e) {
    console.log("Rejecting applicants failed:", e);
  }
};

export const bringBackApplicant = async (email) => {
  const colRef = collection(db, "applicants");
  try {
    const docRef = doc(colRef, email);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      console.log("Document does not exist");
      throw new Error("Document does not exist");
    }
    const applicantData = docSnap.data();
    let exec = await getExecutive();
    const oldRound = applicantData.round;
    if (applicantData.round !== exec.round) {
      if (applicantData.round === -exec.round - 1) {
        exec.selectionStats[exec.round].rejected -= 1;
      } else if (applicantData.round === exec.round + 1) {
        exec.selectionStats[exec.round].continued -= 1;
      }
      await updateDoc(doc(collection(db, 'executiveAccess'), "details"), exec);
      await updateDoc(docRef, { round: exec.round });
    }

    // Return the applicant data
    return { email, ...applicantData, round: exec.round, id: email, oldRound: oldRound };

  } catch (e) {
    console.error("Bringing back applicant failed:", e);
    throw e;
  }
}


export const fetchGrader = async (grader) => {
  try {
    const colRef = collection(db, "graders");
    const docSnap = await getDoc(doc(colRef, grader));
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw Error;
    }
  } catch (e) {
    console.log("Error getting grader info, ", e);
  }
};

export const fetchAllGraders = async () => {
  try {
    const colRef = collection(db, "graders");
    const docsSnap = await getDocs(colRef);
    let docsData = []
    docsSnap.docs.forEach((d) => {
      docsData.push({
        id: d.id,
        ...d.data()
      })
    })
    return docsData;
  } catch (e) {
    console.log("Error getting grader info, ", e);
  }
}

// export const updateGrader = async (grader, graded) => {
//   try {
//     const colRef = collection(db, "graders");
//     const docRef = doc(colRef, grader);
//     await updateDoc(docRef, {
//       graded: graded
//     });
//   } catch (e) {
//     console.log("Error updating grader info, ", e);
//   }
// }

export const getQuestion = async (grader, graderEmail, exec) => {
  console.time("full getQuestion");
  const queueCollection = collection(db, "applicants");
  let pageSize = 2;
  let scalingFactor = 2;
  let lastVisible = null;
  let foundTask = null;
  try {
    while (!foundTask) {
      let q = query(queueCollection, orderBy("views"), limit(pageSize), where("questionsLeft", "array-contains", grader.qidx), where("completed", "==", true));
      if (lastVisible) {
        q = query(queueCollection, orderBy("views"), startAfter(lastVisible), limit(pageSize), where("questionsLeft", "array-contains", grader.qidx), where("completed", "==", true));
      }
      if (grader.qidx >= exec.roundScoringCriteria[0].length) {
        const newGrader = {
          currApplicant: "done",
          currTask: {},
          graded: {},
        };
        console.time("Update grader - done");
        await updateDoc(doc(collection(db, "graders"), graderEmail), newGrader);
        console.timeEnd("Update grader - done");
        return { currApplicant: 'done', currTask: {} };
      }
      console.time("Get documents");
      const querySnap = await getDocs(q);
      console.timeEnd("Get documents");
      // console.log(querySnap.docs);
      if (querySnap.empty) {
        lastVisible = null;
        console.time("Update grader - increment qidx");
        await updateDoc(doc(collection(db, "graders"), graderEmail), { qidx: grader.qidx + 1, graded: {} });
        console.timeEnd("Update grader - increment qidx");
        grader.qidx++;
        grader.graded = {};
        continue;
      }
      for (const taskDoc of querySnap.docs) {
        const taskData = taskDoc.data();
        if (grader.graded[taskDoc.id.split('@')[0]]) {
          // Grader has already graded this question, skip it
          continue;
        } else {
          // Found a question that hasn't been graded by this grader
          foundTask = { taskDoc, taskData };
          break;
        }
      }
      lastVisible = querySnap.docs[querySnap.docs.length - 1];
      pageSize = Math.round(pageSize * scalingFactor);
    }
    if (!foundTask) {
      throw new Error('No suitable task found');
    }
    // Perform the necessary updates directly
    const { taskDoc, taskData } = foundTask;
    //task updates
    let newTask = {
      views: taskData.views + 1,
      viewsPerQuestion: taskData.viewsPerQuestion,
      questionsLeft: taskData.questionsLeft
    };
    newTask.viewsPerQuestion[grader.qidx]++;
    if (taskData.viewsPerQuestion[grader.qidx] >= viewsPerApplicant) {
      newTask.questionsLeft[grader.qidx] = -1;
    }

    //grader updates
    let newGrader = {
      graded: grader.graded,
      numGraded: grader.numGraded
    };
    if (taskData.viewsPerQuestion[grader.qidx] >= viewsPerApplicant) { // if it reaches a question threshold and moves onto the next question, clear graded and increment qidx
      console.log("moved to next question:", grader.qidx + 1);
      newGrader = {
        graded: {},
        qidx: grader.qidx + 1
      };
    }
    newGrader["graded"][taskDoc.id.split('@')[0]] = true;
    newGrader["currTask"] = foundTask.taskData;
    newGrader["currApplicant"] = taskDoc.id;

    console.time("final writes");
    const batch = writeBatch(db);
    batch.update(taskDoc.ref, newTask);
    batch.update(doc(collection(db, "graders"), graderEmail), newGrader);
    await batch.commit();
    console.timeEnd("final writes");

    foundTask.taskData.id = taskDoc.id;
    console.timeEnd("full getQuestion");
    return foundTask.taskData;
  } catch (e) {
    console.error("fetch question failed:", e);
    return {}; // Ensure that the function returns null if it fails
  }
};

export const submitQuestionGrade = async (applicant, grader, qidx, score, exec) => {
  // const exec = await getExecutive();
  const colRef = collection(db, "round0Results");
  const docRef = doc(colRef, applicant + grader);
  console.time("Get scores");
  const docSnap = await getDoc(docRef);
  console.timeEnd("Get scores");
  if (!docSnap.exists()) {
    let scores = Array(exec.roundScoringCriteria[0].length).fill(-1);
    scores[qidx] = score;
    const data = {
      applicantEmail: applicant,
      graderEmail: grader,
      scores: scores,
      comment: "",
    };
    console.time("Set scores")
    await setDoc(docRef, data);
    console.timeEnd("Set scores")
  } else {
    let newScores = docSnap.data().scores;
    newScores[qidx] = score;
    console.time("Update scores")
    await updateDoc(docRef, { scores: newScores });
    console.timeEnd("Update scores")
  }
};

export const clearTask = async (grader) => {
  const colRef = collection(db, "graders");
  const docRef = doc(colRef, grader);
  await updateDoc(docRef, {
    currTask: {},
    currApplicant: ""
  })
};

export const checkGradersDone = async () => {
  const graderCol = collection(db, "graders");
  const q = query(graderCol, where("currApplicant", "==", "done"));
  const numDone = await getCountFromServer(q);
  return numDone.data().count
};

export const submitFlag = async (memberEmail, applicantEmail, color, comment) => {
  const customFlagSort = (flags) => {
    const colorPriority = {
      red: 1,
      yellow: 2,
      green: 3
    };
    return flags.sort((a, b) => colorPriority[a.color] - colorPriority[b.color]);
  }

  try {
    const flagDoc = await getDoc(doc(collection(db, "applicants"), applicantEmail));
    const newFlag = {
      member: memberEmail,
      color: color,
      comment: comment
    }
    let newFlags = flagDoc.data().flags || [];
    newFlags.push(newFlag);
    newFlags = customFlagSort(newFlags);
    await updateDoc(doc(collection(db, "applicants"), applicantEmail), { flags: newFlags });
  } catch (e) {
    throw e;
  }
}

export const applicationCloseFunctions = async () => {
  const applicantsCol = collection(db, "applicants");
  const formAppsSet = new Set([
    "gorache@umich.edu", "lukemcg@umich.edu", "jacobkt@umich.edu", "jakesong@umich.edu", "brunohg@umich.edu",
    "xinyiade@umich.edu", "emmaroth@umich.edu", "jackbaum@umich.edu", "jaberin@umich.edu", "salemala@umich.edu",
    "huiyangc@umich.edu", "jtaffe@umich.edu", "sttcha@umich.edu", "zhangbri@umich.edu", "svatsal@umich.edu",
    "talluriv@umich.edu", "jakesong@umich.edu", "supritan@umich.edu", "tiffsc@umich.edu", "sanjitv@umich.edu",
    "tylerarc@umich.edu", "shamita@umich.edu", "becklau@umich.edu", "anitagov@umich.edu", "essaba@umich.edu",
    "nikhilrn@umich.edu", "vuyyurur@umich.edu", "lbroberg@umich.edu", "edisung@umich.edu", "stevenfu@umich.edu",
    "vchigull@umich.edu", "yidapan@umich.edu", "phoenixs@umich.edu", "angads@umich.edu", "nshenoy@umich.edu",
    "riyasaha@umich.edu", "howtian@umich.edu", "dnimmala@umich.edu", "kyranp@umich.edu", "dsjing@umich.edu",
    "carlota@umich.edu", "johang@umich.edu", "graceche@umich.edu", "njjohns@umich.edu", "kajalpat@umich.edu",
    "abhiatt@umich.edu", "vnayyar@umich.edu", "jessechg@umich.edu", "kudlaktl@umich.edu", "linyufen@umich.edu",
    "jakesong@umich.edu", "aaditj@umich.edu", "hanahmed@umich.edu", "chanlc@umich.edu", "bonhuynh@umich.edu",
    "caor@umich.edu", "doredla@umich.edu", "calebjl@umich.edu", "andryu@umich.edu", "sahithin@umich.edu",
    "zhangywu@umich.edu", "juliamei@umich.edu", "elengeo@umich.edu", "mnoren@umich.edu", "atikekar@umich.edu",
    "mjjiang@umich.edu", "omjoshi@umich.edu", "larmijo@umich.edu", "meghnar@umich.edu", "lammy@umich.edu",
    "stevenza@umich.edu", "xuzijie@umich.edu", "niharo@umich.edu", "shahary@umich.edu", "gulatip@umich.edu",
    "richxh@umich.edu", "amalrajn@umich.edu", "chachu@umich.edu", "rrmenon@umich.edu", "avachang@umich.edu",
    "aditiv@umich.edu", "raksraja@umich.edu", "ayannair@umich.edu", "mocnyn@umich.edu", "borban@umich.edu",
    "jbjustin@umich.edu", "dxnny@umich.edu", "jqwu@umich.edu", "akunju@umich.edu", "adisinha@umich.edu",
    "ianhkim@umich.edu", "abasired@umich.edu", "loganrms@umich.edu", "rnatesan@umich.edu", "kvega@umich.edu",
    "shanbhag@umich.edu", "aolman@umich.edu", "jacobche@umich.edu", "kirtanb@umich.edu", "simrankh@umich.edu",
    "mwcooper@umich.edu", "adagi@umich.edu", "rehansha@umich.edu", "ninagern@umich.edu", "srmondal@umich.edu",
    "truthesh@umich.edu", "tswu@umich.edu", "bayleep@umich.edu", "alexdnl@umich.edu", "justinxy@umich.edu",
    "rickli@umich.edu", "apak@umich.edu", "manuli@umich.edu", "vishnupa@umich.edu", "sherrywu@umich.edu",
    "wentaiz@umich.edu", "srikarn@umich.edu", "mathewz@umich.edu", "vvanbu@umich.edu", "rickgum@umich.edu",
    "gishaan@umich.edu", "angelaqu@umich.edu", "mehakgul@umich.edu", "vandank@umich.edu", "esmecard@umich.edu",
    "hoangpha@umich.edu", "mraghav@umich.edu", "tigerl@umich.edu", "tleng@umich.edu", "alexsali@umich.edu",
    "sdsingh@umich.edu", "waynesc@umich.edu", "nesas@umich.edu", "toanbui@umich.edu", "wujeffer@umich.edu",
    "aroraar@umich.edu", "anishkp@umich.edu", "harjas@umich.edu", "kankats@umich.edu", "amypang@umich.edu",
    "dsaxena@umich.edu", "jklim@umich.edu", "niharo@umich.edu", "rtaepa@umich.edu", "nmaloo@umich.edu",
    "aykom@umich.edu", "davidmon@umich.edu", "gmsidhu@umich.edu", "ssbhide@umich.edu", "amyllee@umich.edu",
    "shreshri@umich.edu", "kolliroh@umich.edu", "wonjunl@umich.edu", "herchris@umich.edu", "gracela@umich.edu",
    "nedagln@umich.edu", "chfloyd@umich.edu", "sparshs@umich.edu", "akulg@umich.edu", "leonaziz@umich.edu",
    "anshch@umich.edu", "muskvr@umich.edu", "yamehta@umich.edu", "gloriayu@umich.edu", "skyechao@umich.edu",
    "cheneth@umich.edu", "ramgade@umich.edu", "edisont@umich.edu", "kbheem@umich.edu", "adnanr@umich.edu",
    "aniv@umich.edu", "natashg@umich.edu", "dnoronha@umich.edu", "adanny@umich.edu", "jiyih@umich.edu",
    "akashbol@umich.edu", "babosha@umich.edu", "ethanhku@umich.edu", "vaap@umich.edu", "koshyn@umich.edu",
    "garciamj@umich.edu", "uwaeish@umich.edu", "vbanga@umich.edu", "drewdame@umich.edu", "aalleyah@umich.edu",
    "subinpyo@umich.edu", "shivac@umich.edu", "bkizzels@umich.edu", "jdgriest@umich.edu", "avemur@umich.edu",
    "smoparti@umich.edu", "caydmay@umich.edu", "rashmivr@umich.edu", "sriyan@umich.edu", "jocechiu@umich.edu",
    "tiffanw@umich.edu", "akulg@umich.edu", "tylersw@umich.edu", "pinjiang@umich.edu", "skritika@umich.edu",
    "ninclark@umich.edu", "agawde@umich.edu", "vikraant@umich.edu", "roshnidv@umich.edu", "sviridov@umich.edu",
    "brhys@umich.edu", "salilvk@umich.edu", "anweshap@umich.edu", "garciamj@umich.edu", "anishcha@umich.edu",
    "nedagln@umich.edu", "semuak@umich.edu", "gaurab@umich.edu", "soumita@umich.edu", "reet@umich.edu",
    "sunnysha@umich.edu", "kayleyg@umich.edu", "schidige@umich.edu", "rithvin@umich.edu", "linusaw@umich.edu",
    "hyebinp@umich.edu", "havishb@umich.edu", "mfeneber@umich.edu", "annajero@umich.edu", "garciamj@umich.edu",
    "kayleyg@umich.edu", "aliciaeb@umich.edu", "afpark@umich.edu", "mollyri@umich.edu", "kchens@umich.edu",
    "dervint@umich.edu", "roywx@umich.edu", "sanikako@umich.edu", "mrezk@umich.edu", "adityamu@umich.edu",
    "vvanbu@umich.edu", "harshalu@umich.edu"
  ])
  const q = query(applicantsCol, where("completed", "==", true));
  const totalCompleted = await getCountFromServer(q);
  const applicants = await getDocs(applicantsCol);
  let count = 0;
  const batch = writeBatch(db);
  for (const app of applicants.docs) {
    const a = app.data();
    if (a.first && a.last && a.major && a.major.length && a.pfpURL && a.resumeURL) {
      let found = false;
      for (const e of a.essayResponses) {
        if (!e) {
          found = true;
          break;
        }
      }
      console.log(app.id, formAppsSet.has(app.id))
      if (formAppsSet.has(app.id) && !found) {
        count += 1;
        batch.update(doc(applicantsCol, app.id), { completed: true });
      } else {
        batch.update(doc(applicantsCol, app.id), { completed: false });
      }
    }
  }
  console.log(count, totalCompleted.data().count);
  const selectionStats = [{
    // total: totalCompleted.data().count,
    total: count,
    continued: 0,
    rejected: 0
  }]
  batch.update(doc(collection(db, "executiveAccess"), "details"), { selectionStats: selectionStats });
  batch.commit();
}

export const saveTimes = async (applicationOpen, applicationClose) => {
  await updateDoc(doc(collection(db, "executiveAccess"), "details"), { applicationOpen: applicationOpen, applicationClose: applicationClose });
}

export const getSchedules = async () => {
  const docsSnap = await getDocs(collection(db, "schedules"));
  return docsSnap.docs;
}

export const getSchedule = async (round) => {
  const docSnap = await getDoc(doc(collection(db, "schedules"), roundNames[round]));
  return docSnap.data();
}

export const saveSchedule = async (roundName, schedule) => { //ADMIN
  try {
    await setDoc(doc(collection(db, "schedules"), roundName), schedule);
  } catch (e) {
    console.error("failed schedule save", e);
  }
}

export const deleteSchedule = async (roundName) => {
  try {
    await deleteDoc(doc(collection(db, "schedules"), roundName));
  } catch (e) {
    console.error("failed schedule delete", e);
  }
}

export const memberSaveSchedule = async (roundName, changes) => {
  try {
    console.log("changes:", changes);
    const result = await runTransaction(db, async (transaction) => {
      // Retrieve the current schedule
      console.log("transaction attempt");
      const scheduleRef = doc(collection(db, "schedules"), roundName);
      const document = await transaction.get(scheduleRef);
      if (!document.exists) {
        throw new Error(`Schedule with round name ${roundName} does not exist.`);
      }
      const currentSchedule = document.data();
      const unsuccessfulAdds = [];
      changes.forEach(change => {
        const { action, locIdx, timeIdx, member } = change;
        const timeSlot = currentSchedule.locations[locIdx].times[timeIdx];

        if (action === "add") {
          if (timeSlot.members.length < 3 && !timeSlot.members.includes(member)) {
            timeSlot.members.push(member);
          } else {
            unsuccessfulAdds.push(change);
          }
        } else if (action === "remove") {
          timeSlot.members = timeSlot.members.filter(m => m !== member);
        }
      });
      transaction.update(scheduleRef, currentSchedule);
      return { failedAdds: unsuccessfulAdds, updatedSchedule: currentSchedule };
    });
    // Return the result of the transaction
    return result;
  } catch (error) {
    console.error("Error saving schedule:", error);
    throw error;
  }
};

export const applicantSaveSchedule = async (roundName, newIndices, uniqname) => {
  try {
    const result = await runTransaction(db, async (transaction) => {
      const scheduleRef = doc(collection(db, "schedules"), roundName);
      const document = await transaction.get(scheduleRef);
      if (!document.exists) {
        throw new Error(`Schedule with round name ${roundName} does not exist.`);
      }
      const currentSchedule = document.data();
      let addFailed = false;
      let found = false;
      let oldIndices = []; // [locationIdx, timeIdx]
      for (let i = 0; i < currentSchedule.locations.length; i++) {
        for (let j = 0; j < currentSchedule.locations[i].times.length; j++) {
          if (currentSchedule.locations[i].times[j].applicants.includes(uniqname)) {
            oldIndices = [i, j];
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      const newTimeSlot = currentSchedule.locations[newIndices[0]].times[newIndices[1]];
      if (newTimeSlot.applicants.length < currentSchedule.maxApplicants) { // has space
        if (!newTimeSlot.applicants.includes(uniqname)) { // user not already in
          newTimeSlot.applicants.push(uniqname);
          if (oldIndices !== newIndices && found) {
            const oldTimeSlot = currentSchedule.locations[oldIndices[0]].times[oldIndices[1]];
            oldTimeSlot.applicants = oldTimeSlot.applicants.filter(u => u !== uniqname);
          }
          transaction.update(scheduleRef, { locations: currentSchedule.locations });
        }
      } else {
        addFailed = true;
      }
      return { failedAdd: addFailed ? newIndices : [], updatedSchedule: currentSchedule, oldIndices: oldIndices };
    });
    return result;
  } catch (error) {
    console.error("Error saving schedule:", error);
    throw error;
  }
};

export const continueAllApplicants = async (currRound) => {
  try {
    const appCollectionRef = collection(db, 'applicants')
    const q = query(appCollectionRef, where("completed", "==", true), where("round", "==", currRound));
    const qSnap = await getDocs(q);
    console.log(qSnap.docs.length)
    const batch = writeBatch(db);
    for (const d of qSnap.docs) {
      const applicant = d.id;
      const appDocRef = doc(appCollectionRef, applicant);
      batch.update(appDocRef, { round: currRound + 1 });
    }
    await batch.commit();
  } catch (error) {
    console.error(`Error continuing applicants from round ${currRound}:`, error);
  }
}





//FUNCTIONS USED FOR TESTING 
export const resetGrading = async () => {
  //Maybe see if writes can be done concurrently?? Not sure if promise.all can take 300 concurrent things
  const exec = await getExecutive();
  const batch = writeBatch(db);

  //reset applicants
  const appColRef = collection(db, "applicants");
  const applicants = await getDocs(appColRef);
  for (const applicant of applicants.docs) {
    const applicantDocRef = doc(db, "applicants", applicant.id);
    batch.update(applicantDocRef, { views: 0, viewsPerQuestion: Array(exec.roundScoringCriteria[0].length).fill(0), questionsLeft: Array.from({ length: exec.roundScoringCriteria[0].length }, (_, index) => index) });
  }

  //reset graders
  const gradersColRef = collection(db, "graders");
  const graders = await getDocs(gradersColRef);
  for (const grader of graders.docs) {
    const graderDocRef = doc(db, "graders", grader.id);
    batch.set(graderDocRef, { graded: {}, qidx: 0, currTask: {}, currApplicant: "", numGraded: 0 });
  }

  await batch.commit();
  console.log("grading stats reset");
};

export const buildTestApplicants = async () => {
  const exec = await getExecutive();
  const batch = writeBatch(db);

  let letters = [];
  for (let i = 97; i <= 122; i++) {
    letters.push(String.fromCharCode(i));
  }
  for (let i = 97; i <= 122; i++) {
    for (let j = 97; j <= 122; j++) {
      if (letters.length < 300) {
        letters.push(String.fromCharCode(i) + String.fromCharCode(j));
      } else {
        break;
      }
    }
  }
  for (const letter of letters) {
    batch.set(doc(collection(db, "applicants"), letter + '@umich.edu'), {
      first: "test" + letter,
      last: "test" + letter,
      class: "Spring 2028",
      major: [],
      minor: [],
      involvement: [],
      coursework: [],
      links: [],
      isAnalyst: false,
      pfpURL: defaultPfp,
      resumeURL: "https://firebasestorage.googleapis.com/v0/b/internal-project-22ae3.appspot.com/o/applicants%2Fresumes%2Fseojuny?alt=media&token=1cadd34c-faea-4f9c-a0ff-d0bc4f584c0e",
      round: 0,
      position: "Applicant",
      essayResponses: Array.from({ length: exec.roundScoringCriteria[0].length - 1 }, (_, index) => letter + index), //-1 bc resume
      normScores: Array(numRounds).fill(0),
      delay: false,
      views: 0,
      viewsPerQuestion: Array(exec.roundScoringCriteria[0].length).fill(0),
      questionsLeft: Array.from({ length: exec.roundScoringCriteria[0].length }, (_, index) => index),
      completed: true
    })
  }
  await batch.commit()
  console.log("built test data");
}

export const removeFlag = async (applicantEmail, newFlags) => {
  await updateDoc(doc(collection(db, "applicants"),applicantEmail), {flags: newFlags})
}


export const buildTestGraders = async () => {
  const colRef = collection(db, "graders");
  const batch = writeBatch(db);
  const arr = [...board, ...interviewCommittee];
  for (const uniqname of arr) {
    batch.set(doc(colRef, uniqname + "@umich.edu"), {
      currApplicant: "done",
      currTask: {},
      graded: {},
      numGraded: 0,
      qidx: 6,
    })
  };
  await batch.commit();
  console.log("built test graders");
};

export const checkQuestionForChangedGradingScale = async () => {
  const colRef = collection(db, "round0Results");
  const q = query(colRef, where("graderEmail", "==", "vkaushik@umich.edu"));
  const appDocs = await getDocs(q);
  const batch = writeBatch(db);
  for (const d of appDocs.docs) {
    let data = d.data();
    if (data.scores[2] >= 5) {
      const oldScore = data.scores[2];
      const offset = (data.scores[2] - 5) / 4;
      const score = offset + 1.5;
      data.scores[2] = score;
      console.log(oldScore, score);
      batch.update(doc(colRef, d.id), { scores: data.scores });
    }
  }
  await batch.commit();
};

export const checkGradingIncomplete = async () => {
  const colRef = collection(db, "applicants");
  const q = query(colRef, where("completed", "==", true));
  const appDocs = await getDocs(q);
  const batch = writeBatch(db);
  let count = 0;
  for (const d of appDocs.docs) {
    const data = d.data();
    let qsLeft = data.questionsLeft;
    let viewsPerQuestion = data.viewsPerQuestion;

    if (data.questionsLeft[4] === 4) {
      count += 1;
    }
    batch.update(doc(colRef, d.id), { questionsLeft: qsLeft, viewsPerQuestion: viewsPerQuestion });
  }
  batch.commit();
  console.log(count);
}

export const testing1 = async () => {
  const colRef = collection(db, "applicants");
  const q = query(colRef, where("round", "==", 1));
  const appDocs = await getDocs(q);
  let arr = [];
  for (const d of appDocs.docs) {
    arr.push(d.id);
  }
  console.log(arr);
}

export const testing2 = async () => {
  const exec = await getExecutive();
  const colRef = collection(db, "applicants");
  const q = query(colRef, where("round", "==", exec.round + 1));
  const appDocs = await getDocs(q);
  let arr = [];
  let str = ""
  for (const d of appDocs.docs) {
    let data = d.data();
    data["id"] = d.id;
    arr.push(data);
    str += (d.id) + ' ';
  }
  console.log(str);
  return arr;
}

export const getMatchingApplicants = async () => {
  const nameList = [
    "Aarush Khanna",
    "Jakub Steg",
    "Shivansh Upadhyay",
    "Shota Gen",
    "Stephanie Ochoa"
  ];

  const colRef = collection(db, "applicants");
  let matchingDocs = [];

  for (const fullName of nameList) {
    const [firstName, lastName] = fullName.toLowerCase().split(' ');

    const q = query(colRef,
      where("firstName", "==", firstName),
      where("lastName", "==", lastName));

    const appDocs = await getDocs(q);

    appDocs.forEach((doc) => {
      matchingDocs.push(doc.id);
    });
  }
  console.log(matchingDocs);
  return matchingDocs;
};

export const testing3 = async() => {
  // const memberEmail = "wangemi@umich.edu";
  // const applicantEmail = "jdgriest@umich.edu"
  // console.log(applicantEmail + memberEmail);
  // await updateDoc(doc(collection(db, "round1Results"), applicantEmail + memberEmail), {scores: [4]})
}