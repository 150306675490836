import { React, useState, useEffect } from "react";
import { Navbar } from "./Navbar";
import { Outlet, Navigate } from "react-router-dom";
import { AuthData } from "./AuthWrapper";
import { getExecutive } from "../libs/firebaseCalls";
// import "../css/LoggedIn.css";
import { Loading } from "./Loading";
import { AppClosed } from "./AppClosed.js"

const MAX_TIMEOUT = 2 ** 31 - 1; // Maximum value for setTimeout (2147483647 ms)

export const LoggedIn = () => {
  const { user } = AuthData();
  const [exec, setExec] = useState({});
  const [loading, setLoading] = useState(true);
  const [appStatus, setAppStatus] = useState("closed");

  const formatDateTime = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  useEffect(() => {
    const scheduleTimeout = (delay, callback) => {
      if (delay > MAX_TIMEOUT) {
        setTimeout(() => scheduleTimeout(delay - MAX_TIMEOUT, callback), MAX_TIMEOUT);
      } else {
        setTimeout(callback, delay);
      }
    };

    const fetchExecutiveData = async () => {
      try {
        setLoading(true);
        // console.log("Logged in read");
        const executiveDetails = await getExecutive();
        setExec(executiveDetails);
        const applicationOpen = executiveDetails.applicationOpen.toDate();
        const applicationClose = executiveDetails.applicationClose.toDate();
        const currentTime = new Date();

        if (currentTime < applicationOpen) {
          setAppStatus("notOpen");
          scheduleTimeout(applicationOpen - currentTime, () => setAppStatus("open"));
        } else if (currentTime >= applicationOpen && currentTime <= applicationClose) {
          setAppStatus("open");
          scheduleTimeout(applicationClose - currentTime, () => setAppStatus("closed"));
        } else {
          setAppStatus("closed");
        }
      } catch (error) {
        console.error("Error fetching executive details:", error);
        setAppStatus("closed");
      } finally {
        setLoading(false);
      }
    };

    fetchExecutiveData();
  }, []);

  if (loading) {
    return <Loading logged={true} />;
  }

  return (
    <>
      {user.uniqname === "" ? (
        <Navigate to="/login" />
      ) : user.uniqname !== "" && !user.completeSignUp ? (
        <Navigate to="/signup" />
      ) : appStatus === "open" || user.position !== "Applicant" || (user.position === "Applicant" && user.round >= exec.round) ? (
      // ) : true ? (
        <div className="flex flex-row ">
          <div className="h-screen fixed w-[70px] lg:w-64 bg-ad-light-blue">
            <Navbar />
          </div>
          <div className="ml-[70px] lg:ml-64 w-full px-12 py-16">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="px-[2%] w-[100%]">
          <AppClosed
            appStatus={appStatus}
            appOpen={formatDateTime(exec.applicationOpen.toDate())}
          />
        </div>
      )}
    </>
  );
};
