import React from "react";
import { AuthData } from "./AuthWrapper";
import CreatableSelect from "react-select/creatable";
import { minors, majors, years, involvements, courses, startYears, namesArray, technologiesArray } from "../data/data.js";
// import "../css/ApplicantProfile.css";

export const ApplicantEditProfile = ({
  saveResponses,
  first,
  last,
  year,
  startYear,
  major,
  minor,
  involvement,
  removeIsNewField,
  coursework,
  knownAtlasMembers,
  familiarTechnologies,
  links,
  isAnalyst,
  handleFirst,
  handleLast,
  handleYear,
  handleStartYear,
  handleMajor,
  handleMinor,
  handleInvolvement,
  handleCoursework,
  handleKnownAtlasMembers,
  handleFamiliarTechnologies,
  handleLinks,
  handleIsAnalyst,
  handlePfpPopup,
  handleResumePopup,
}) => {
  const { user } = AuthData();
  return (
    <form onSubmit={(e) => saveResponses(e)} className="">
      <div className=" flex flex-col space-y-8">
        <div className="flex flex-col space-y-4">

          <div className="flex space-x-4">
            <div className="bold border-2 border-ad-blue text-ad-blue w-fit px-4 py-1 rounded-full hover:cursor-pointer hover:bg-ad-blue hover:text-white duration-100" onClick={handlePfpPopup}>Add / Update Profile Picture</div>
            {
              user.round === 0 &&
              <div className="bold border-2 border-ad-blue text-ad-blue w-fit px-4 py-1 rounded-full hover:cursor-pointer hover:bg-ad-blue hover:text-white duration-100" onClick={handleResumePopup}>Add / Update Resume</div>
            }
          </div>
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 w-full lg:w-1/2 flex justify-between">
              <label className="bold">First Name</label>
              <input
                type="text"
                className="border-[1px] border-solid rounded-full border-gray-300 px-3"
                value={first}
                required
                onChange={(e) => handleFirst(e.target.value)}
              />
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 w-full lg:w-1/2 flex justify-between">
              <label className="bold">Last Name</label>
              <input
                type="text"
                className="border-[1px] border-solid rounded-full border-gray-300 px-3"
                value={last}
                required
                onChange={(e) => handleLast(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between">
              <label className="bold">Anticipated Graduation</label>
              <select value={year} className="border-[1px] border-solid border-gray-300 rounded-full w-[224px]" onChange={(e) => handleYear(e.target.value)}>
                {years.map((curYear, idx) => (
                  <option key={idx} value={curYear}>
                    {curYear}
                  </option>
                ))}
              </select>
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between">
              <label className="bold">College Starting Semester</label>
              <select value={startYear} className="border-[1px] border-solid border-gray-300 rounded-full w-[224px]" onChange={(e) => handleStartYear(e.target.value)}>
                {startYears.map((curYear, idx) => (
                  <option key={idx} value={curYear}>
                    {curYear}
                  </option>
                ))}
              </select>
            </div>
            <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between">
              <label className="bold">Preferred Position</label>
              <select value={isAnalyst ? "true" : "false"} className="border-[1px] border-solid border-gray-300 rounded-full w-[224px]" onChange={(e) => handleIsAnalyst(e.target.value === 'true')}>
                <option value="true">Software Analyst</option>
                <option value="false">UX Designer</option>
              </select>
            </div>
          </div>

          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">Major(s)</label>
            <CreatableSelect
              isMulti
              options={majors}
              value={major.map((m) => {
                return { value: m, label: m };
              })}
              className="w-[70%]"
              required
              onChange={(selectedOptions) => {
                handleMajor(removeIsNewField(selectedOptions));
              }}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">Minor(s)</label>
            <CreatableSelect
              isMulti
              options={minors}
              value={minor.map((m) => {
                return { value: m, label: m };
              })}
              className="w-[70%]"
              onChange={(selectedOptions) => {
                handleMinor(removeIsNewField(selectedOptions));
              }}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">Campus Involvements</label>
            <CreatableSelect
              isMulti
              options={involvements}
              value={involvement.map((i) => {
                return { value: i, label: i };
              })}
              className="w-[70%]"
              onChange={(selectedOptions) => {
                handleInvolvement(removeIsNewField(selectedOptions));
              }}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">Relevant Coursework</label>
            <CreatableSelect
              isMulti
              options={courses}
              value={coursework.map((c) => {
                return { value: c, label: c };
              })}
              className="w-[70%]"
              onChange={(selectedOptions) => {
                handleCoursework(removeIsNewField(selectedOptions));
              }}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">
              Familiar Technologies
            </label>
            <CreatableSelect
              isMulti
              options={technologiesArray}
              value={familiarTechnologies.map((link) => {
                return { value: link, label: link };
              })}
              className="w-[70%]"
              onChange={(selectedOptions) => {
                handleFamiliarTechnologies(removeIsNewField(selectedOptions));
              }}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">
              Relevant Links (LinkedIn, GitHub, etc)
            </label>
            <CreatableSelect
              isMulti
              options={[]}
              value={links.map((link) => {
                return { value: link, label: link };
              })}
              className="w-[70%]"
              onChange={(selectedOptions) => {
                handleLinks(removeIsNewField(selectedOptions));
              }}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
            <label className="bold w-[30%]">
              Known Atlas Members (this will not affect your chances at admission and will solely be used to evaluate conflicts of interest)
            </label>
            <CreatableSelect
              isMulti
              options={namesArray}
              value={knownAtlasMembers.map((link) => {
                return { value: link, label: link };
              })}
              className="w-[70%]"
              onChange={(selectedOptions) => {
                handleKnownAtlasMembers(removeIsNewField(selectedOptions));
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
