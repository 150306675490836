import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { getUserProfile, applicantUpdate } from "../libs/firebaseCalls";
import { pfpUpload, resumeUpload } from "../libs/storageCalls.js";
import { AuthData } from "./AuthWrapper";
import { ApplicantViewProfile } from "./ApplicantViewProfile.js";
import { ApplicantEditProfile } from "./ApplicantEditProfile.js";
import { SubmissionError } from "./SubmissionError.js";
import { Loading } from "./Loading";
// import "../css/ApplicantProfile.css";
import { Popup } from "./Popup.js";

export const ApplicantProfile = () => {
  const { user } = AuthData();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [year, setYear] = useState("Winter 2028");
  const [startYear, setStartYear] = useState("Fall 2024");
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [coursework, setCoursework] = useState([]);
  const [involvement, setInvolvement] = useState([]);
  const [links, setLinks] = useState([]);
  const [isAnalyst, setIsAnalyst] = useState(false);
  const [pfp, setPFP] = useState("");
  const [resume, setResume] = useState("");
  const [responses, setResponses] = useState([])
  const [popupOpen, setPopupOpen] = useState(false);
  const [isPfpPopup, setIsPfpPopup] = useState(true);
  const [isFileSaving, setIsFileSaving] = useState(false);
  const [pfpFile, setPfpFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [familiarTechnologies, setFamiliarTechnologies] = useState([]);
  const [knownAtlasMembers, setKnownAtlasMembers] = useState([]);
  const [originalProfile, setOriginalProfile] = useState({});

  const checkComplete = () => {
    let responsesFilled = true;
    for (const response of responses) {
      if (!response) {
        responsesFilled = false;
      }
    }
    if (!first || !last || !major || major.length === 0 || !pfp || !resume || !responsesFilled) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const newProfile = await getUserProfile(user.email, false);
        setOriginalProfile(newProfile);
        setFirst(newProfile.first);
        setLast(newProfile.last);
        setYear(newProfile.class);
        setStartYear(newProfile.startYear);
        setMajor(newProfile.major);
        setMinor(newProfile.minor);
        setCoursework(newProfile.coursework);
        setInvolvement(newProfile.involvement);
        setFamiliarTechnologies(newProfile.familiarTechnologies || []);
        setLinks(newProfile.links);
        setIsAnalyst(newProfile.isAnalyst);
        setPFP(newProfile.pfpURL);
        setResume(newProfile.resumeURL);
        setResponses(newProfile.essayResponses);
        setKnownAtlasMembers(newProfile.knownAtlasMembers || []);
      } catch (error) {
        console.error("Error fetching profile or executive details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.email]);

  const saveResponses = async (e) => {
    e.preventDefault();
    setShowConfetti(false);
    setSaving(true);
    let userObject = {
      first: first,
      last: last,
      class: year,
      startYear: startYear,
      major: major,
      minor: minor,
      coursework: coursework,
      involvement: involvement,
      familiarTechnologies: familiarTechnologies,
      links: links,
      isAnalyst: isAnalyst,
      knownAtlasMembers: knownAtlasMembers
    }
    const completed = checkComplete();
    userObject.completed = completed;
    await applicantUpdate(
      user.email,
      userObject
    );
    setSaving(false);
    setEdit(false);
    if (completed) {
      setShowConfetti(true);
    }
  };

  const handleFirst = (n) => {
    setFirst(n);
  };

  const handleLast = (n) => {
    setLast(n);
  };

  const handleYear = (n) => {
    setYear(n);
  };

  const handleStartYear = (n) => {
    setStartYear(n);
  }

  const handleMajor = (n) => {
    setMajor(n);
  };

  const handleMinor = (n) => {
    setMinor(n);
  };

  const handleInvolvement = (n) => {
    setInvolvement(n);
  };

  const handleCoursework = (n) => {
    setCoursework(n);
  };

  const handleFamiliarTechnologies = (n) => {
    setFamiliarTechnologies(n);
  }

  const handleKnownAtlasMembers = (n) => {
    setKnownAtlasMembers(n);
  }

  const handleLinks = (n) => {
    setLinks(n);
  };

  const handleIsAnalyst = (n) => {
    setIsAnalyst(n);
  };

  const handlePfpFileChange = (n) => {
    setPfpFile(n);
  };

  const handleResumeFileChange = (n) => {
    setResumeFile(n);
  };

  const handlePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const handlePfpPopup = () => {
    setIsPfpPopup(true);
    handlePopup();
  };

  const handleResumePopup = () => {
    setIsPfpPopup(false);
    handlePopup();
  };

  const handlePfpSave = async () => {
    setIsFileSaving(true);
    try {
      const pfpURL = await pfpUpload(user.email.split("@")[0], pfpFile, setErrorMessage);
      if (pfpURL) {
        let appObj = {
          pfpURL: pfpURL
        }
        setPFP(pfpURL);
        setOriginalProfile((prevProfile) => {
          return { ...prevProfile, pfpURL: pfpURL }
        })
        appObj.completed = checkComplete();
        await applicantUpdate(user.email, appObj);
      }
    } finally {
      setIsFileSaving(false);
      setPopupOpen(false);
    }
  };

  const handleResumeSave = async () => {
    setIsFileSaving(true);
    try {
      const resumeURL = await resumeUpload(user.email.split("@")[0], resumeFile, setErrorMessage, first, last);
      if (resumeURL) {
        let appObj = {
          resumeURL: resumeURL
        }
        setResume(resumeURL);
        setOriginalProfile((prevProfile) => {
          return { ...prevProfile, resumeURL: resumeURL }
        })
        appObj.completed = checkComplete();
        await applicantUpdate(user.email, appObj);
      }
    } finally {
      setIsFileSaving(false);
      setPopupOpen(false);
    }
  };

  const handleCancel = async () => {
    setFirst(originalProfile.first);
    setLast(originalProfile.last);
    setMajor(originalProfile.major);
    setMinor(originalProfile.minor);
    setCoursework(originalProfile.coursework);
    setInvolvement(originalProfile.involvement);
    setLinks(originalProfile.links);
    setIsAnalyst(originalProfile.isAnalyst);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {showConfetti &&
        <Confetti
          recycle={false}
          numberOfPieces={600}
          initialVelocityY={15}
          gravity={0.3}
          colors={["#1AADE3", "#000000", "#FFFFFF", "#DDE9FF"]}
        />
      }
      <div className="font-kanit space-y-12">
        <div className="flex justify-between items-center">
          <div className="bold text-5xl">Profile</div>
          {user.round === 0 && <div className="flex space-x-2">
            {!saving && edit && (
              <div onClick={(e) => saveResponses(e)} className="uppercase bg-ad-blue shadow-light rounded-full py-2 px-4 bold text-white hover:text-ad-dark duration-200 hover:bg-white hover:cursor-pointer">Save</div>
            )}
            {saving && edit && <div className="saving shadow-light rounded-full py-2 px-4 bold ">Saving...</div>}
            <div
              onClick={() => {
                if (edit) {
                  handleCancel();
                }
                setEdit(!edit)
              }}
              className="bg-ad-blue shadow-light rounded-full py-2 px-4 bold text-white hover:text-ad-dark duration-200 hover:bg-white hover:cursor-pointer"
            >
              {edit ? "CANCEL" : "EDIT"}
            </div>
          </div>}
          {errorMessage && <SubmissionError message={errorMessage} setErrorMessage={setErrorMessage} />}
        </div>
        <div className="">
          {!edit ? (
            <ApplicantViewProfile
              pfp={pfp}
              resume={resume}
              first={first}
              last={last}
              year={year}
              startYear={startYear}
              isAnalyst={isAnalyst}
              major={major}
              minor={minor}
              involvement={involvement}
              coursework={coursework}
              knownAtlasMembers={knownAtlasMembers}
              familiarTechnologies={familiarTechnologies}
              links={links}
              handlePfpPopup={handlePfpPopup}
              handleResumePopup={handleResumePopup}
            />
          ) : (
            <ApplicantEditProfile
              saveResponses={saveResponses}
              first={first}
              last={last}
              year={year}
              startYear={startYear}
              major={major}
              minor={minor}
              involvement={involvement}
              familiarTechnologies={familiarTechnologies}
              knownAtlasMembers={knownAtlasMembers}
              removeIsNewField={(state) => state.map((obj) => obj["value"])}
              coursework={coursework}
              links={links}
              isAnalyst={isAnalyst}
              handleFirst={handleFirst}
              handleLast={handleLast}
              handleYear={handleYear}
              handleStartYear={handleStartYear}
              handleMajor={handleMajor}
              handleMinor={handleMinor}
              handleInvolvement={handleInvolvement}
              handleCoursework={handleCoursework}
              handleFamiliarTechnologies={handleFamiliarTechnologies}
              handleKnownAtlasMembers={handleKnownAtlasMembers}
              handleLinks={handleLinks}
              handleIsAnalyst={handleIsAnalyst}
              handlePfpPopup={handlePfpPopup}
              handleResumePopup={handleResumePopup}
            />
          )}
        </div>
      </div>

      <div className={`${popupOpen && isPfpPopup ? 'opacity-100' : 'opacity-0 pointer-events-none'} duration-200`}>
        <Popup title="Select Profile Picture:" closePopup={handlePopup} loading={isFileSaving}>
          <>
            <input type="file" accept="image/*" onChange={(e) => handlePfpFileChange(e.target.files[0])} />
            <br />
            <div type="button" className="border-2 border-ad-blue bg-white rounded-full text-ad-blue text-center bold uppercase py-1 hover:bg-ad-blue hover:text-white hover:cursor-pointer duration-100" onClick={handlePfpSave}>
              Save
            </div>
          </>
        </Popup>
      </div>

      <div className={`${popupOpen && !isPfpPopup ? 'opacity-100' : 'opacity-0 pointer-events-none'} duration-200`}>
        <Popup title="Select Resume File:" closePopup={handlePopup} loading={isFileSaving}>
          <>
            <input type="file" accept="application/pdf,.doc,.docx" onChange={(e) => handleResumeFileChange(e.target.files[0])} />
            <br />
            <button type="button" className="border-2 border-ad-blue bg-white rounded-full text-ad-blue text-center bold uppercase py-1 hover:bg-ad-blue hover:text-white hover:cursor-pointer duration-100" onClick={handleResumeSave}>
              Save
            </button>
          </>
        </Popup >
      </div>
    </>
  );
};
