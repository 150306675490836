// import { db } from "../configs/firebase";
import { storage } from "../configs/firebase";
import { getDownloadURL, ref, uploadBytes, listAll, deleteObject } from "firebase/storage";
// import { collection, doc, getDoc } from "firebase/firestore";
import imageCompression from 'browser-image-compression';
import { members } from "../data/data";
// import * as pdfjsLib from 'pdfjs-dist/webpack';

import { db } from "../configs/firebase";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

// import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export const pfpUpload = async (uniqname, imageFile, setErrorMessage) => {
  let pfpLarge = false;
  try {
    const fileSizeLimit = 10 * 1024 * 1024; // 10 MB limit
    if (imageFile.size > fileSizeLimit) {
      pfpLarge = true;
      setErrorMessage("ERROR: PROFILE PICTURE MUST BE SMALLER THAN 10 MB");
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
      throw new Error("File size exceeds the limit");
    }
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      maxIteration: 10,
    };

    const compressedFile = await imageCompression(imageFile, options);

    const imageRef = members.has(uniqname)
      ? ref(storage, `members/profilePictures/${uniqname}`)
      : ref(storage, `applicants/profilePictures/${uniqname}`);
      
    const metadata = {
      cacheControl: 'public, max-age=31536000', // Cache for 1 year
    };

    await uploadBytes(imageRef, compressedFile, metadata);
    const url = await getDownloadURL(imageRef);
    console.log("Successfully uploaded pfp.")
    return url;
  } catch (e) {
    if(!pfpLarge){
      setErrorMessage("FAILED TO UPLOAD PROFILE PICTURE. PLEASE TRY AGAIN.");
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
    console.error("Error uploading pfp", e);
    return null; // Indicate failure
  }
};

// export const pfpRetrieval = async (uniqname) => { //generally, shouldn't be used, just get user data
//   try {
//     const applicant = await getDoc(doc(collection(db, "applicants"), uniqname + "@umich.edu"));
//     if (applicant.data().pfpURL === "") {
//       return defaultPfp;
//     }
//     return applicant.data().pfpURL;
//   } catch (e) {
//     console.log("Error retrieving pfp, returning default", e);
//     return defaultPfp;
//   }
// };

// export const resumeUpload = async (uniqname, resumeFile, setErrorMessage) => { //resume upload without anonymization, see below
//   try {
//     const fileSizeLimit = 1 * 1024 * 1024; // 1.5 MB limit (adjust as needed)
//     if (resumeFile.size > fileSizeLimit) {
//       setErrorMessage("ERROR: RESUME MUST BE SMALLER THAN 1 MB");
//       setTimeout(() => {
//         setErrorMessage("");
//       }, 4000);
//       throw new Error("File size exceeds the limit");
//     }

//     const imageRef = members.has(uniqname)
//       ? ref(storage, `members/resumes/${uniqname}`)
//       : ref(storage, `applicants/resumes/${uniqname}`);
//     await uploadBytes(imageRef, resumeFile);
//     const url = await getDownloadURL(imageRef);
//     return url;
//   } catch (e) {
//     console.log("Error uploading resume", e);
//     return null; // Indicate failure
//   }
// };

// const readAsArrayBuffer = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//     reader.readAsArrayBuffer(file);
//   });
// };

const isPdf = (file) => {
  return file.type === 'application/pdf';
};

// const extractTextWithPositions = async (pdfBytes) => {
//   const pdf = await pdfjsLib.getDocument({ data: pdfBytes }).promise;
//   const textItems = [];

//   for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
//     const page = await pdf.getPage(pageNum);
//     const textContent = await page.getTextContent();

//     textContent.items.forEach(item => {
//       if (item.str) {
//         textItems.push({
//           str: item.str,
//           x: item.transform[4], // X position
//           y: item.transform[5], // Y position
//           width: item.width, // Text width
//           height: item.height // Text height
//         });
//       }
//     });
//   }

//   return textItems;
// };

export const resumeUpload = async (uniqname, resumeFile, setErrorMessage, first, last) => { //upload with anonymization of name, email, phone numbers, and linkedin
  let resumeLarge = false;
  try {
    const fileSizeLimit = 1 * 1024 * 1024; // 1 MB limit (adjust as needed)
    if (resumeFile.size > fileSizeLimit) {
      resumeLarge = true;
      setErrorMessage("ERROR: RESUME MUST BE SMALLER THAN 1 MB");
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
      throw new Error("File size exceeds the limit");
    }

    if (!isPdf(resumeFile)) {
      setErrorMessage("ERROR: FILE MUST BE A PDF");
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
      throw new Error("File is not a PDF");
    }
    if (members.has(uniqname)) {
      let memberRef = ref(storage, `members/resumes/${uniqname}`);
      await uploadBytes(memberRef, resumeFile);
      const url = await getDownloadURL(memberRef);
      return url;
    }

    //STUFF TO ANONYMIZE UPON UPLOAD. CURRENTLY SET TO ANONYMIZE UPON FETCH DURING APPLICATION GRADING
    // const arrayBuffer = await readAsArrayBuffer(resumeFile);
    // const pdfBytes = new Uint8Array(arrayBuffer);

    // function arrayBufferToBase64(buffer) {
    //   var binary = '';
    //   var bytes = new Uint8Array(buffer);
    //   var len = bytes.byteLength;
    //   for (var i = 0; i < len; i++) {
    //     binary += String.fromCharCode(bytes[i]);
    //   }
    //   return window.btoa(binary);
    // }
    // const textItems = await extractTextWithPositions(pdfBytes);
    // const arrayB2 = await readAsArrayBuffer(resumeFile);
    // const b64data = arrayBufferToBase64(arrayB2);
    // const pdfDoc = await PDFDocument.load(b64data, { ignoreEncryption: true });
    // const pages = pdfDoc.getPages();
    // const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    // const phoneRegex = /\b(\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?|\d{3})[-.\s]?\d{3}[-.\s]?\d{4})\b/g;
    // const linkedInRegex = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+/g;
    // const firstNameRegex = new RegExp(`\\b${first}\\b`, 'gi');
    // const lastNameRegex = new RegExp(`\\b${last}\\b`, 'gi');
    // const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    // textItems.forEach(({ str, x, y, width, height }) => {
    //   if (str.match(emailRegex) || str.match(phoneRegex) || str.match(linkedInRegex) || str.match(firstNameRegex) || str.match(lastNameRegex)) {
    //     console.log(str)
    //     const pageIndex = Math.floor(y / pages[0].getHeight());
    //     const page = pages[pageIndex];
    //     page.drawRectangle({
    //       x,
    //       y: y - 4,
    //       width,
    //       height: height + 8,
    //       color: rgb(0, 0, 0),
    //     });
    //     const textWidth = font.widthOfTextAtSize('[REDACTED]', height);
    //     const centeredX = x + (width - textWidth) / 2;
    //     const centeredY = y - 4 + (height + 8 - height / 2) / 2;
    //     page.drawText('[REDACTED]', {
    //       x: centeredX,
    //       y: centeredY,
    //       size: height,
    //       font,
    //       color: rgb(1, 1, 1),
    //     });
    //   }
    // });
    // const pdfBytesModified = await pdfDoc.save();
    // const anonymizedPdfBlob = new Blob([pdfBytesModified], { type: 'application/pdf' });

    const imageRef = ref(storage, `applicants/resumes/${uniqname}`);
    await uploadBytes(imageRef, resumeFile);
    // await uploadBytes(imageRef, anonymizedPdfBlob);
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (e) {
    console.log("Error uploading resume", e);
    if(!resumeLarge){
      setErrorMessage("FAILED TO UPLOAD RESUME. PLEASE TRY AGAIN.");
    }
    return null;
  }
};

export async function moveProfilePictures() {
  const oldPath = 'profilePictures/';
  const newPath = 'members/profilePictures/';
  const storageRef = ref(storage, oldPath);

  try {
      // List all files in the old directory
      const listResult = await listAll(storageRef);

      // Loop through each file and move it
      for (const itemRef of listResult.items) {
          const fileName = itemRef.name;
          const newFileRef = ref(storage, `${newPath}${fileName}`);

          // Get the download URL for the existing file
          const fileUrl = await getDownloadURL(itemRef);

          // Fetch the file as a blob
          const response = await fetch(fileUrl);
          const fileBlob = await response.blob();

          // Upload the blob to the new path
          await uploadBytes(newFileRef, fileBlob);

          // Delete the original file
          await deleteObject(itemRef);
          
          console.log(`Moved: ${fileName}`);
      }

      console.log("All files moved successfully.");
  } catch (error) {
      console.error("Error moving files:", error);
  }
}





export async function updateMemberPfpURLs() {
  const membersCollection = collection(db, 'members');
  const newPfpPath = 'members/profilePictures/';
  const possibleExtensions = ['.jpg', '.jpeg', '.JPG', '.JPEG', '.png'];

  try {
      // Get all member documents
      const membersSnapshot = await getDocs(membersCollection);

      // Loop through each document in the members collection
      for (const memberDoc of membersSnapshot.docs) {
          const memberId = memberDoc.id;
          const memberUniq = memberId.split('@')[0];
          let newDownloadURL = null;

          // Try each possible file extension until one succeeds
          for (const extension of possibleExtensions) {
              const newFileRef = ref(storage, `${newPfpPath}${memberUniq}${extension}`);
              try {
                  newDownloadURL = await getDownloadURL(newFileRef);
                  break; // Exit the loop once a valid URL is found
              } catch (err) {
                  console.log(`File not found with extension ${extension} for member: ${memberId}`);
              }
          }

          // If a valid URL was found, update the Firestore document
          if (newDownloadURL) {
              const memberDocRef = doc(db, 'members', memberId);
              await updateDoc(memberDocRef, {
                  pfpURL: newDownloadURL
              });

              console.log(`Updated pfpURL for member: ${memberId}`);
          } else {
              console.error(`Failed to find profile picture for member: ${memberId}`);
          }
      }

      console.log("All member pfpURLs updated successfully.");
  } catch (error) {
      console.error("Error updating member pfpURLs:", error);
  }
}



// export const resumeRetrieval = async (uniqname) => { //generally, shouldn't be used, just get applicant data
//   try {
//     const imageRef = members.has(uniqname)
//       ? ref(storage, `members/resumes/${uniqname}`)
//       : ref(storage, `applicants/resumes/${uniqname}`);
//     const url = await getDownloadURL(imageRef);
//     return url;
//   } catch (e) {
//     console.log("Error retrieving resume", e);
//     return null;
//   }
// };