import { React, useEffect, useState } from "react";
import { applicantSaveSchedule, getSchedules } from "../libs/firebaseCalls";
import Loading from "./Loading";
import { roundNames } from "../data/data";
import { AuthData } from "./AuthWrapper";
import { SubmissionError } from "./SubmissionError.js"

export const ApplicantSchedule = () => {
  const { user } = AuthData();
  const [loading, setLoading] = useState(true);
  const [currSchedule, setCurrSchedule] = useState({});
  const [changeSchedule, setChangeSchedule] = useState({});
  const [failedSaves, setFailedSaves] = useState("");
  const [indices, setIndices] = useState([]); //locationIdx, timeIdx
  const [initIndices, setInitIndices] = useState([]);
  const [selectedRound, setSelectedRound] = useState(user.round);
  const [allSchedules, setAllSchedules] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const allSchedulesDocs = await getSchedules();
      let allSchedulesTemp = {};
      for (const schedule of allSchedulesDocs) {
        allSchedulesTemp[schedule.id] = schedule.data();
      }
      setAllSchedules(allSchedulesTemp);
      if (allSchedulesTemp[roundNames[user.round]]) {
        let selectedSchedule = allSchedulesTemp[roundNames[user.round]];
        const uniqname = user.email.split('@')[0];
        let found = false;
        for (let i = 0; i < selectedSchedule.locations.length; i++) {
          for (let j = 0; j < selectedSchedule.locations[i].times.length; j++) {
            if (selectedSchedule.locations[i].times[j].applicants.includes(uniqname)) {
              setIndices([i, j]);
              setInitIndices([i, j]);
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
        setCurrSchedule(cloneSchedule(selectedSchedule));
        setChangeSchedule(cloneSchedule(selectedSchedule));
      }
      setLoading(false);
    };
    fetchData();
  }, [user]);

  const cloneSchedule = (schedule) => {
    return {
      ...schedule,
      date: schedule.date,  // Ensure date is properly handled
      locations: schedule.locations.map(location => ({
        ...location,
        times: location.times.map(time => ({
          ...time,
          members: [...time.members],
          applicants: [...time.applicants]
        }))
      })),
      open: schedule.open
    };
  };

  const formatDateTime = (date) => {
    const options = {
      weekday: "long",
      month: "numeric",
      day: "numeric",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const applicantSignUp = async (locIdx, timeIdx) => {
    setChangeSchedule((prevSchedule) => {
      const newSchedule = cloneSchedule(prevSchedule);
      const timeSlot = newSchedule.locations[locIdx].times[timeIdx];
      if (timeSlot.applicants.length < prevSchedule.maxApplicants && !timeSlot.applicants.includes(user.email.split("@")[0])) {
        timeSlot.applicants.push(user.email.split("@")[0]);
        if (indices.length) {
          const oldTimeSlot = newSchedule.locations[indices[0]].times[indices[1]];
          oldTimeSlot.applicants = oldTimeSlot.applicants.filter(
            (uniqname) => uniqname !== user.email.split("@")[0]
          );
        }
      }
      return newSchedule;
    });
    setIndices([locIdx, timeIdx]); // Assuming setIndices takes an array as input
  };

  const handleSaveSignUps = async () => {
    setLoading(true);
    if (indices.length && !currSchedule.locations[indices[0]].times[indices[1]].applicants.includes(user.email.split('@')[0])) {
      const resp = await applicantSaveSchedule(roundNames[selectedRound], indices, user.email.split('@')[0]);
      const newSchedule = resp.updatedSchedule;
      if (resp.failedAdd.length) {
        const newFails = `Sign up for ${newSchedule.locations[resp.failedAdd[0]].location} at ${newSchedule.locations[resp.failedAdd[0]].times[resp.failedAdd[1]].time} failed. Slots are full.\n`;
        setFailedSaves(newFails);
        setIndices(resp.oldIndices);
      } else {
        setFailedSaves("");
        setInitIndices([...indices]);
      }
      setCurrSchedule(newSchedule);
      setChangeSchedule(newSchedule);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setChangeSchedule(cloneSchedule(currSchedule));  // Use clone function
    setIndices([...initIndices]);
  };

  const handleChangeSelectedRound = (roundName) => {
    let roundNum = 0;
    for (let i = 0; i < Object.values(roundNames).length; i++) {
      if (roundNames[i] === roundName) {
        roundNum = i;
        break;
      }
    }
    setSelectedRound(roundNum);
    if (allSchedules[roundNames[roundNum]]) {
      let selectedSchedule = allSchedules[roundNames[roundNum]];
      const uniqname = user.email.split('@')[0];
      let found = false;
      for (let i = 0; i < selectedSchedule.locations.length; i++) {
        for (let j = 0; j < selectedSchedule.locations[i].times.length; j++) {
          if (selectedSchedule.locations[i].times[j].applicants.includes(uniqname)) {
            setIndices([i, j]);
            setInitIndices([i, j]);
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      setCurrSchedule(cloneSchedule(selectedSchedule));
      setChangeSchedule(cloneSchedule(selectedSchedule));
    } else {
      setCurrSchedule({});
      setChangeSchedule({});
    }
  }

  if (loading) {
    return <Loading />;
  }


  return (
    <>
      <div className="font-kanit flex flex-col w-[100%] space-y-8">
        {failedSaves &&
          <SubmissionError
            message={failedSaves}
            setErrorMessage={setFailedSaves}
          />}
        <div>
          <select value={roundNames[selectedRound]} onChange={(e) => { handleChangeSelectedRound(e.target.value) }}>
            {//eslint-disable-next-line
              Object.values(roundNames).map((n, idx) => {
                if (n.includes("Interview")) {
                  return (
                    <option key={idx}>{n}</option>
                  )
                }
              })}
          </select>
        </div>
        <div className="w-full bold text-5xl text-center">{roundNames[selectedRound]} Scheduling</div>
        {!Object.keys(changeSchedule).length || !changeSchedule.open ?
          (
            <div>
              No schedule yet available.
            </div>
          )
          :
          (
            <div className="flex flex-col w-[100%] p-8 rounded-3xl bg-ad-light-blue/50 shadow-xl space-y-8">
              <div className="flex flex-col w-[100%] border-solid p-[30px] rounded-3xl bg-[#F1F6FF]">
                <div className="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 md:space-x-8 items-center">
                  {roundNames[selectedRound] === "Final Interviews " ?
                    <div className="flex flex-col space-y-2 w-40 sm:w-[200px] lg:w-40 text-center">
                      <div className="bg-green-300 rounded-full">Analyst Interview</div>
                      <div className="bg-purple-300 rounded-full">Designer Interview</div>
                      <div className="bg-red-300 rounded-full">Full</div>
                    </div>
                    :
                    <div className="flex flex-col space-y-2 w-40 sm:w-[200px] lg:w-40 text-center">
                      <div className="bg-green-300 rounded-full">Open</div>
                      <div className="bg-red-300 rounded-full">Full</div>
                    </div>
                  }
                  <div className="bold text-3xl uppercase text-center">
                    {formatDateTime(currSchedule.date.toDate())}
                  </div>
                  <div className="flex flex-col justify-center w-40 text-center space-y-2">
                    <div
                      className="bg-ad-blue text-white bold uppercase rounded-full shadow-light hover:text-ad-dark hover:bg-white w-40 py-2 hover:cursor-pointer duration-100"
                      onClick={handleSaveSignUps}
                    >Save Changes</div>
                    <div
                      className="bg-ad-blue text-white bold uppercase rounded-full shadow-light hover:text-ad-dark hover:bg-white w-40 py-2 hover:cursor-pointer duration-100"
                      onClick={handleCancel}
                    >Cancel Changes</div>
                  </div>
                </div>
                <div className="scheduleGridContainer text-left">
                  {changeSchedule.locations.map((location, i) => {
                    return (
                      <div key={i} className="scheduleGridItem">
                        <div className="flex flex-col ">
                          <b>Location:</b>
                          <div className="ml-[20px] gap-[10px] flex flex-row align-center">
                            {location.location}
                          </div>
                        </div>
                        <div className="h-[10px] mb-[10px]" />
                        <b>Times:</b>
                        <div className="flex flex-col gap-[3px]">
                          {location.times.map((time, idx) => {
                            return (
                              <div key={idx} className={`flex flex-col align-center`}>
                                <div
                                  className={`pl-[20px] mb-[3px] rounded-lg ${time.applicants.length >= currSchedule.maxApplicants
                                    ? "bg-red-300"
                                    : time.isAnalyst
                                      ? "bg-green-300"
                                      : "bg-purple-300"
                                    }`}
                                >
                                  <b>{time.time}</b>
                                </div>
                                <div className="flex flex-col justify-center">
                                  {time.applicants.map((applicant, applicantIdx) => (
                                    <div key={applicantIdx} className="pl-[35px]">
                                      {applicant}
                                    </div>
                                  ))}
                                  {time.applicants.length < currSchedule.maxApplicants && !time.applicants.includes(user.email.split("@")[0]) && (
                                    <button
                                      className="ml-[35px] rounded-md p-[3px] w-fit text-sm"
                                      onClick={() => applicantSignUp(i, idx)}
                                    >
                                      Sign Up for Slot
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}