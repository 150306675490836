import React from "react";
import CreatableSelect from "react-select/creatable";
import { minors, majors, years } from "../data/data.js";
import "../css/ApplicantProfile.css";

export const MemberEditProfile = ({
  saveResponses,
  first,
  last,
  year,
  major,
  minor,
  removeIsNewField,
  links,
  number,
  handleFirst,
  handleLast,
  handleYear,
  handleMajor,
  handleMinor,
  handleLinks,
  handleNumber,
  handlePfpPopup,
  handleResumePopup,
}) => {

  const save = (e) => {
    e.preventDefault(); // Prevent default form submission
    saveResponses(e)
    handleFirst(first)
  };

  return (
    <>
      <form onSubmit={save} className="space-y-4 w-full">

        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <div className="bold border-2 border-ad-blue text-ad-blue w-fit px-4 py-1 rounded-full hover:cursor-pointer hover:bg-ad-blue hover:text-white duration-100" onClick={handlePfpPopup}>Add / Update Profile Picture</div>
          <div className="bold border-2 border-ad-blue text-ad-blue w-fit px-4 py-1 rounded-full hover:cursor-pointer hover:bg-ad-blue hover:text-white duration-100" onClick={handleResumePopup}>Add / Update Resume</div>
        </div>

        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 w-full lg:w-1/2 flex justify-between h-fit">
            <label className="bold">First Name</label>
            <input
              type="text"
              className="border-[1px] border-solid rounded-full border-gray-300 px-3"
              value={first}
              required
              onChange={(e) => handleFirst(e.target.value)}
            />
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 w-full lg:w-1/2 flex justify-between h-fit">
            <label className="bold">Last Name</label>
            <input
              type="text"
              className="border-[1px] border-solid rounded-full border-gray-300 px-3"
              value={last}
              required
              onChange={(e) => handleLast(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 w-full">
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between h-fit">
            <label className="bold">Anticipated Graduation</label>
            <select value={year} className="border-[1px] border-solid border-gray-300 rounded-full w-[224px]" onChange={(e) => handleYear(e.target.value)}>
              {years.map((curYear, idx) => (
                <option key={idx} value={curYear}>
                  {curYear}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl space-x-4 lg:w-1/2 flex justify-between h-fit">
            <label className="bold">Number</label>
            <input
              type="text"
              className="border-[1px] border-solid rounded-full border-gray-300 px-3"
              value={number}
              required
              onChange={(e) => handleNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
          <label className="bold">Major(s)</label>
          <CreatableSelect
            isMulti
            options={majors}
            value={major.map((m) => {
              return { value: m, label: m };
            })}
            className="w-[70%]"
            required
            onChange={(selectedOptions) => {
              handleMajor(removeIsNewField(selectedOptions));
            }}
          />
        </div>
        <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
          <label className="bold">Minor(s)</label>
          <CreatableSelect
            isMulti
            options={minors}
            value={minor.map((m) => {
              return { value: m, label: m };
            })}
            className="w-[70%]"
            onChange={(selectedOptions) => {
              handleMinor(removeIsNewField(selectedOptions));
            }}
          />
        </div>

        <div className="bg-ad-light-blue/50 shadow p-4 rounded-xl w-full space-x-4 flex justify-between items-center">
          <label className="bold">
            Relevant Links (LinkedIn, GitHub, etc)
          </label>
          <CreatableSelect
            isMulti
            options={[]}
            value={links.map((link) => {
              return { value: link, label: link };
            })}
            className="w-[70%]"
            onChange={(selectedOptions) => {
              handleLinks(removeIsNewField(selectedOptions));
            }}
          />
        </div>
      </form>
    </>
  );
};
