import { React, useEffect, useState } from "react";
import { getSchedules, memberSaveSchedule } from "../libs/firebaseCalls";
import Loading from "./Loading";
import { roundNames } from "../data/data";
import { AuthData } from "./AuthWrapper";
import { SubmissionError } from "./SubmissionError.js"
import { IoClose } from "react-icons/io5";

export const MemberSchedule = () => {
  const { user } = AuthData();
  const [loading, setLoading] = useState(true);
  const [currSchedule, setCurrSchedule] = useState({});
  const [changeSchedule, setChangeSchedule] = useState({});
  const [changes, setChanges] = useState([]);
  const [failedSaves, setFailedSaves] = useState("");
  const [selectedRound, setSelectedRound] = useState(2);
  const [allSchedules, setAllSchedules] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const allSchedulesDocs = await getSchedules();
      let allSchedulesTemp = {};
      for (const schedule of allSchedulesDocs) {
        allSchedulesTemp[schedule.id] = schedule.data();
      }
      setAllSchedules(allSchedulesTemp);
      if (allSchedulesTemp[roundNames[2]]) {
        let selectedSchedule = allSchedulesTemp[roundNames[2]];
        const uniqname = user.email.split('@')[0];
        let found = false;
        for (let i = 0; i < selectedSchedule.locations.length; i++) {
          for (let j = 0; j < selectedSchedule.locations[i].times.length; j++) {
            if (selectedSchedule.locations[i].times[j].applicants.includes(uniqname)) {
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
        setCurrSchedule(cloneSchedule(selectedSchedule));
        setChangeSchedule(cloneSchedule(selectedSchedule));
      }
      setLoading(false);
    };
    fetchData();
  }, [user]);

  const cloneSchedule = (schedule) => {
    return {
      ...schedule,
      date: schedule.date,  // Ensure date is properly handled
      locations: schedule.locations.map(location => ({
        ...location,
        times: location.times.map(time => ({
          ...time,
          members: [...time.members],
          applicants: [...time.applicants]
        }))
      })),
      open: schedule.open
    };
  };

  const formatDateTime = (date) => {
    const options = {
      weekday: "long",
      month: "numeric",
      day: "numeric",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const addMember = (locIdx, timeIdx) => {
    setChangeSchedule((prevSchedule) => {
      const newSchedule = cloneSchedule(prevSchedule);
      const timeSlot = newSchedule.locations[locIdx].times[timeIdx];
      if (timeSlot.members.length < 2 && !timeSlot.members.includes(user.email.split("@")[0])) {
        timeSlot.members.push(user.email.split("@")[0]);
      }
      return newSchedule;
    });

    setChanges((prevChanges) => {
      const existingChangeIndex = prevChanges.findIndex(
        change =>
          change.action === "remove" &&
          change.locIdx === locIdx &&
          change.timeIdx === timeIdx &&
          change.member === user.email.split("@")[0]
      );

      if (existingChangeIndex !== -1) {
        const updatedChanges = [...prevChanges];
        updatedChanges.splice(existingChangeIndex, 1);
        return updatedChanges;
      } else {
        return [
          ...prevChanges,
          { action: "add", locIdx, timeIdx, member: user.email.split("@")[0] },
        ];
      }
    });
  };

  const removeMember = (locIdx, timeIdx) => {
    setChangeSchedule((prevSchedule) => {
      const newSchedule = cloneSchedule(prevSchedule);
      const timeSlot = newSchedule.locations[locIdx].times[timeIdx];
      timeSlot.members = timeSlot.members.filter(member => member !== user.email.split("@")[0]);
      return newSchedule;
    });

    setChanges((prevChanges) => {
      const existingChangeIndex = prevChanges.findIndex(
        change =>
          change.action === "add" &&
          change.locIdx === locIdx &&
          change.timeIdx === timeIdx &&
          change.member === user.email.split("@")[0]
      );

      if (existingChangeIndex !== -1) {
        const updatedChanges = [...prevChanges];
        updatedChanges.splice(existingChangeIndex, 1);
        return updatedChanges;
      } else {
        return [
          ...prevChanges,
          { action: "remove", locIdx, timeIdx, member: user.email.split("@")[0] },
        ];
      }
    });
  };

  const handleSaveSignUps = async () => {
    // Implement saving logic here

    // memberSaveSchedule will return 
    // a list of failed changes, along with the new schedule with all/some/none of the changes saved
    setLoading(true);
    const resp = await memberSaveSchedule(roundNames[selectedRound], [...changes]);
    let newFailedChanges = ""
    const newSchedule = resp.updatedSchedule
    for (const change of resp.failedAdds) {
      newFailedChanges += `Sign up for ${newSchedule.locations[change.locIdx].location} at ${newSchedule.locations[change.locIdx].times[change.timeIdx].time} failed.\n`
    }
    setFailedSaves(newFailedChanges);
    setCurrSchedule(newSchedule);
    setChangeSchedule(newSchedule);
    setChanges([]);
    setLoading(false);
  };

  const handleCancel = () => {
    setChangeSchedule(cloneSchedule(currSchedule));  // Use clone function
  };

  const handleChangeSelectedRound = (roundName) => {
    let roundNum = 0;
    for (let i = 0; i < Object.values(roundNames).length; i++) {
      if (roundNames[i] === roundName) {
        roundNum = i;
        break;
      }
    }
    console.log(roundNum)
    setSelectedRound(roundNum);
    if (allSchedules[roundName]) {
      let selectedSchedule = allSchedules[roundName];
      const uniqname = user.email.split('@')[0];
      let found = false;
      for (let i = 0; i < selectedSchedule.locations.length; i++) {
        for (let j = 0; j < selectedSchedule.locations[i].times.length; j++) {
          if (selectedSchedule.locations[i].times[j].applicants.includes(uniqname)) {
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      setCurrSchedule(cloneSchedule(selectedSchedule));
      setChangeSchedule(cloneSchedule(selectedSchedule));
    } else {
      setCurrSchedule({});
      setChangeSchedule({});
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="font-kanit flex flex-col w-[100%] space-y-8">
        {failedSaves &&
          <SubmissionError
            message={failedSaves}
            setErrorMessage={setFailedSaves}
          />
        }
        <div>
          <select value={roundNames[selectedRound]} onChange={(e) => { handleChangeSelectedRound(e.target.value) }}>
            {Object.values(roundNames).map((n, idx) => {
              if (n.includes("Interview")) {
                return (
                  <option key={idx}>{n}</option>
                )
              }
              //eslint-disable-next-line
              return;
            })}
          </select>
        </div>
        <div className="w-full bold text-5xl text-center">{roundNames[selectedRound]} Scheduling</div>
        {!Object.keys(changeSchedule).length || !changeSchedule.open ?
          (
            <div>No schedule yet available.</div>
          )
          :
          (
            <div className="flex flex-col w-[100%] p-8 rounded-3xl bg-ad-light-blue/50 shadow-xl space-y-8">
              <div className="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 md:space-x-8 items-center">
                {roundNames[selectedRound] === "Final Interviews " ?
                  <div className="flex flex-col space-y-2 w-40 sm:w-[200px] lg:w-40 text-center">
                    <div className="bg-green-300 rounded-full">Analyst Interview</div>
                    <div className="bg-purple-300 rounded-full">Designer Interview</div>
                    <div className="bg-red-300 rounded-full">Full</div>
                  </div>
                  :
                  <div className="flex flex-col space-y-2 w-40 sm:w-[200px] lg:w-40 text-center">
                    <div className="bg-green-300 rounded-full">Open</div>
                    <div className="bg-red-300 rounded-full">Full</div>
                  </div>
                }

                <div className="bold text-3xl uppercase text-center">
                  {formatDateTime(currSchedule.date.toDate())}
                </div>


                <div className="flex flex-col justify-center w-40 text-center space-y-2">
                  <div
                    className="bg-ad-blue text-white bold uppercase rounded-full shadow-light hover:text-ad-dark hover:bg-white w-40 py-2 hover:cursor-pointer duration-100"
                    onClick={handleSaveSignUps}
                  >Save Changes</div>
                  <div
                    className="bg-ad-blue text-white bold uppercase rounded-full shadow-light hover:text-ad-dark hover:bg-white w-40 py-2 hover:cursor-pointer duration-100"
                    onClick={handleCancel}
                  >Cancel Changes</div>
                </div>
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                {changeSchedule.locations.map((location, i) => {
                  return (
                    <div key={i} className="bg-white px-8 py-4 rounded-xl shadow">
                      <div className="flex flex-col ">
                        <b>Location:</b>
                        <div className="ml-[20px] gap-[10px] flex flex-row align-center">
                          {location.location}
                        </div>
                      </div>
                      <div className="h-[10px]" />
                      <b>Times:</b>
                      {location.times.map((time, idx) => {
                        return (
                          <div key={idx} className={`flex flex-col align-center`}>
                            <div
                              className={`pl-[20px] rounded-lg ${time.members.length >= 2
                                ? "bg-red-300"
                                : time.isAnalyst
                                  ? "bg-green-300"
                                  : "bg-purple-300"
                                }`}
                            >
                              <b>{time.time}</b>
                            </div>
                            <div className="flex flex-col justify-center">
                              <b className="pl-[35px]">Members:</b>
                              {time.members.map((member, memberIdx) => (
                                <div key={memberIdx} className="pl-[50px] flex flex-row items-center">
                                  {member}
                                  {member === user.email.split("@")[0] && (
                                    <div className="ml-[10px] flex flex-col justify-center">
                                      <div className="bg-ad-blue border-2 border-ad-blue size-5 text-white flex rounded-full items-center justify-center hover:cursor-pointer hover:bg-white hover:text-ad-blue duration-100" onClick={() => removeMember(i, idx)}><IoClose className="size-4" /></div>
                                    </div>
                                  )}
                                </div>
                              ))}
                              {time.members.length < 2 && !time.members.includes(user.email.split("@")[0]) && (
                                <div
                                  className="ml-[50px] bg-ad-blue px-2 border-2 border-ad-blue text-white bold hover:cursor-pointer hover:text-ad-blue hover:bg-white duration-100 rounded-lg p-[3px] w-fit text-sm"
                                  onClick={() => addMember(i, idx)}
                                >
                                  Sign Up for Slot
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col justify-center">
                              <b className="pl-[35px]">Applicants:</b>
                              {time.applicants.map((applicant, applicantIdx) => (
                                <div key={applicantIdx} className="pl-[50px]">{applicant}</div>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        <br />
        <br />
      </div>
    </>
  );
};
