import { useEffect, useState } from "react";
import { testing2, getExecutive } from "../libs/firebaseCalls";
import { Loading } from "./Loading.js"
import { Link } from "react-router-dom";

export const MovedOn = () => {
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exec, setExec] = useState({})
  const [analystsFirst, setAnalystsFirst] = useState(false);

  const printCSV = (data) => {
    const csvRows = [];
    data.forEach(applicant => {
      const row = [
        applicant.first,
        applicant.last,
        applicant.id,
        applicant.class,
        applicant.isAnalyst ? 'Analyst' : 'Designer',
        applicant.normScores.join(',')
      ];
      csvRows.push(row.join(','));
    });
    const csvString = csvRows.join('\n');
    console.log(csvString);
  };

  useEffect(() => {
    const getApps = async () => {
      setLoading(true);
      const apps = await testing2();
      const executive = await getExecutive();
      setExec(executive);
      const sortedApps = apps.sort((a, b) => {
        if (a.isAnalyst !== b.isAnalyst) {
          return (a.isAnalyst - b.isAnalyst);
        }
        return b.normScores[executive.round - 1] - a.normScores[executive.round - 1];
      });
      printCSV(apps);
      setApplicants(sortedApps);
      setLoading(false);
    };
    getApps();
  }, []);

  useEffect(() => {

  }, [analystsFirst, applicants, exec.round]);

  if (loading) {
    return <Loading></Loading>
  }

  return (
    <>
      <h2 className="bold">Passed Applicants</h2>
      <button className="w-[200px]" onClick={() => {
        const newApps = applicants.sort((a, b) => {
          if (a.isAnalyst !== b.isAnalyst) {
            return !analystsFirst ? -1 : 1 * (a.isAnalyst - b.isAnalyst);
          }
          return b.normScores[exec.round] - a.normScores[exec.round];
        });
        setAnalystsFirst(!analystsFirst);
        setApplicants(newApps);
      }}>
        {analystsFirst ? "Analysts" : "Designers"} First
      </button>
      <table className="min-w-full table-auto">
        <thead>
          <tr className="border-b">
            <th className="px-4 py-2 text-center">Profile Picture</th>
            <th className="px-4 py-2 text-center">Name</th>
            <th className="px-4 py-2 text-center">ID</th>
            <th className="px-4 py-2 text-center">Class</th>
            <th className="px-4 py-2 text-center">Role</th>
            <th className="px-4 py-2 text-center">Normalized Scores</th>
          </tr>
        </thead>
        <tbody>
          {applicants.map((applicant, idx) => (
            <tr key={idx} className="border-b">
              <td className="px-4 py-2 flex flex-row justify-center items-center">
                <a href={applicant.resumeURL} target="_blank" rel="noopener noreferrer"><img src={applicant.pfpURL} alt="pfp" className="profile-picture h-auto aspect-square w-16" /></a>
              </td>
              <td className="px-4 py-2 text-center">
                <Link
                  className="search-score text-clip overflow-hidden max-w-[15vw]"
                  to={`/feedback/${applicant.id.split('@')[0]}`}
                >
                  {applicant.first} {applicant.last}
                </Link>
              </td>
              <td className="px-4 py-2 text-center">{applicant.id.split('@')[0]}</td>
              <td className="px-4 py-2 text-center">{applicant.class}</td>
              <td className="px-4 py-2 text-center">{applicant.isAnalyst ? "Analyst" : "Designer"}</td>
              <td className="px-4 py-2 text-center h-[100%]">
                <div className="flex flex-row gap-[10px] h-[100%] justify-center items-center">
                  {applicant.normScores.map((score, i) => (
                    <span key={i} className="px-1">
                      {score}
                    </span>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MovedOn;
