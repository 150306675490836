import React from "react";
import { MemberProfile } from "./MemberProfile";
import { ApplicantProfile } from "./ApplicantProfile";
import { AuthData } from "./AuthWrapper";

export const Profile = () => {
  const { user } = AuthData();

  return (
    <main className="">
      {user.position !== "Applicant" ? <MemberProfile /> : <ApplicantProfile />}
    </main>
  );
};
