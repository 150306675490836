import React, { useState, useEffect, forwardRef } from "react";
import {
  getExecutive, normalize, applicationCloseFunctions, saveTimes, incrementRound, continueAllApplicants,
  getSchedules,
  saveSchedule,
  deleteSchedule,
  updateExecutive,
  buildTestGraders,
  // resetGrading, buildTestApplicants //TEST FUNCTIONS
} from "../libs/firebaseCalls";
// import { moveProfilePictures, updateMemberPfpURLs } from "../libs/storageCalls.js";
import { Loading } from "./Loading.js";
import { Popup } from "./Popup.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/Admin.css";
import { roundNames } from "../data/data.js";


export const Admin = () => {
  const [exec, setExec] = useState(null);
  const [essays, setEssays] = useState([]);
  const [criteriaRound, setCriteriaRound] = useState(0);
  const [criteria, setCriteria] = useState([]);
  const [applicationOpen, setApplicationOpen] = useState(new Date());
  const [applicationClose, setApplicationClose] = useState(new Date());
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionFunction, setActionFunction] = useState(async () => { });
  const [actionName, setActionName] = useState("");
  const [scheduleRound, setScheduleRound] = useState(0);
  const [schedules, setSchedules] = useState({});
  const [currSchedule, setCurrSchedule] = useState({});
  const [currAddTime, setCurrAddTime] = useState([]);
  const [currAddLoc, setCurrAddLoc] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const execData = await getExecutive();
      setEssays(execData.essayQuestions || []);
      setCriteria((execData.roundScoringCriteria && execData.roundScoringCriteria[0]) || {});
      setApplicationOpen(execData.applicationOpen.toDate());
      setApplicationClose(execData.applicationClose.toDate());
      const newSchedules = await getSchedules();
      let scheduleObject = {};
      newSchedules.forEach((d) => {
        scheduleObject[d.id] = d.data();
      })
      setCurrSchedule(scheduleObject[roundNames[0]]);
      setCurrAddTime(scheduleObject[roundNames[0]] !== undefined ? Array(scheduleObject[roundNames[0]].locations.length).fill("12:00 AM") : []);
      setSchedules(scheduleObject)
      setExec(execData);
      setLoading(false);
    }
    fetchData();
  }, []);

  const hourOptions = [];
  for (let hour = 1; hour <= 12; hour++) {
    if (hour < 10) {
      hourOptions.push('0' + hour)
    } else {
      hourOptions.push(hour.toString());
    }
  }
  const minuteOptions = [];
  for (let min = 0; min <= 45; min += 15) {
    if (min === 0) {
      minuteOptions.push('00')
    } else {
      minuteOptions.push(min.toString());
    }
  }

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const nextRound = async () => {
    try {
      await incrementRound();
    } catch (e) {
      console.error(e);
    } finally {
      setPopupOpen(false);
    }
  };

  const handleContinueAll = async () => {
    try {
      await continueAllApplicants(exec.round);
    } catch (e) {
      console.error(e);
    } finally {
      setPopupOpen(false);
    }
  }

  const handleNormalization = async () => {
    try {
      await normalize();
    } catch (e) {
      console.error(e);
    } finally {
      setPopupOpen(false);
    }
  }

  const closeApplication = async () => {
    try {
      await applicationCloseFunctions();
    } catch (e) {
      console.error(e);
    } finally {
      setPopupOpen(false);
    }
  };

  const handleSaveTimes = async () => {
    try {
      await saveTimes(applicationOpen, applicationClose);
    } catch (e) {
      console.error(e);
    } finally {
      setPopupOpen(false);
    }
  };

  const handleCreateSchedule = async () => {
    const newSchedule = {
      date: new Date(),
      maxApplicants: 0,
      locations: [],
      open: false
    };
    setCurrSchedule(newSchedule);
  }

  const cloneSchedule = (schedule) => {
    return {
      ...schedule,
      date: schedule.date,  // Ensure date is properly handled
      locations: schedule.locations.map(location => ({
        ...location,
        times: location.times.map(time => ({
          ...time,
          members: [...time.members],
          applicants: [...time.applicants]
        }))
      })),
      open: schedule.open
    };
  };

  const handleSaveSchedule = async () => {
    const newSchedule = cloneSchedule(currSchedule);
    const parseTimeString = (timeStr) => {
      const [time, modifier] = timeStr.split(' ');
      let [hours, minutes] = time.split(':');
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    };
    newSchedule.locations.forEach(location => {
      location.times.sort((a, b) => {
        const timeA = parseTimeString(a.time);
        const timeB = parseTimeString(b.time);
        return timeA - timeB;
      });
    });
    setCurrSchedule(newSchedule);
    setSchedules((prevSchedules) => {
      return {
        ...prevSchedules,
        [roundNames[scheduleRound]]: newSchedule
      }
    })
    await saveSchedule(roundNames[scheduleRound], newSchedule);
  }

  const handleDeleteSchedule = async () => {
    setSchedules((prevSchedules) => {
      return {
        ...prevSchedules,
        [roundNames[scheduleRound]]: undefined
      }
    })
    setCurrSchedule(undefined);
    await deleteSchedule(roundNames[scheduleRound]);
  }

  const testGraders = async () => {
    try {
      await buildTestGraders();
    } catch (e) {
      console.error(e);
    } finally {
      setPopupOpen(false);
    }
  };


  // TEST FUNCTIONS

  // const resetGradingStats = async () => {
  //   await resetGrading();
  // };

  // const testApps = async () => {
  //   try {
  //     await buildTestApplicants();
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     setPopupOpen(false);
  //   }
  // };

  const CalendarCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  if (loading) {
    return <Loading></Loading>
  }

  return (
    <>
      {loading || !exec ? (
        <Loading />
      ) : (
        <div className="adminPage font-kanit">
          {/* <div className="flex flex-row gap-[30px] w-[100%] h-[55%] justify-center">
            <div className="flex flex-col align-center justify-center aspect-square h-[100%] rounded-[50%] bg-[#1AADE3] text-white text-8xl p-[30px]">
              <b>{new Date().getMonth() >= 6 ? `Fall ${new Date().getFullYear()}` : `Winter ${new Date().getFullYear()}`}</b>
            </div>
          </div>
          <div className="h-[2%]"></div> */}
          <div className="flex flex-row gap-[5%] w-[100%] h-[35%] justify-center">
            <div className="flex flex-col gap-[15px] p-[20px] border-solid rounded-3xl bg-[#F1F6FF] justify-center w-[42.5%] min-w-[450px]">
              <div className="instructions-wrapper">
                <div className="flex w-[100%] text-left mb-[10px]">
                  <h2><b>ACTIONS:</b></h2>
                </div>
                <div className="flex flex-col justify-center w-[100%] h-[100%]">
                  <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => { openPopup(); setActionName("Move Onto Next Round"); setActionFunction(() => nextRound); }}>Move Onto Next Round</button>
                    <div className="instruction">Updates round. Only press after delibs for the round have been completed. Once pressed, ready to start next round.</div>
                  </div>
                  <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => { openPopup(); setActionName("Normalize Scores"); setActionFunction(() => handleNormalization); }}>Normalize Scores</button>
                    <div className="instruction">Normalizes scores by grader and question. Only press after all scoring for the round has been completed, but before delibs for the round.</div>
                  </div>
                  <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => { openPopup(); setActionName("Post Application Close"); setActionFunction(() => closeApplication); }}>Post Application Close</button>
                    <div className="instruction">Counts completed applications and preps for application grading. Only press once the application has closed, but before grading starts.</div>
                  </div>
                  <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => { openPopup(); setActionName("Continue All Applicants"); setActionFunction(() => handleContinueAll); }}>Continue All Applicants</button>
                    <div className="instruction">Advances all applicants from the current round into the next round. Press after speed dating is completed.</div>
                  </div>
                  <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => { openPopup(); setActionName("Build Graders"); setActionFunction(() => testGraders); }}>
                      Build Graders
                    </button>
                    <div className="instruction">Builds list of graders that are able to grade application questions.</div>
                  </div>
                  {/* <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => {console.log("AHH"); moveProfilePictures()}}>Move Pfps</button>
                  </div> */}
                  {/* <div className="button-instruction-wrapper">
                    <button className="admin-button button1" onClick={() => {console.log("AHH"); updateMemberPfpURLs()}}>Update Pfp Urls</button>
                  </div> */}
                  {/* {"testing stuff start"}
                  <button onClick={() => { openPopup(); setActionFunction(() => resetGradingStats); }}>
                    Reset Grading Statistics
                  </button>
                  <button onClick={() => { openPopup(); setActionFunction(() => testApps); }}>
                    Build Test Applicants
                  </button>
                  {"testing stuff end"} */}
                </div>
              </div>
            </div>

            <div className="flex flex-col p-[20px] border-solid rounded-3xl bg-[#F1F6FF] justify-between w-[42.5%] min-w-[450px]">
              <div className="flex w-[100%] text-left mb-[10px]">
                <h2><b>TIMES:</b></h2>
              </div>
              <div className="flex flex-col flex-grow w-[100%] justify-center gap-[15px]">
                <div className="flex flex-row justify-center gap-[30px]">
                  <div className="flex flex-col justify-center text-left"><b>Application Open: </b></div>
                  <DatePicker
                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                    className="text-right"
                    customInput={<CalendarCustomInput />}
                    selected={applicationOpen}
                    onChange={(date) => {
                      const updatedDate = new Date(date);
                      updatedDate.setHours(23, 59, 59);
                      setApplicationOpen(updatedDate);
                    }}
                  />
                </div>
                <div className="flex flex-row justify-center gap-[30px]">
                  <div className="flex flex-col justify-center text-left"><b>Application Close: </b></div>
                  <DatePicker
                    dateFormat="MM/dd/yyyy h:mm:ss aa"
                    className="text-right"
                    customInput={<CalendarCustomInput />}
                    selected={applicationClose}
                    onChange={(date) => {
                      const updatedDate = new Date(date);
                      updatedDate.setHours(23, 59, 59);
                      setApplicationClose(updatedDate);
                    }}
                  />
                </div>
                <div className="w-[100%] flex flex-row justify-center">
                  <button className="w-fit" onClick={() => { openPopup(); setActionName("Save Open/Close Times"); setActionFunction(() => handleSaveTimes); }}>
                    Save Open/Close Times
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-[30px] w-[100%] h-[15px] justify-center"></div>
          <div className="flex flex-row gap-[5%] w-[100%] h-fit justify-center">
            <div className="flex flex-col w-[42.5%] h-fit p-[20px] border-solid rounded-3xl bg-[#F1F6FF]">
              <div className="flex flex-row gap-[20px] w-[100%] text-left mb-[10px]">
                <h2><b>ESSAYS:</b></h2>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex flex-row gap-[5%]">
                  <button onClick={() => {
                    if (!exec.essayQuestions) {
                      setEssays([]);
                    } else {
                      setEssays([...exec.essayQuestions]);
                    }
                  }}>CANCEL</button>
                  <button
                    onClick={async () => {
                      setLoading(true);
                      // let intEssays = essays;
                      // for(let i = 0; i < intEssays.length(); i++){
                      //   intEssays[i].limit = parseInt(intEssays[i].limit);
                      // }
                      // console.log(intEssays);
                      await updateExecutive({ essayQuestions: essays });
                      setExec((prevExec) => {
                        return {
                          ...prevExec,
                          essayQuestions: essays
                        }
                      })
                      setLoading(false);
                    }}
                  >
                    SAVE
                  </button>
                </div>
                {essays.length ? <div className="flex flex-row gap-[2%] h-[20px]">
                  <div className="w-[69%] h-[20px] bold text-center">
                    Prompt
                  </div>
                  <div className="w-[20%] h-[20px] bold text-center">
                    Word Limit
                  </div>
                </div> : <></>}
                {essays.map((question, idx) => {
                  return (
                    <div key={idx} className="flex flex-row gap-[2%] h-[70px]">
                      <textarea
                        className="w-[69%] h-[70px] border-solid border-[2px]"
                        value={question.prompt}
                        onChange={(e) => {
                          setEssays((prevEssays) => {
                            let newEssays = [...prevEssays];
                            newEssays[idx].prompt = e.target.value;
                            return newEssays;
                          })
                        }
                        }
                      />
                      <textarea
                        type="text"
                        className="w-[20%] h-[70px] border-solid border-[2px]"
                        value={question.limit}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (/^\d*$/.test(value)) { // only allow digits
                            setEssays((prevEssays) => {
                              let newEssays = [...prevEssays];
                              newEssays[idx].limit = (value);
                              return newEssays;
                            });
                          }
                        }}
                      />
                      <div className="h-[100%] flex flex-col justify-center">
                        <button className="w-[9%] h-[30px]" onClick={() => { setEssays((prevEssays) => prevEssays.filter((_, essayIdx) => { return essayIdx !== idx })) }}>
                          &times;
                        </button>
                      </div>
                    </div>
                  )
                })}
                <button onClick={() => setEssays([...essays, { prompt: "", limit: -1 }])}>
                  Add Essay
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-[10px] w-[42.5%] h-fit p-[20px] border-solid rounded-3xl bg-[#F1F6FF]">
              <div className="flex w-[100%] text-left gap-[20px]">
                <h2><b>CRITERIA:</b></h2>
                <select
                  value={criteriaRound}
                  onChange={(e) => {
                    const newRound = parseInt(e.target.value, 10);
                    setCriteriaRound(newRound);
                    setCriteria(exec.roundScoringCriteria[newRound]);
                  }}
                  className="border-[1px] border-solid border-black rounded-md border-[#e5e7eb] h-[50px] w-fit"
                >
                  {Object.values(roundNames).map((name, idx) => {
                    return (<option key={idx} value={idx}>{name}</option>)
                  })}
                </select>
              </div>
              {!criteria || !criteria.length ?
                (<div className="flex flex-col gap-[10px]">
                  <b className="text-3xl">No Criteria Created for this Round</b>
                  <button
                    onClick={() => {
                      setCriteria([{ criteria: "", max: 10 }]);
                      if (Object.keys(exec.roundScoringCriteria).length <= criteriaRound) {
                        setExec((prevExec) => {
                          const newRoundScoringCriteria = { ...prevExec.roundScoringCriteria };
                          while (Object.keys(newRoundScoringCriteria).length < criteriaRound) {
                            newRoundScoringCriteria[Object.keys(newRoundScoringCriteria).length] = [];
                          }
                          newRoundScoringCriteria[criteriaRound] = [{ criteria: "", max: 10 }];
                          return { ...prevExec, roundScoringCriteria: newRoundScoringCriteria };
                        });
                      } else {
                        setExec((prevExec) => {
                          const newRoundScoringCriteria = { ...prevExec.roundScoringCriteria };
                          newRoundScoringCriteria[criteriaRound] = [{ criteria: "", max: 10 }];
                          return { ...prevExec, roundScoringCriteria: newRoundScoringCriteria };
                        });
                      }
                    }}
                  >
                    Create Round Criteria
                  </button>
                </div>)
                :
                (
                  <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-row gap-[5%]">
                      <button onClick={async () => {
                        setLoading(true);
                        setCriteria(undefined);
                        let newExec = { ...exec };
                        let newRoundScoringCriteria = { ...newExec.roundScoringCriteria };
                        delete newRoundScoringCriteria[criteriaRound];
                        newExec.roundScoringCriteria = newRoundScoringCriteria;
                        setExec(newExec);
                        await updateExecutive({ ...newExec });
                        setLoading(false);
                      }}>
                        DELETE
                      </button>
                      <button onClick={() => { setCriteria([...exec.roundScoringCriteria[criteriaRound]]) }}>
                        CANCEL
                      </button>
                      <button
                        onClick={async () => {
                          setLoading(true);
                          let newRoundScoringCriteria = { ...exec.roundScoringCriteria };
                          newRoundScoringCriteria[criteriaRound] = criteria;
                          await updateExecutive({ roundScoringCriteria: newRoundScoringCriteria });
                          setExec((prevExec) => ({
                            ...prevExec,
                            roundScoringCriteria: newRoundScoringCriteria
                          }))
                          setLoading(false);
                        }}
                      >
                        SAVE
                      </button>
                    </div>
                    {essays.length ? <div className="flex flex-row gap-[2%] h-[20px]">
                      <div className="w-[72%] h-[20px] bold text-center">
                        Criteria
                      </div>
                      <div className="w-[15%] h-[20px] bold text-center">
                        Max
                      </div>
                    </div> : <></>}
                    {criteria.map((crit, critIdx) => {
                      return (
                        <div key={critIdx} className="flex flex-row gap-[2%] h-[70px]">
                          <textarea
                            value={crit.criteria}
                            onChange={(e) => {
                              setCriteria((prevCriteria) => {
                                let newCriteria = [...prevCriteria];
                                newCriteria[critIdx] = { criteria: e.target.value, max: parseInt(crit.max) };
                                return newCriteria;
                              })
                            }}
                            className="w-[72%] h-[70px] border-solid border-[2px]"
                          />
                          <select
                            className="w-[15%] border-solid border-[2px] rounded-lg border-[#ccc]"
                            value={crit.max}
                            onChange={(e) => {
                              setCriteria((prevCriteria) => {
                                let newCriteria = [...prevCriteria];
                                newCriteria[critIdx] = { criteria: crit.criteria, max: parseInt(e.target.value) };
                                return newCriteria;
                              })
                            }}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((num, numIdx) => { return <option key={numIdx} value={num}>{num}</option> })}
                          </select>
                          <div className="h-[100%] flex flex-col justify-center">
                            <button className="w-[9%] h-[30px]" onClick={() => { setCriteria((prevCriteria) => { return prevCriteria.filter((_, fidx) => { return fidx !== critIdx }) }) }}>&times;</button>
                          </div>
                        </div>
                      )
                    })}
                    <button onClick={() => { setCriteria((prevCriteria) => { console.log(exec); return [...prevCriteria, { criteria: "", max: 10 }] }) }}>
                      Add Criteria
                    </button>
                  </div>
                )
              }
            </div >
          </div >
          <div className="flex flex-row gap-[30px] w-[100%] h-[15px] justify-center"></div>
          <div className="flex flex-row gap-[30px] w-[100%] justify-center">
            <div className="flex flex-col p-[20px] border-solid rounded-3xl bg-[#F1F6FF] justify-between w-[90%] min-w-[450px]">
              <div className="flex flex-col w-[100%] h-[100%] gap-[20px] text-left mb-[10px]">
                <div className={`flex flex-row w-[100%] ${currSchedule !== undefined ? "justify-between" : "gap-[20px]"}`}>
                  <h2><b>SCHEDULE: </b></h2>
                  <select
                    value={scheduleRound}
                    onChange={(e) => {
                      const newRound = parseInt(e.target.value, 10);
                      setScheduleRound(newRound);
                      setCurrSchedule(schedules[roundNames[newRound]]);
                      setCurrAddTime(schedules[roundNames[newRound]] !== undefined ? Array(schedules[roundNames[newRound]].locations.length).fill("12:00 AM") : []);
                    }}
                    className="border-[1px] border-solid border-black rounded-md border-[#e5e7eb]"
                  >
                    {Object.values(roundNames).map((name, idx) => {
                      return (<option key={idx} value={idx}>{name}</option>)
                    })}
                  </select>
                  {currSchedule !== undefined &&
                    <>
                      <div className="flex flex-row gap-[10px]">
                        <b className="flex flex-col justify-center">Applicants Per Room:</b>
                        <input
                          type="text"
                          className="w-[80px] border-solid border-black border-[1px] rounded-[6px] px-[8px]"
                          value={currSchedule.maxApplicants}
                          onChange={(e) => {
                            setCurrSchedule(prevCurr => ({
                              ...prevCurr,
                              maxApplicants: e.target.value
                            }))
                          }}
                        />
                      </div>
                      <div className="flex flex-row gap-[10px]">
                        <div className="flex flex-col justify-center"><b>Date:</b></div>
                        <DatePicker
                          dateFormat="EE MM/dd"
                          className="text-right"
                          customInput={<CalendarCustomInput />}
                          selected={currSchedule.date instanceof Date && !isNaN(currSchedule.date.valueOf()) ? currSchedule.date : (currSchedule.date && currSchedule.date.toDate())}
                          onChange={(date) => {
                            const updatedDate = new Date(date);
                            updatedDate.setHours(0, 0, 0);
                            setCurrSchedule(prevCurr => ({
                              ...prevCurr,
                              date: updatedDate
                            }))
                          }}
                        />
                      </div>
                      <button className="w-[80px]" onClick={() => { openPopup(); setActionName(`Delete ${roundNames[exec.round]} Schedule`); setActionFunction(() => handleDeleteSchedule); }}>DELETE</button>
                      <button className="w-[80px]" onClick={() => { openPopup(); setActionName(`Save ${roundNames[exec.round]} Schedule`); setActionFunction(() => handleSaveSchedule); }}>SAVE</button>
                    </>
                  }
                </div>
                {currSchedule !== undefined ?
                  (
                    <>
                      <div className="scheduleGridContainer" >
                        {currSchedule.locations && currSchedule.locations.map((location, i) => {
                          return (
                            <div key={i} className="scheduleGridItem relative">
                              <div className="absolute top-[20px] right-[20px] flex flex-row">
                                <button
                                  className="w-[8px] h-[8px] p-[7px]"
                                  onClick={() => {
                                    setCurrSchedule((prevCurrSchedule) => {
                                      const newLocations = [...prevCurrSchedule.locations].filter((_, filterI) => filterI !== i);
                                      return {
                                        ...prevCurrSchedule,
                                        locations: newLocations,
                                      };
                                    });
                                    setCurrAddTime((prevCurrAddTime) => ([...prevCurrAddTime].filter((_, filterI) => filterI !== i)));
                                  }}
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="flex flex-col">
                                <b>Location:</b>
                                <div className="ml-[20px] gap-[10px] flex flex-row align-center">
                                  {location.location}
                                </div>
                              </div>
                              <div className="h-[10px]" />
                              <b>Times:</b>
                              {location.times.map((time, idx) => {
                                return (
                                  <div key={idx} className="ml-[20px] gap-[10px] flex flex-row, align-center">
                                    {(time.time)}
                                    <select
                                      onChange={() => {
                                        setCurrSchedule((prevCurrSchedule) => {
                                          const newLocations = [...prevCurrSchedule.locations];
                                          let locationTimes = newLocations[i].times;
                                          locationTimes[idx] = {
                                            ...locationTimes[idx],
                                            isAnalyst: !time.isAnalyst
                                          }
                                          newLocations[i] = {
                                            ...newLocations[i],
                                            times: locationTimes,
                                          };
                                          return {
                                            ...prevCurrSchedule,
                                            locations: newLocations,
                                          };
                                        });
                                      }}
                                      value={time.isAnalyst ? "Analyst" : "Designer"}
                                      className="border-solid border-black border-[1px]"
                                    >
                                      <option key={0} value={"Analyst"}>Analyst</option>
                                      <option key={1} value={"Designer"}>Designer</option>
                                    </select>
                                    <div className="flex flex-col justify-center">
                                      <button
                                        className="w-[8px] h-[8px] p-[7px]"
                                        onClick={() => {
                                          setCurrSchedule((prevCurrSchedule) => {
                                            const newLocations = [...prevCurrSchedule.locations];
                                            const locationTimes = newLocations[i].times;
                                            newLocations[i] = {
                                              ...newLocations[i],
                                              times: locationTimes.filter((_, filterI) => filterI !== idx),
                                            };
                                            return {
                                              ...prevCurrSchedule,
                                              locations: newLocations,
                                            };
                                          });
                                        }}
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  </div>
                                )
                              })}
                              <div className="h-[10px]" />
                              <div className="flex flex-col gap-[10px] w-[100%]">
                                <div className="flex flex-row gap-[5px]">
                                  <select
                                    className="border-solid border-black border-[1px]"
                                    value={currAddTime[i].split(':')[0]}
                                    onChange={(e) => {
                                      const newTime = `${e.target.value}:${currAddTime[i].split(':')[1]}`;
                                      let newTimes = [...currAddTime];
                                      newTimes[i] = newTime;
                                      setCurrAddTime(newTimes);
                                    }}
                                  >
                                    {hourOptions.map((hour) => (
                                      <option key={hour} value={hour}>
                                        {hour}
                                      </option>
                                    ))}
                                  </select>
                                  <b className="flex flex-col justify-center text-l">:</b>
                                  <select
                                    className="border-solid border-black border-[1px]"
                                    value={currAddTime[i].split(':')[1].split(' ')[0]}
                                    onChange={(e) => {
                                      const newTime = `${currAddTime[i].split(':')[0]}:${e.target.value} ${currAddTime[i].split(' ')[1]}`;
                                      let newTimes = [...currAddTime];
                                      newTimes[i] = newTime;
                                      setCurrAddTime(newTimes);
                                    }}
                                  >
                                    {minuteOptions.map((min, minIdx) => (
                                      <option key={minIdx} value={min}>
                                        {min}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="border-solid border-black border-[1px]"
                                    value={currAddTime[i].split(' ')[1]}
                                    onChange={(e) => {
                                      const newTime = `${currAddTime[i].split(' ')[0]} ${e.target.value}`;
                                      let newTimes = [...currAddTime];
                                      newTimes[i] = newTime;
                                      setCurrAddTime(newTimes);
                                    }}
                                  >
                                    <option key={0} value="AM">
                                      AM
                                    </option>
                                    <option key={1} value="PM">
                                      PM
                                    </option>
                                  </select>
                                  <button
                                    className="w-[100%] p-[3px]"
                                    onClick={() => {
                                      setCurrSchedule((prevCurrSchedule) => {
                                        const newLocations = [...prevCurrSchedule.locations];
                                        const locationTimes = newLocations[i].times;
                                        const newTime = {
                                          applicants: [],
                                          members: [],
                                          time: currAddTime[i],
                                          isAnalyst: true
                                        };
                                        const timeExists = locationTimes.some(time => time.time === newTime.time);
                                        if (!timeExists) {
                                          newLocations[i] = {
                                            ...newLocations[i],
                                            times: [...locationTimes, newTime],
                                          };
                                          return {
                                            ...prevCurrSchedule,
                                            locations: newLocations,
                                          };
                                        }
                                        return prevCurrSchedule;
                                      });
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-row gap-[10px]">
                        <input
                          type="text"
                          value={currAddLoc}
                          onChange={(e) => setCurrAddLoc(e.target.value)}
                          placeholder="Ex: Union Room 1206"
                          className="w-[50%] border-solid border-black border-[1px] rounded-[6px] px-[8px]"
                        />
                        <button
                          className="w-[30%]"
                          onClick={() => {
                            setCurrSchedule((prevCurrSchedule) => {
                              const locExists = prevCurrSchedule.locations.some(location => location.location === currAddLoc);
                              if (!locExists && currAddLoc) {
                                const newLocations = [...prevCurrSchedule.locations, { location: currAddLoc, times: [] }];
                                setCurrAddTime(prevCurrAddTime => [...prevCurrAddTime, "12:00 AM"]);
                                setCurrAddLoc("");
                                return {
                                  ...prevCurrSchedule,
                                  locations: newLocations,
                                };
                              }
                              return prevCurrSchedule;
                            });
                          }}
                        ><b>Add Location</b></button>
                        <div className="flex flex-row justify-center items-center w-[15%] bold">
                          Schedule Open?
                        </div>
                        <div className="flex flex-row justify-center items-center w-[5%]">
                          <input
                            type="checkBox"
                            className="h-[30px] w-[30px]"
                            checked={currSchedule.open}
                            onChange={() => {
                              setCurrSchedule((prevCurrSchedule) => {
                                return {
                                  ...prevCurrSchedule,
                                  open: !prevCurrSchedule.open
                                }
                              })
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )
                  :
                  (
                    <div className="flex flex-grow flex-col gap-[20px] justify-center align-center text-center h-[100%]">
                      <b className="text-3xl">No Schedule For This Round</b>
                      <div className="w-[100%] flex flex-row justify-center">
                        <button className="w-[30%]" onClick={handleCreateSchedule}>Create Schedule</button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div >
      )}

      {
        popupOpen && (
          <Popup customContainerClass="min-w-[300px] popupContent">
            <Confirmation actionFunction={actionFunction} handleClose={closePopup} actionName={actionName} />
          </Popup>
        )
      }
    </>
  );
};

const Confirmation = ({ actionFunction, handleClose, actionName }) => {
  const [loading, setLoading] = useState(false);

  const runAction = async () => {
    setLoading(true);
    try {
      await actionFunction();  // Correctly await the action function
    } catch (e) {
      console.error("Failed action, try again or cancel", e);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  if (loading) {
    return <Loading customContainerClass="w-[100%] flex flex-row justify-center align-center" />;
  }

  return (
    <div className="flex flex-col gap-[15px] p-[20px]">
      <b>Confirm Action ({actionName}) or Cancel</b>
      <button onClick={runAction}>Confirm</button>
      <button onClick={handleClose}>Cancel</button>
    </div>
  );
};
