import React, { useState, useEffect } from "react";
import { getUserProfile, memberUpdate } from "../libs/firebaseCalls";
import {
  pfpUpload,
  resumeUpload,
} from "../libs/storageCalls.js";
import { MemberViewProfile } from "./MemberViewProfile.js";
import { MemberEditProfile } from "./MemberEditProfile.js";
import { AuthData } from "./AuthWrapper";
import { Popup } from "./Popup.js";
import { SubmissionError } from "./SubmissionError.js";
import { Loading } from "./Loading.js"
import "../css/ApplicantProfile.css";

export const MemberProfile = () => {
  const { user } = AuthData();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [coursework, setCoursework] = useState([]);
  const [involvement, setInvolvement] = useState([]);
  const [links, setLinks] = useState([]);
  const [number, setNumber] = useState(0);
  const [pfp, setPFP] = useState("");
  const [resume, setResume] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [isPfpPopup, setIsPfpPopup] = useState(true);
  const [originalProfile, setOriginalProfile] = useState({});

  const [isFileSaving, setIsFileSaving] = useState(false);
  const [pfpFile, setPfpFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    //get data
    const fetchUser = async () => {
      setLoading(true);
      const newProfile = await getUserProfile(user.email, true);
      setOriginalProfile(newProfile);
      setFirst(newProfile.first);
      setLast(newProfile.last);
      setMajor(newProfile.major);
      setMinor(newProfile.minor);
      setYear(newProfile.class);
      setCoursework(newProfile.coursework);
      setInvolvement(newProfile.involvement);
      setLinks(newProfile.links);
      setNumber(newProfile.number);
      setPFP(newProfile.pfpURL);
      setResume(newProfile.resumeURL);
      setLoading(false);
    };
    //execute commands
    fetchUser();
  }, [user.email]);

  const removeIsNewField = (state) => {
    let newState = [];
    state.forEach((obj) => {
      newState.push(obj["value"]);
    });
    return newState;
  };

  const saveResponses = async (e) => {
    e.preventDefault();
    setSaving(true);
    memberUpdate(
      user.email,
      {
        first: first,
        last: last,
        number: number,
        class: year,
        major: major,
        minor: minor,
        links: links
      }
    );
    setSaving(false);
    setEdit(false);
  };

  const handleFirst = (n) => {
    setFirst(n);
  };

  const handleLast = (n) => {
    setLast(n);
  };

  const handleYear = (n) => {
    setYear(n);
  };

  const handleMajor = (n) => {
    setMajor(n);
  };

  const handleMinor = (n) => {
    setMinor(n);
  };

  const handleInvolvement = (n) => {
    setInvolvement(n);
  };

  const handleCoursework = (n) => {
    setCoursework(n);
  };

  const handleLinks = (n) => {
    setLinks(n);
  };

  const handleNumber = (n) => {
    setNumber(n);
  };

  const handlePfpFileChange = (n) => {
    setPfpFile(n);
  };

  const handleResumeFileChange = (n) => {
    setResumeFile(n);
  };

  const handlePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const handlePfpPopup = () => {
    setIsPfpPopup(true);
    handlePopup();
  };

  const handleResumePopup = () => {
    setIsPfpPopup(false);
    handlePopup();
  };

  const handlePfpSave = async () => {
    setIsFileSaving(true);
    const pfp = await pfpUpload(
      user.email.split("@")[0],
      pfpFile,
      setErrorMessage
    );
    if (pfp) {
      setPFP(pfp);
      setOriginalProfile((prevProfile) => {
        return { ...prevProfile, pfpURL: pfp }
      })
      await memberUpdate(user.email, { pfpURL: pfp });
    }
    setIsFileSaving(false);
    setPopupOpen(false);
  };

  const handleResumeSave = async () => {
    setIsFileSaving(true);
    const resume = await resumeUpload(
      user.email.split("@")[0],
      resumeFile,
      setErrorMessage,
      first,
      last
    );
    if (resume) {
      setResume(resume);
      setOriginalProfile((prevProfile) => {
        return { ...prevProfile, resumeURL: resume }
      })
      await memberUpdate(user.email, { resumeURL: resume });
    }
    setIsFileSaving(false);
    setPopupOpen(false);
  };

  const handleCancel = async () => {
    setFirst(originalProfile.first);
    setLast(originalProfile.last);
    setMajor(originalProfile.major);
    setMinor(originalProfile.minor);
    setYear(originalProfile.class);
    setCoursework(originalProfile.coursework);
    setInvolvement(originalProfile.involvement);
    setLinks(originalProfile.links);
    setNumber(originalProfile.number);
  }

  return (
    <>
      {loading ?
        (
          <Loading />
        )
        :
        (
          <>
            <div className="font-kanit space-y-12">
              <div className="flex justify-between items-center">
                <div className="text-5xl font-bold">Profile</div>
                {/* <div className={`size-8 hover:text-ad-blue text-ad-dark duration-200 hover:cursor-pointer ${edit ? 'rotate-45' : ''}`} onClick={() => setEdit(!edit)}>
                  <IoSettingsOutline
                    style={{ userDrag: "none", WebkitUserDrag: "none", width: "100%", height: "100%" }}
                  />
                </div> */}
                <div className="flex space-x-2">
                  {!saving && edit && (
                    <div onClick={(e) => saveResponses(e)} className="uppercase bg-ad-blue shadow-light rounded-full py-2 px-4 bold text-white hover:text-ad-dark duration-200 hover:bg-white hover:cursor-pointer">Save</div>
                  )}
                  {saving && edit && <div className="saving rounded-lg">Saving...</div>}
                  <div
                    onClick={() => {
                      if (edit) {
                        handleCancel();
                      }
                      setEdit(!edit)
                    }}
                    className="bg-ad-blue shadow-light rounded-full py-2 px-4 bold text-white hover:text-ad-dark duration-200 hover:bg-white hover:cursor-pointer"
                  >
                    {edit ? "CANCEL" : "EDIT"}
                  </div>
                </div>
                {errorMessage !== "" && <SubmissionError message={errorMessage} setErrorMessage={setErrorMessage} />}
              </div>
              {/* <hr className="line" /> */}
              <div className="viewProfileInfo">
                {!edit ? (
                  <>
                    <MemberViewProfile
                      pfp={pfp}
                      resume={resume}
                      first={first}
                      last={last}
                      year={year}
                      major={major}
                      minor={minor}
                      involvement={involvement}
                      coursework={coursework}
                      number={number}
                      links={links}
                      handlePfpPopup={handlePfpPopup}
                      handleResumePopup={handleResumePopup}
                    />
                  </>
                ) : (
                  <MemberEditProfile
                    saveResponses={saveResponses}
                    first={first}
                    last={last}
                    year={year}
                    major={major}
                    minor={minor}
                    removeIsNewField={removeIsNewField}
                    links={links}
                    number={number}
                    handleFirst={handleFirst}
                    handleLast={handleLast}
                    handleYear={handleYear}
                    handleMajor={handleMajor}
                    handleMinor={handleMinor}
                    handleInvolvement={handleInvolvement}
                    handleCoursework={handleCoursework}
                    handleLinks={handleLinks}
                    handleNumber={handleNumber}
                    handlePfpPopup={handlePfpPopup}
                    handleResumePopup={handleResumePopup}
                  />
                )}
              </div>
            </div>


            <div className={`${popupOpen && isPfpPopup ? 'opacity-100' : 'opacity-0 pointer-events-none'} duration-200`}>
              <Popup title="Select Profile Picture:" closePopup={handlePopup} loading={isFileSaving}>
                <>
                  <input type="file" accept="image/*" onChange={(e) => handlePfpFileChange(e.target.files[0])} />
                  <div type="button" className="border-2 border-ad-blue bg-white rounded-full text-ad-blue text-center bold uppercase py-1 hover:bg-ad-blue hover:text-white hover:cursor-pointer duration-100" onClick={handlePfpSave}>
                    Save
                  </div>
                </>
              </Popup>
            </div>

            <div className={`${popupOpen && !isPfpPopup ? 'opacity-100' : 'opacity-0 pointer-events-none'} duration-200`}>
              <Popup title="Select Resume File:" closePopup={handlePopup} loading={isFileSaving}>
                <>
                  <input type="file" accept="application/pdf,.doc,.docx" onChange={(e) => handleResumeFileChange(e.target.files[0])} />
                  <br />
                  <button type="button" className="border-2 border-ad-blue bg-white rounded-full text-ad-blue text-center bold uppercase py-1 hover:bg-ad-blue hover:text-white hover:cursor-pointer duration-100" onClick={handleResumeSave}>
                    Save
                  </button>
                </>
              </Popup >
            </div>

          </>
        )
      }

    </>
  );
};
