import React, { createContext, useContext, useState, useEffect } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import {
  setPersistence,
  signInWithPopup,
  browserLocalPersistence,
  signOut,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import { db, auth } from "../configs/firebase";
import { members } from "../data/data.js";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = ({ children }) => {
  const [user, setUser] = useState({
    uniqname: "",
    email: "",
    completeSignUp: false,
    position: "",
    round: 0,
  });

  const login = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    try {
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, provider);
      const uniqname = result.user.email.split("@")[0];
      const domain = result.user.email.split("@")[1];
      if (domain !== "umich.edu") {
        await signOut(auth);
        console.log("Invalid umich email");
      } else {
        const collectionName = members.has(uniqname) ? "members" : "applicants";
        const collectionRef = collection(db, collectionName);
        const docRef = doc(collectionRef, result.user.email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUser({
            uniqname: uniqname,
            email: result.user.email,
            completeSignUp: true,
            position: docSnap.data().position,
            round: docSnap.data().round,
          });
        } else {
          setUser({
            uniqname: uniqname,
            email: result.user.email,
            completeSignUp: false,
            position: "",
            round: 0,
          });
        }
        console.log("User signed in successfully");
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const logout = async () => {
    await signOut(auth);
    setUser({
      uniqname: "",
      email: "",
      completeSignUp: false,
      position: "",
      round: 0,
    });
    console.log("User signed out properly");
  };

  const completeSignUp = async (email, position) => {
    console.log("User authed properly");
    setUser({
      uniqname: email.split("@")[0],
      email: email,
      completeSignUp: true,
      position: position,
      round: 0,
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const uniqname = user.email.split("@")[0];
          const domain = user.email.split("@")[1];
          if (domain !== "umich.edu") {
            await signOut(auth);
            console.log("Invalid umich email");
          } else {
            const collectionName = members.has(uniqname) ? "members" : "applicants";
            const collectionRef = collection(db, collectionName);
            const docRef = doc(collectionRef, user.email);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              setUser({
                uniqname: uniqname,
                email: user.email,
                completeSignUp: true,
                position: docSnap.data().position,
                round: docSnap.data().round,
              });
            } else {
              setUser({
                uniqname: uniqname,
                email: user.email,
                completeSignUp: false,
                position: "",
                round: 0,
              });
            }
            console.log("User signed in successfully");
          }
        } catch (error) {
          console.error("Error signing in with Google:", error);
        }
      } else {
        console.log("No user signed in");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, completeSignUp }}>
      {children}
    </AuthContext.Provider>
  );
};
