import React from "react";
import { Navigate } from "react-router-dom";
import { AuthData } from "./AuthWrapper";
import "../css/Login.css";
import { ad_logo, ad_logo_long } from "../data/svg";
import { theme0Image } from "../data/data";

export const Login = () => {
  const { login, user } = AuthData();

  return (
    <>
      {user.uniqname !== "" ? (
        <Navigate to="/" />
      ) : (
        <div className="relative flex flex-col md:flex-row font-kanit text-ad-dark w-screen h-screen">
          <div className="w-1/2 h-screen left-0 top-0 bg-ad-light-blue hidden md:flex justify-center items-center flex-col p-4">
            <div className="size-28">{ad_logo}</div>
            {/* <div className="w-48 pb-8">{ad_logo_stacked}</div> */}
            <div className="flex flex-col justify-center items-center">
              <img
                src={theme0Image}
                className="w-96"
                alt="Theme pic"
                draggable={false}
              />
            </div>
          </div>
          <div className=" md:w-1/2 h-screen flex flex-col justify-center items-center space-y-8 p-4 ">
            <div className="flex flex-col items-center space-y-2 px-4 w-full max-w-[500px]">
              <div className="w-40">{ad_logo_long}</div>
              <div className="uppercase font-bold text-6xl">Welcome</div>
              <div className="text-center">We're excited to have you as the first users of our new portal this semester! As it's still in beta, there may be a few bugs or glitches. If you encounter any, please let us know by emailing <a href="mailto:seojuny@umich.edu" className="text-blue-600 underline"> seojuny@umich.edu</a>.</div>
              <div className="text-center">Please sign in with your school email to login and begin the application process!</div>
            </div>
            <div onClick={login} className="flex py-4 px-6 space-x-8 rounded-full shadow-light hover:text-white hover:bg-ad-blue hover:cursor-pointer duration-200">
              <div>Continue with Google</div>
              <svg className="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" /><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" /><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" /><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" /><path d="M1 1h22v22H1z" fill="none" /></svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
