// MAKE SURE TO UPDATE THESE EVERY SEMESTER
// Place for things that aren't going to change throughout 1 recruitment cycle

export const presidentEmail = "vkaushik@umich.edu";
export const recruitingEmail = "zhaoeric@umich.edu";
export const members = new Set([
  "vkaushik",
  "tifftang",
  "hannahkk",
  "ashwinak",
  "johannt",
  "alxyang",
  "zhaoeric",
  "nkavya",
  "asyuan",
  "bennyh",
  "riyashar",
  "samau",
  "seojuny",
  "manavk",
  "fangfr",
  "sarachae",
  "angann",
  "ncleong",
  "aakali",
  "cyber",
  "bcaballo",
  "daviddwu",
  "fahmida",
  "fraskind",
  "irisding",
  "jesszh",
  "johnxie",
  "kalpp",
  "nkabra",
  "pranavjo",
  "rbafna",
  "shanncai",
  "shanye",
  "andrkang",
  "arnavdk",
  "wangemi",
  "jamespi",
  "joyren",
  "nwlvrd",
  "ohmp",
  "sarahfw",
  "sohamsal",
  "sarnav",
  "clsun",
  "mbhavsar",
  "mnauli",
  "krachit",
  "riddhib",
  "heaa",
  "andrenan",
  "anikangi",
  "anrigu",
  "apurvad",
  "cwrightj",
  "dtalluri",
  "elainezh",
  "ericsong",
  "flyon",
  "gavlich",
  "ngocho",
  "livbraha",
  "mnema",
  "nilishab",
  "narangp",
  "rladdha",
  "vkukutla",
  "chandrav",
  "potdukhe",

  //newbies
  'aaditj',
  'alexdnl',
  'angelaqu',
  'anishcha',
  'calebjl',
  'jiyih',
  'becklau',
  'mjjiang',
  'ramgade',
  'reet',
  'brhys',
  'kankats',
  'rtaepa',
  'toanbui',
  'vnayyar',
  'waynesc',

  //alumni
  "nhanover",
  "aashni",
  "sarangia",
  "asda",
  "aveekdg",
  "burteth",
  "fiahmed",
  "ithaker",
  "jakestan",
  "mikelh",
  "neelsh",
  "nrgupta",
  "nuppula",
  "rgudal",
  "sukritsg",
  "abohra",
  "arkumar",
  "axtya",
  "tallurid",
  "fhaider",
  "yerrak",
  "jenil",
  "ksawal",
  "kushptl",
  "rqkang",
  "unserh",
  "vparv",
  "advaidhv",
  "bknowles",
  "marlina",
  "sogruse",
  "gfolbe",
  "prajwalc",
  "pratixp",
  "arthurjd",
  "kramesh",
  "vinrev",
  "smjung",
  "emilycao",
  "aniketn",
  "antarag",
  "avyukd",
  "ianglee",
  "irisfuna",
  "jadensun",
  "jzenas",
  "kjihwan",
  "lydialam",
  "lydiatan",
  "milinm",
  "skikkeri",
  "vpentak",
]);


//18
export const board = new Set([
  // "seojuny", //FIXME
  "vkaushik",
  "tifftang",
  "zhaoeric",
  "johannt",
  "hannahkk",
  "alxyang",
  "ashwinak",
  "nkavya"
]);

export const interviewCommittee = new Set([
  "johnxie",
  "seojuny",
  "shanye",
  "riyashar",
  "asyuan",
  "flyon",
  "sarnav",
  "fahmida",
  "wangemi",
  "manavk"
]);

export const viewsPerApplicant = 4;
export const numRounds = 4;
export const defaultPfp = "/pfpWhite.png";
export const loadingImage = "/AD_Logo.png"
export const theme0Image = "/2024_Fall_Theme_0.png"
export const theme1Image = "/2024_Fall_Theme_1.png"
export const theme2Image = "/2024_Fall_Theme_2.png"

export const recruitingEvents = [
  {
    name: "Festifall",
    date: new Date(2024, 7, 28, 16, 24),
    time: "2:30-6 PM",
    location: "Diag & Ingalls Mall",
  },
  {
    name: "Mass Meeting I",
    date: new Date(2024, 8, 3, 16, 26),
    time: "7-9 PM",
    location: "League | Hussey",
  },
  {
    name: "Mass Meeting II",
    date: new Date(2024, 8, 4, 16, 28),
    time: "7-9 PM",
    location: "North Quad 2435",
  },
  {
    name: "DEI Panel",
    date: new Date(2024, 8, 5, 16, 30),
    time: "7-9 PM",
    location: "League | Henderson",
  },
  {
    name: "Career Workshop",
    date: new Date(2024, 8, 6, 16, 32),
    time: "7-9 PM",
    location: "North Quad 2435",
  },
  {
    name: "Application Due",
    date: new Date(2024, 8, 7, 16, 34),
    time: "11:59 PM",
    // location: "",
  },
  {
    name: "Selection Process",
    date: new Date(2024, 8, 10, 16, 36),
    // time: "TBD",
    location: "By Invite Only",
  },
];

export const closedEvents = [
  {
    name: "Speed Dating",
    date: new Date(2024, 8, 9, 16, 24),
    time: "TBD",
    location: "TBD",
  },
  {
    name: "Group Interviews",
    date: new Date(2024, 8, 10, 16, 26),
    time: "Various Times",
    location: "Various Locations",
  },
  {
    name: "Interview Workshop",
    date: new Date(2024, 8, 11, 16, 28),
    time: "6:30-8 PM",
    location: "League | Room 4",
  },
  {
    name: "Final Interviews",
    date: new Date(2024, 8, 12, 16, 30),
    time: "Various Times",
    location: "Various Locations",
  },
  {
    name: "Final Round",
    date: new Date(2024, 8, 13, 16, 32),
    time: "TBD",
    location: "TBD",
  },
];

export const roundNames = {
  0: "Application",
  1: "Speed Dating",
  2: "Group Interviews",
  3: "Final Interviews",
  4: "Questionfest",
}

export const memberPositions = new Set([
  "Analyst",
  "Project Manager",
  "Senior Advisor",
]);

export const boardPositions = new Set([
  "President",
  "Vice President",
  "Director of Recruitment",
  "Director of Projects",
  "Director of Corporate Engagement",
  "Director of Technical Development",
  "Director of Design",
  "Director of Social",
]);

export const majors = [
  { value: "Undecided", label: "Undecided" },
  { value: "Computer Science", label: "Computer Science" },
  { value: "Computer Engineering", label: "Computer Engineering" },
  { value: "Data Science", label: "Data Science" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "Math", label: "Math" },
  { value: "Statistics", label: "Statistics" },
  { value: "Business", label: "Business" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Industrial Operations Engineering", label: "Industrial Operations Engineering" },
  { value: "Art & Design", label: "Art & Design" },
  { value: "User Experience Design", label: "User Experience Design" },
  { value: "Cognitive Science", label: "Cognitive Science" },
  { value: "Organizational Studies", label: "Organizational Studies" },
];

export const minors = [
  { value: "Computer Science", label: "Computer Science" },
  { value: "Computer Engineering", label: "Computer Engineering" },
  { value: "Data Science", label: "Data Science" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "Math", label: "Math" },
  { value: "Statistics", label: "Statistics" },
  { value: "Business", label: "Business" },
  { value: "Entrepreneurship", label: "Entrepreneurship" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Industrial Operations Engineering", label: "Industrial Operations Engineering" },
  { value: "Art & Design", label: "Art & Design" },
  { value: "User Experience Design", label: "User Experience Design" },
  { value: "Cognitive Science", label: "Cognitive Science" },
  { value: "Organizational Studies", label: "Organizational Studies" },
];


const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
export const years = [
  currentMonth < 6 && `Spring ${currentYear}`,
  `Winter ${currentYear}`,
  `Spring ${currentYear + 1}`,
  `Winter ${currentYear + 1}`,
  `Spring ${currentYear + 2}`,
  `Winter ${currentYear + 2}`,
  `Spring ${currentYear + 3}`,
  `Winter ${currentYear + 3}`,
  `Spring ${currentYear + 4}`,
  currentMonth >= 6 && `Winter ${currentYear + 4}`
].filter(Boolean);

export const startYears = [
  currentMonth < 6 && `Fall ${currentYear - 4}`,
  `Spring ${currentYear - 4}`,
  `Fall ${currentYear - 3}`,
  `Spring ${currentYear - 3}`,
  `Fall ${currentYear - 2}`,
  `Spring ${currentYear - 2}`,
  `Fall ${currentYear - 1}`,
  `Spring ${currentYear - 1}`,
  `Fall ${currentYear}`,
  currentMonth >= 6 && `Spring ${currentYear}`
].filter(Boolean);

export const involvements = [
  {
    value: "V1",
    label: "V1",
  },
  {
    value: "Kappa Theta Pi",
    label: "Kappa Theta Pi",
  },
  {
    value: "Atlas Consulting",
    label: "Atlas Consulting",
  },
  {
    value: "Michigan Investment Group",
    label: "Michigan Investment Group",
  },
  {
    value: "Alpha Theta Delta",
    label: "Alpha Theta Delta",
  },
  {
    value: "MECC Consulting",
    label: "MECC Consulting",
  },
  {
    value: "Michigan Data Science Team",
    label: "Michigan Data Science Team",
  },
  {
    value: "Michigan Hackers",
    label: "Michigan Hackers",
  },
  {
    value: "Wolverine Soft",
    label: "Wolverine Soft",
  },
  {
    value: "WolvSec",
    label: "WolvSec",
  },
  {
    value: "GEECS",
    label: "GEECS",
  },
];

export const courses = [
  {
    value: "EECS 183",
    label: "EECS 183",
  },
  {
    value: "EECS 280",
    label: "EECS 280",
  },
  {
    value: "EECS 203",
    label: "EECS 203",
  },
  {
    value: "EECS 281",
    label: "EECS 281",
  },
  {
    value: "EECS 370",
    label: "EECS 370",
  },
  {
    value: "EECS 376",
    label: "EECS 376",
  },
];

export const namesArray = [
  { value: "Vikram Kaushik", label: "Vikram Kaushik" },
  { value: "Tiffany Tang", label: "Tiffany Tang" },
  { value: "Hannah Kim", label: "Hannah Kim" },
  { value: "Ashwin Kumar", label: "Ashwin Kumar" },
  { value: "Johann Tsegay", label: "Johann Tsegay" },
  { value: "Alex Yang", label: "Alex Yang" },
  { value: "Eric Zhao", label: "Eric Zhao" },
  { value: "Kavya Narayan", label: "Kavya Narayan" },
  { value: "Angela Yuan", label: "Angela Yuan" },
  { value: "Benny Huang", label: "Benny Huang" },
  { value: "Riya Sharma", label: "Riya Sharma" },
  { value: "Samantha Au", label: "Samantha Au" },
  { value: "Seojun Yoon", label: "Seojun Yoon" },
  { value: "Manav Khanvilkar", label: "Manav Khanvilkar" },
  { value: "Frank Fang", label: "Frank Fang" },
  { value: "Sara Chae", label: "Sara Chae" },
  { value: "Adrian Ngan", label: "Adrian Ngan" },
  { value: "Angie Leong", label: "Angie Leong" },
  { value: "Anika Akali", label: "Anika Akali" },
  { value: "Aaron Rahman", label: "Aaron Rahman" },
  { value: "Brian Caballo", label: "Brian Caballo" },
  { value: "David Wu", label: "David Wu" },
  { value: "Fahmida Rahman", label: "Fahmida Rahman" },
  { value: "Fanny Raskind", label: "Fanny Raskind" },
  { value: "Iris Ding", label: "Iris Ding" },
  { value: "Jessica Zhang", label: "Jessica Zhang" },
  { value: "John Xie", label: "John Xie" },
  { value: "Kalp Patel", label: "Kalp Patel" },
  { value: "Naman Kabra", label: "Naman Kabra" },
  { value: "Pranav Joshi", label: "Pranav Joshi" },
  { value: "Rohan Bafna", label: "Rohan Bafna" },
  { value: "Shannon Cai", label: "Shannon Cai" },
  { value: "Shanshan Ye", label: "Shanshan Ye" },
  { value: "Andrew Kang", label: "Andrew Kang" },
  { value: "Arnav Kadam", label: "Arnav Kadam" },
  { value: "Emily Wang", label: "Emily Wang" },
  { value: "Jamie Spielman", label: "Jamie Spielman" },
  { value: "Joy Ren", label: "Joy Ren" },
  { value: "Nick Walvoord", label: "Nick Walvoord" },
  { value: "Ohm Patel", label: "Ohm Patel" },
  { value: "Sarah Wan", label: "Sarah Wan" },
  { value: "Soham Salunke", label: "Soham Salunke" },
  { value: "Arnav Shah", label: "Arnav Shah" },
  { value: "Claire Sun", label: "Claire Sun" },
  { value: "Marissa Bhavsar", label: "Marissa Bhavsar" },
  { value: "Mira Panjaitan", label: "Mira Panjaitan" },
  { value: "Rachit Khandelwal", label: "Rachit Khandelwal" },
  { value: "Riddhi Bhattacharya", label: "Riddhi Bhattacharya" },
  { value: "Allison He", label: "Allison He" },
  { value: "Andre Nandi", label: "Andre Nandi" },
  { value: "Anika Angi", label: "Anika Angi" },
  { value: "Anri Gu", label: "Anri Gu" },
  { value: "Apurva Desai", label: "Apurva Desai" },
  { value: "Charles Wright", label: "Charles Wright" },
  { value: "Druv Talluri", label: "Druv Talluri" },
  { value: "Elaine Zhang", label: "Elaine Zhang" },
  { value: "Eric Song", label: "Eric Song" },
  { value: "Flynn Lyon", label: "Flynn Lyon" },
  { value: "Gavin Lichtenberg", label: "Gavin Lichtenberg" },
  { value: "Kylie Ho", label: "Kylie Ho" },
  { value: "Livia Brahaj", label: "Livia Brahaj" },
  { value: "Maansi Nema", label: "Maansi Nema" },
  { value: "Nilisha Baid", label: "Nilisha Baid" },
  { value: "Parth Narang", label: "Parth Narang" },
  { value: "Richa Laddha", label: "Richa Laddha" },
  { value: "Vijaya Kukutla", label: "Vijaya Kukutla" },
  { value: "Vishal Chandra", label: "Vishal Chandra" },
  { value: "Yash Potdukhe", label: "Yash Potdukhe" },
];


export const technologiesArray = [
  { value: "C/C++", label: "C/C++" },
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  { value: "JavaScript", label: "JavaScript" },
  { value: "C#", label: "C#" },
  { value: "HTML/CSS", label: "HTML/CSS" },
  { value: "Go", label: "Go" },
  { value: "React.js", label: "React.js" },
  { value: "SQL", label: "SQL" },
  { value: "NoSQL", label: "NoSQL" }
];
