import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import {
  getExecutive,
  getUserProfile,
  applicantUpdate,
} from "../libs/firebaseCalls";
import { AuthData } from "./AuthWrapper";
import { Loading } from "./Loading";
// import "../css/Apply.css";

const MAX_TIMEOUT = 2 ** 31 - 1; // Maximum value for setTimeout (2147483647 ms)

export const Apply = () => {
  const { user } = AuthData();

  const [questions, setQuestions] = useState([]);
  const [profile, setProfile] = useState({});
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [saving, setSaving] = useState(false);
  const [appStatus, setAppStatus] = useState("closed");
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const scheduleTimeout = (delay, callback) => {
      if (delay > MAX_TIMEOUT) {
        setTimeout(() => scheduleTimeout(delay - MAX_TIMEOUT, callback), MAX_TIMEOUT);
      } else {
        setTimeout(callback, delay);
      }
    };
    const fetchExecutive = async () => {
      const executiveDetails = await getExecutive();
      setQuestions(executiveDetails.essayQuestions);
      const applicationOpen = executiveDetails.applicationOpen.toDate();
      const applicationClose = executiveDetails.applicationClose.toDate();
      const currentTime = new Date();
      if (currentTime < applicationOpen) {
        setAppStatus("notOpen");
        scheduleTimeout(applicationOpen - currentTime, () => setAppStatus("open"));
      } else if (currentTime >= applicationOpen && currentTime <= applicationClose) {
        setAppStatus("open");
        scheduleTimeout(applicationClose - currentTime, () => setAppStatus("closed"));
      } else {
        setAppStatus("closed");
      }
      setLoading(false);
    };

    const fetchUser = async () => {
      const newProfile = await getUserProfile(user.email, false);
      setResponses(newProfile.essayResponses);
      setProfile(newProfile);
      setLoading2(false);
    };

    fetchExecutive();
    fetchUser();
  }, [user.email]);

  const checkComplete = () => {
    let responsesFilled = true;
    for (const response of responses) {
      if (!response) {
        responsesFilled = false;
      }
    }
    if (!profile.first || !profile.last || !profile.major || profile.major.length === 0 || !profile.pfpURL || !profile.resumeURL || !responsesFilled) {
      return false;
    }
    return true;
  }

  const saveResponses = async () => {
    setSaving(true);
    setShowConfetti(false);
    const trimmedResponses = responses.map(response => response.trim());
    setResponses(trimmedResponses);
    let userObj = {
      essayResponses: trimmedResponses
    }
    const completed = checkComplete();
    userObj.completed = completed;
    await applicantUpdate(user.email, userObj);
    setSaving(false);
    if (completed) {
      setShowConfetti(true);
    }
  };

  const handleTextareaChange = (e, id, limit) => {
    const inputValue = e.target.value;
    const wordCount = inputValue.trim().split(/\s+/).length;

    if (limit !== 0 && wordCount <= limit) {
      setResponses((prevResponses) => {
        const result = [...prevResponses];
        result[id] = inputValue;
        return result;
      });
    }
  };

  if (loading || loading2) {
    return <Loading />;
  }

  return (
    <>
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={600}
          initialVelocityY={20}
          gravity={0.25}
          colors={["#1AADE3", "#000000", "#FFFFFF", "#DDE9FF", "#939393"]}
        />
      )}
      <div className="font-kanit space-y-4 text-ad-dark">
        <div className="text-5xl bold">Essays</div>

        <div className="space-y-8">
          <div className="flex flex-col sm:flex-row items-center sm:items-start justify-between space-y-4 sm:space-y-0 sm:space-x-8">
            <div className="bg-ad-light-blue/50 rounded-xl shadow-lg p-4 space-y-2 w-full sm:w-2/3">
              Please respond to the following application questions. To avoid losing your progress, we recommend typing your answers in a separate document and saving frequently. Once you're satisfied with your responses, paste and save them here for submission.
            </div>
            <div className="bg-ad-light-blue/50 rounded-xl shadow-lg p-4 space-y-2 w-full sm:w-2/3">
              As our portal is still in beta, we have an additional <a href="https://docs.google.com/forms/d/e/1FAIpQLSexjbp_9O0Wtg4SMoW1HzIaCcaDxKGfHjQiy_PcKDA-lX9tow/viewform?usp=sf_link" target="_blank" rel="noreferrer noopener" className="text-red-600 underline">REQUIRED GOOGLE FORM</a> that must be filled out as a failsafe. Please ensure that you have submitted!
            </div>

            <div>
              {appStatus === "open" && (saving ? (
                <div className="bold rounded-full px-8 py-2 uppercase">Saving...</div>
              ) : (
                <div onClick={saveResponses} className="bold rounded-full px-8 py-2 bg-ad-blue text-white uppercase w-fit hover:cursor-pointer hover:bg-white hover:text-ad-dark duration-100 shadow-light">Save</div>
              ))}
            </div>
          </div>

          <div className="">
            {appStatus === "open" ? (
              questions ? (
                <div className="flex flex-col space-y-4">
                  {questions.map((question, id) => (
                    <div key={id} className="qr">
                      <div className="space-y-2">
                        <div className="">
                          Q{id + 1}. {question.prompt} <span className="text-sm">(Word Limit: {question.limit !== 0 ? question.limit : "No Limit"})</span>
                        </div>
                        <textarea
                          className="border-2 border-ad-light-blue rounded-xl font-kanit"
                          type="text"
                          align="top"
                          value={responses[id] || ""}
                          onChange={(e) => handleTextareaChange(e, id, question.limit)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No Essays Available</div>
              )
            ) : questions ? (
              <div className="flex flex-col space-y-4">
                {questions.map((question, id) => (
                  <div key={id} className="qr">
                    <div className="space-y-2">
                      <div className="">
                        Q{id + 1}. {question.prompt} <span className="text-sm">(Word Limit: {question.limit !== 0 ? question.limit : "No Limit"})</span>
                      </div>
                      <textarea
                        className="border-2 border-ad-light-blue rounded-xl font-kanit"
                        type="text"
                        align="top"
                        readOnly
                        value={responses[id] || ""}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>No Essays Available</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
