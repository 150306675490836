import React from "react";
// import "../css/FeedbackProfileView.css";
// import "../css/Feedback.css";
import { defaultPfp } from "../data/data";
import { MemberLinks } from "./MemberViewProfile";
import { IoRibbon } from "react-icons/io5";

export const FeedbackProfileView = ({
  appEmail,
  first,
  last,
  year,
  isAnalyst,
  major,
  minor,
  coursework,
  involvement,
  links,
  pfp,
  scores,
  setFeedbackMode,
  setCommentsOpen,
  setCommentRound,
  executive
}) => {
  return (
    <main className="space-y-8 w-full">
      <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-8">
        <div className="w-full max-w-[250px] h-[300px] bg-ad-light-blue/50 rounded-3xl overflow-hidden shadow-xl" >
          <img src={pfp || defaultPfp} alt="profilePic" className="object-cover w-full h-full"></img>
        </div >

        <div className="flex flex-col space-y-4 lg:space-y-6">
          <div className="space-y-4">
            <div className="flex flex-row flex-wrap space-x-6 items-center">
              <div className="text-4xl md:text-4xl lg:text-5xl bold w-fit">{first} {last}</div>
              <MemberLinks links={links} />
            </div>
          </div>
          <div className="text-4xl md:text-4xl lg:text-5xl bold w-fit">{appEmail}</div>
          <div className="flex flex-col space-y-1 lg:space-y-2">
            <div className="flex space-x-2 items-start">
              {major.length > 1 ? (<div className="bold pt-2">Majors:</div>) : (<div className="bold pt-2">Major:</div>)}
              {major.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {major.map((maj, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{maj}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start">
              {minor.length > 1 ? (<div className="bold pt-2">Minors:</div>) : (<div className="bold pt-2">Minor:</div>)}
              {minor.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {minor.map((min, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{min}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start"><div className="bold pt-2">Expected Graduation:</div><div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{year}</div></div>
            {/* <div className="flex space-x-2 items-center"><div className="bold">Mobile:</div><div className="bg-ad-light-blue/50 px-3 py-1 my-1 rounded-full">{number}</div></div> */}

            <div className="flex space-x-2 items-start">
              <div className="bold pt-2">Coursework:</div>
              {coursework.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {coursework.map((course, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{course}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex space-x-2 items-start">
              {involvement.length > 1 ? (<div className="bold pt-2">Extracurriculars:</div>) : (<div className="bold pt-2">Extracurricular:</div>)}
              {involvement.length === 0 ? (
                <div className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">None</div>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {involvement.map((ec, idx) => (
                    <div key={idx} className="bg-ad-light-blue/50 mx-1 my-1 px-3 py-1 rounded-2xl">{ec}</div>
                  ))}
                </div>
              )}
            </div>


          </div>
        </div>
      </div>

      <div className="flex flex-row flex-wrap bg-ad-light-blue/50 p-4 rounded-xl shadow-xl justify-center">
        <div onClick={() => { setFeedbackMode(true) }} className="uppercase flex space-x-2 items-center bg-ad-blue rounded-full text-white mx-1 my-1 px-3 py-px border-2 border-ad-blue hover:cursor-pointer hover:bg-transparent hover:text-ad-blue duration-100">
          <div className="bold">Give Feedback</div>
          <div >
            <IoRibbon className="size-4" />
          </div>
        </div>
        {/* {scores.map((score, idx) => (
          <div key={idx} className={`${idx <= executive.round ? '' : 'hidden'} uppercase bold text-white hover:text-ad-blue border-2 border-ad-blue mx-1 my-1 px-3 py-px hover:cursor-pointer bg-ad-blue rounded-full hover:bg-transparent duration-100`} onClick={() => { setCommentRound(idx); setCommentsOpen(true) }}>{roundNames[idx]}: {score}</div>
        ))} */}
      </div>


    </main>
  );
};
