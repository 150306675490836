import React, { useEffect, useState } from "react";
import { AuthData } from "./AuthWrapper";
import {
  getExecutive,
  fetchAllGraders,
  averageGraderScores,
  // checkQuestionForChangedGradingScale,
  // checkGradingIncomplete,
  // testing1,
  // testing2,
  // getMatchingApplicants,
  // selectionStatsUpdate,
  // testing3
} from "../libs/firebaseCalls";
import { GradeQuestion } from "./GradeQuestion.js";
import { board, viewsPerApplicant } from "../data/data.js";
import { Loading } from "./Loading.js";
import { Popup } from "./Popup.js"
import "../css/ApplicationGrading.css"

export const ApplicationGrading = () => {
  const { user } = AuthData();
  const [loading, setLoading] = useState(true);
  const [exec, setExec] = useState({});
  const [grader, setGrader] = useState({});
  const [gradersDone, setGradersDone] = useState(false);
  const [graders, setGraders] = useState([]);
  const [viewsDone, setViewsDone] = useState(0);
  const [gradePopupOpen, setGradePopupOpen] = useState(false);
  const [addTasks, setAddTasks] = useState(0);

  useEffect(() => {
    const fetchExec = async () => {
      setLoading(true);
      try {
        const execData = await getExecutive();
        setExec(execData);
        let countGradersDone = 0;
        let totalViews = 0;
        let gradersData = await fetchAllGraders();
        gradersData.forEach((data) => {
          if (data.id === user.email) {
            setGrader(data);
          }
          countGradersDone += data.currApplicant === "done" || data.qidx >= 5 ? 1 : 0;
          totalViews += data.numGraded;
        })
        gradersData.sort(((a, b) => { return b.numGraded - a.numGraded }));
        setGraders(gradersData);
        setViewsDone(totalViews);

        setGradersDone(countGradersDone === board.length);
      } catch (e) {
        console.error("Error fetching data", e);
      } finally {
        setLoading(false);
      }
    };
    fetchExec();
  }, [user.email]);

  const changeTask = async (newTask) => {
    console.log(addTasks)
    if (newTask.currApplicant === "done") { //received done signal
      setGrader((prevGrader) => ({
        ...prevGrader,
        currApplicant: "done",
        currTask: {},
        numGraded: prevGrader.numGraded - 1
      }));
      handleClose();
    } else if (newTask.currApplicant === "close") { //close task, ie, submit and exit
      setGrader((prevGrader) => ({
        ...prevGrader,
        currApplicant: "",
        currTask: {},
      }));
    } else if (!Object.keys(newTask).length) { //empty task, error catch
      handleClose();
      console.log("error caught")
    } else {
      setGrader((prevGrader) => ({ //standard task reception
        ...prevGrader,
        currApplicant: newTask.id,
        currTask: newTask,
      }));
      setAddTasks(addTasks + 1);
      const updatedGraders = graders.map(g =>
        g.id === user.email ? grader : g
      );
      const sortedGraders = updatedGraders.sort(((a, b) => { return b.numGraded - a.numGraded }));
      setGraders(sortedGraders);
    }
  };

  const handleClose = () => {
    setGradePopupOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="font-kanit space-y-12" >
        {gradePopupOpen &&
          <div className={`${gradePopupOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} z-[1000] duration-200 fixed`}>
            <Popup customContainerClass={`h-[90%] w-[80%] max-w-[80%] max-h-[90%] bg-white px-12 py-16 rounded-xl shadow-light`}>
              <GradeQuestion
                exec={exec}
                grader={grader}
                changeTask={changeTask}
                handleClose={handleClose}
              />
            </Popup>
          </div>
        }
        <div className="flex space-x-4 items-center">
          <div className="bold uppercase">Progress</div>
          {gradersDone ?
            (<div className="bg-ad-light-blue/50 rounded-full w-full shadow-lg h-8">
              <div
                className="bg-gradient-to-r from-ad-dark-blue to-ad-blue font-bold rounded-full text-center text-white flex items-center justify-center py-1 px-4"
                style={{ width: "100%" }}
              >
                100%
              </div>
            </div>)
            :
            (<div className="bg-ad-light-blue/50 rounded-full w-full shadow-lg h-8">
              <div
                className="bg-gradient-to-r from-ad-dark-blue to-ad-blue font-bold rounded-full text-center text-white flex items-center justify-center py-1 px-4"
                style={{ width: (viewsDone + (addTasks)) / (viewsPerApplicant * ((exec && exec.selectionStats && exec.selectionStats[0] && exec.selectionStats[0].total) || 1) * ((exec && exec.roundScoringCriteria && exec.roundScoringCriteria[0] && exec.roundScoringCriteria[0].length) || 1)) * 100 < 5.5 ? ("50px") : `${(viewsDone + (addTasks)) / (viewsPerApplicant * ((exec && exec.selectionStats && exec.selectionStats[0] && exec.selectionStats[0].total) || 1) * ((exec && exec.roundScoringCriteria && exec.roundScoringCriteria[0] && exec.roundScoringCriteria[0].length) || 1)) * 100}%` }}
              >
                {Math.round((viewsDone + (addTasks)) / (viewsPerApplicant * ((exec && exec.selectionStats && exec.selectionStats[0] && exec.selectionStats[0].total) || 1) * ((exec && exec.roundScoringCriteria && exec.roundScoringCriteria[0] && exec.roundScoringCriteria[0].length) || 1)) * 100)}%
              </div>
            </div>)
          }
        </div>
        <button onClick={() => averageGraderScores()}>TESTING BUTTON</button>
        <div className="flex flex-col-reverse md:flex-row md:space-y-0 md:space-x-8">
          <div className="mt-10 md:mt-0 md:w-1/2 space-y-8">
            {exec.round !== 0 || !exec.selectionStats || !exec.selectionStats[0] ? (
              <div className="gradingStatusContainer" style={{ height: "100%" }}><b>APPLICATION GRADING NOT YET OPEN</b></div>
            ) : gradersDone ? (
              <div className="gradingStatusContainer" style={{ height: "100%" }}><b>ALL GRADING COMPLETE</b></div>
            ) : grader.currApplicant === "done" || grader.qidx >= 5 ? ( //FIX ME FIXME
              <>
                <div className="homeStatsContainer">
                  <div className="homeTotalContainer">
                    <b>
                      <div style={{ fontSize: "85px" }}>{Math.min(viewsDone + (addTasks), (exec && exec.selectionStats && exec.selectionStats[0] ? exec.selectionStats[0].total : 1))}</div>
                    </b>
                    QUESTIONS GRADED
                  </div>
                  <div className="homeRemainingContainer">
                    <b>
                      <div style={{ fontSize: "85px" }}>{(exec && exec.selectionStats && exec.selectionStats[0] && exec.selectionStats[0].total && exec.roundScoringCriteria ? Math.max(0, exec.selectionStats[0].total * viewsPerApplicant * exec.roundScoringCriteria[0].length - viewsDone - addTasks) : 0)}</div>
                    </b>
                    QUESTIONS LEFT
                  </div>
                </div>
                <div className="gradingStatusContainer"><b>YOU'RE ALL DONE</b></div>
              </>
            ) : (
              <>
                <div className="space-y-4">
                  <div className="flex space-x-4">
                    <div className="bg-ad-light-blue/50 w-1/2 rounded-xl p-4 text-center shadow-lg">
                      <div className="bold text-5xl">{viewsDone + (addTasks)}</div>
                      <div>Questions Graded</div>
                    </div>
                    <div className="bg-ad-light-blue/50 w-1/2 rounded-xl p-4 text-center shadow-lg">
                      <div className="bold text-5xl">{(exec && exec.selectionStats && exec.selectionStats[0] && exec.selectionStats[0].total && exec.roundScoringCriteria ? exec.selectionStats[0].total * viewsPerApplicant * exec.roundScoringCriteria[0].length - viewsDone - addTasks : 0)}</div>
                      <div>Questions Left</div>
                    </div>
                  </div>
                  <div className="bg-ad-light-blue/50 rounded-xl flex flex-col p-4 shadow-lg">
                    <div className="bold uppercase">Current Question:</div>
                    <div className="max-h-40 overflow-auto">
                      {exec?.roundScoringCriteria?.[0]?.[grader.qidx]?.criteria || "YOU'RE ALL DONE"}
                    </div>
                  </div>
                </div>
                <div className="bg-ad-blue bold rounded-full uppercase hover:bg-white hover:text-ad-dark text-white hover:cursor-pointer duration-200 text-center p-2 shadow-light" onClick={() => setGradePopupOpen(true)}>
                  Grade Question
                </div>
              </>
            )}
          </div>
          <div className="md:w-1/2 rounded-xl space-y-2">
            <div className="bold uppercase text-center text-2xl">Leaderboard</div>
            <div className="rounded-xl bg-gradient-to-b from-ad-light-blue to-white overflow-auto shadow-xl max-h-[450px]">
              <table className="table-auto w-full">
                <thead className="sticky top-0 bg-ad-light-blue">
                  <tr key={0} >
                    <th className="py-2 rounded-tl-xl uppercase">Member</th>
                    <th className="py-2 uppercase">Q#</th>
                    <th className="py-2 rounded-tr-xl uppercase">Graded</th>
                  </tr>
                </thead>
                <tbody className="text-center font-normal">
                  {graders.map((g, idx) => (
                    g.id === user.email ?
                      (
                        <tr key={idx} className="odd:bg-white even:bg-gray-50 border-b">
                          <td className="py-2">{g.id.split('@')[0]}</td>
                          <td className="py-2">{g.currApplicant === "done" ? "Done!" : g.qidx + 1}</td>
                          <td className="py-2">{g.numGraded}</td>
                        </tr>
                      )
                      :
                      (
                        <tr key={idx} className="odd:bg-white even:bg-gray-50 border-b" >
                          <td className="py-2">{g.id.split('@')[0]}</td>
                          <td className="py-2">{g.currApplicant === "done" ? "Done!" : g.qidx + 1}</td>
                          <td className="py-2">{g.numGraded}</td>
                        </tr>
                      )
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div >
      </div >
    </>
    // <div className="">
    //   {grader.currTask && Object.keys(grader.currTask).length === 0 ? (
    //     <div>
    //       Hello {user.uniqname}!
    //       <div>Actions:</div>
    //       <button onClick={fetchQuestion}>Get Question to Grade</button>
    //     </div>
    //   ) : (
    //     <GradeQuestion changeTask={changeTask} grader={grader} exec={exec} />
    //   )}
    // </div>
  );
};

export default ApplicationGrading;
