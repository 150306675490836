import React, { useState, useEffect } from "react";
import {
  getUserProfile,
  getExecutive,
  uploadApplicantScores,
} from "../libs/firebaseCalls";
import { AuthData } from "./AuthWrapper";
import { Loading } from "./Loading.js";
import { FeedbackItem } from "./FeedbackItem.js";
// import "../css/Feedback.css";
// import "../css/FeedbackFeedbackView.css";
import { theme1Image } from "../data/data.js";
import { IoAdd } from "react-icons/io5";

export const FullFeedback = () => {
  const { user } = AuthData();
  const [executive, setExecutive] = useState({ round: 0 });
  const [addApplicantText, setAddApplicantText] = useState("");
  const [people, setPeople] = useState([]);
  const [names, setNames] = useState([]);
  const [invalidUniqname, setInvalidUniqname] = useState(false);
  const [duplicateUniqname, setDuplicateUniqname] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitFlags, setSubmitFlags] = useState([]);

  useEffect(() => {
    const fetchExecutiveData = async () => {
      setLoading(true);
      const executiveData = await getExecutive();
      setExecutive(executiveData);
      setLoading(false);
    };
    fetchExecutiveData();
  }, []);

  // useEffect(() => {
  //   console.log(submitFlags);
  // }, [submitFlags])

  const addApplicantClick = async (e) => {
    e.preventDefault();
    if (!people.includes(addApplicantText)) {
      setLoading(true);
      setDuplicateUniqname(false);
      const profile = await getUserProfile(addApplicantText + "@umich.edu", false);
      if (!profile) {
        setInvalidUniqname(true);
        setDuplicateUniqname(false);
      } else {
        setInvalidUniqname(false);
        setNames([...names, profile.first + " " + profile.last]);
        setPeople([...people, addApplicantText]);
        setSubmitFlags([...submitFlags, { uniqname: addApplicantText, scores: [], comment: "" }]);
      }
      setAddApplicantText("");
      setLoading(false);
    } else {
      setDuplicateUniqname(true);
      setInvalidUniqname(false);
    }
  };

  const outerSliderChange = (i, newScores) => {
    setSubmitFlags((prevSubmitFlags) => {
      let copy = prevSubmitFlags
      copy[i].scores = newScores;
      return copy;
    });
  }

  const removeApplicant = async (index) => {
    setPeople(people.filter((_, idx) => idx !== index));
    setNames(names.filter((_, idx) => idx !== index));
    setSubmitFlags(submitFlags.filter((_, idx) => idx !== index));
  };

  const clearApplicants = () => {
    setPeople([]);
    setNames([]);
    setSubmitFlags([]);
  };

  const submitForm = async (index) => {
    const applicantEmail = people[index] + "@umich.edu";
    const graderEmail = user.email;
    const scores = submitFlags[index].scores;
    const comment = submitFlags[index].comment || "";
    console.log(applicantEmail, graderEmail, scores, comment);
    await uploadApplicantScores(applicantEmail, graderEmail, scores, comment);
  };

  const submitAllForms = async () => {
    setLoading(true);
    for (let i = 0; i < people.length; i++) {
      await submitForm(i);
    }
    clearApplicants();
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="font-kanit w-full">
          {executive.round === 0 ? (
            <div className="flex flex-col items-center space-y-4">
              <div className="bold text-3xl">Feedback Closed for Current Round</div>
              <div className="">
                <img src={theme1Image} alt="theme1" className="w-80" draggable={false} />
              </div>
            </div>
          ) : (
            <div className="space-y-8 flex flex-col items-center">


              <div className="space-y-4 flex flex-col items-center w-full">
                {!invalidUniqname && !duplicateUniqname && (
                  <div className="bold text-ad-dark uppercase">Search for applicants</div>
                )}
                {invalidUniqname && (
                  <div className="bold text-red-500 uppercase">Applicant not found</div>
                )}
                {duplicateUniqname && (
                  <div className="bold text-red-500 uppercase">Applicant already selected</div>
                )}

                <form onSubmit={addApplicantClick} className="relative flex flex-col items-center justify-center w-full sm:w-2/3">
                  <input
                    type="text"
                    placeholder="Enter Uniqname"
                    value={addApplicantText}
                    onChange={(e) => setAddApplicantText(e.target.value)}
                    className="shadow-light rounded-full px-8 py-4 w-full  text-center"
                  />
                  <div className="absolute bg-ad-blue text-white size-8 flex rounded-full items-center justify-center right-4 hover:cursor-pointer border-2 border-ad-blue hover:bg-white hover:text-ad-blue duration-200" onClick={addApplicantClick}><IoAdd className="size-6" /></div>
                </form>


              </div>


              <div className="bg-ad-light-blue/50 rounded-xl max-w-[calc(100vw-166px)] lg:max-w-[calc(100vw-352px)] shadow-xl">
                {!people.length ? (
                  <div className="bold uppercase p-8 text-center">No Applicants Selected</div>
                ) : (
                  <div className="flex flex-row space-x-8 px-8 py-6 overflow-x-scroll ">
                    {people.map((person, index) => (
                      <div key={index}>
                        <FeedbackItem
                          executive={executive}
                          name={names[index]}
                          applicantEmail={person + "@umich.edu"}
                          graderEmail={user.email}
                          singleSubmitIdx={index}
                          removeApplicant={removeApplicant}
                          submitFlags={submitFlags}
                          setSubmitFlags={setSubmitFlags}
                          outerSliderChange={outerSliderChange}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {people.length !== 0 && (
                <div className="flex space-x-4 text-center">
                  <div className="bg-ad-blue text-white bold uppercase rounded-full shadow-light hover:text-ad-dark hover:bg-white w-40 py-2 hover:cursor-pointer duration-100" onClick={clearApplicants}>Clear</div>
                  <div className="bg-ad-blue text-white bold uppercase rounded-full shadow-light hover:text-ad-dark hover:bg-white w-40 py-2 hover:cursor-pointer duration-100" onClick={submitAllForms}>Submit All</div>
                </div>
              )}

            </div>
          )}
        </div>
      )}
    </>
  );
};