import React from "react";
import { AuthData } from "./AuthWrapper";
// import "../css/Home.css";
import { ApplicantHome } from "./ApplicantHome";
import { MemberHome } from "./MemberHome";
import { PostCloseApplicantHome } from "./PostCloseApplicantHome"

export const Home = () => {
  const { user } = AuthData();
  return (
    <div className="">
      {user.position !== "Applicant" ? (
        <MemberHome />
      ) : user.round > 0 ? (
      // ) : false ? (
        <PostCloseApplicantHome />
      ) : (
        <ApplicantHome />
      )}
    </div>
  );
};
