import React, { useState } from "react";
import { memberCreation } from "../libs/firebaseCalls";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { AuthData } from "./AuthWrapper";

export const MemberSignUp = ({ years, majors, minors }) => {
  const { user, completeSignUp, logout } = AuthData();
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [position, setPosition] = useState("");
  const [number, setNumber] = useState("");
  const [links, setLinks] = useState([]);

  const positions = [
    {
      value: "Analyst",
      label: "Analyst",
    },
    {
      value: "Project Manager",
      label: "Project Manager",
    },
    {
      value: "Senior Advisor",
      label: "Senior Advisor",
    },
    {
      value: "Alumni",
      label: "Alumni",
    }
    // {
    //   value: "President",
    //   label: "President",
    // },
    // {
    //   value: "Vice President",
    //   label: "Vice President",
    // },
    // {
    //   value: "Director of Recruiting",
    //   label: "Director of Recruiting",
    // },
    // {
    //   value: "Director of Projects",
    //   label: "Director of Projects",
    // },
    // {
    //   value: "Director of Corporate Engagement",
    //   label: "Director of Corporate Engagement",
    // },
    // {
    //   value: "Director of Tech Dev",
    //   label: "Director of Tech Dev",
    // },
    // {
    //   value: "Director of Design Dev",
    //   label: "Director of Design Dev",
    // },
    // {
    //   value: "Director of Social",
    //   label: "Director of Social",
    // },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formMajors = major.map((cur) => {
      return cur.value;
    });
    const formMinors = minor.map((cur) => {
      return cur.value;
    });
    const formPosition = position.value;
    const formLinks = links.map((cur) => {
      return cur.value;
    });

    await memberCreation(
      user.email,
      first,
      last,
      number,
      year,
      formMajors,
      formMinors,
      formPosition,
      formLinks,
      completeSignUp
    );
  };

  return (
    <div className="page font-kanit">
      <form onSubmit={handleSubmit}>
        <div className="signUpContainer flex flex-col justify-evenly mb-[50px]">
        <div className="signUpInput">
            <label className="bold">
              First name?
            </label>
            <input
              type="text"
              className="border-[1px] border-solid border-black rounded-md border-[#e5e7eb]"
              value={first}
              required
              onChange={(e) => setFirst(e.target.value)}
            />
          </div>
          <div className="signUpInput">
            <label className="bold">
              Last name?
            </label>
            <input
              type="text"
              className="border-[1px] border-solid border-black rounded-md border-[#e5e7eb]"
              value={last}
              required
              onChange={(e) => setLast(e.target.value)}
            />
          </div>
          <div className="signUpInput">
            <label className="bold">
              Number?
            </label>
            <input
              type="text"
              className="border-[1px] border-solid border-black rounded-md border-[#e5e7eb]"
              value={number}
              required
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div className="signUpInput">
            <label className="bold">
              Graduating year?
            </label>
            <select value={year} className="border-[1px] border-solid border-black rounded-md border-[#e5e7eb]" onChange={(e) => setYear(e.target.value)}>
              {years.map((curYear, idx) => (
                <option key={idx} value={curYear}>
                  {curYear}
                </option>
              ))}
            </select>
          </div>
          <div className="signUpInput">
            <label className="bold">
              Major(s)?
            </label>
            <CreatableSelect
              isMulti
              options={majors}
              value={major}
              className="max-w-[70%] min-w-[20%]"
              required
              onChange={(selectedOptions) => {
                setMajor(selectedOptions);
              }}
            />
          </div>
          <div className="signUpInput">
            <label className="bold">
              Minor(s)?
            </label>
            <CreatableSelect
              isMulti
              options={minors}
              value={minor}
              className="max-w-[70%] min-w-[20%]"
              onChange={(selectedOptions) => {
                setMinor(selectedOptions);
              }}
            />
          </div>
          <div className="signUpInput">
            <label className="bold">
              Position?
            </label>
            <Select
              options={positions}
              required
              value={position}
              className="max-w-[70%] min-w-[20%]"
              onChange={(newPosition) => {
                setPosition(newPosition);
              }}
            />
          </div>
          <div className="signUpInput">
            <label className="bold">
              Relevant Links (LinkedIn, GitHub, etc)?
            </label>
            <CreatableSelect
              isMulti
              options={[]}
              value={links}
              className="max-w-[70%] min-w-[20%]"
              onChange={(selectedOptions) => {
                setLinks(selectedOptions);
              }}
            />
          </div>
          <div className="flex flex-row w-[100%] justify-around" >
            <button onClick={logout} className="w-[35%]"> Logout</button>
            <button type="submit" className="w-[35%] self-center">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};
