import React from "react";
import "../css/SubmissionError.css";

export const SubmissionError = ({ message, setErrorMessage }) => {

    return (
        <div className="errorContainer">
            <div className="errorTitleContainer">
                <b>ERROR!</b>
                <button className="errorCloseButton" onClick={() => setErrorMessage("")}>&times;</button>
            </div>
            <div className="errorTextContainer flex flex-col">
            {message.length ? 
                    (
                        message.split('\n').map((m, i) => (<div key={i}><b>{m}</b></div>))
                    )
                    :
                    (
                        <b>"UPLOAD FAILED"</b>
                    )}
            </div>
        </div>
    );
}