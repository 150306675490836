import React from "react";
import { AuthData } from "./AuthWrapper";
import "../css/AppClosed.css";
import { ad_logo, ad_logo_long } from "../data/svg";
import { theme0Image } from "../data/data";

export const AppClosed = ({ appStatus, appOpen }) => {
  const { logout } = AuthData();

  return (
    <div className="font-kanit text-ad-dark">
      {appStatus === "notOpen" ? (
        <div className="relative flex flex-col md:flex-row font-kanit text-ad-dark w-screen h-screen">
          <div className=" md:w-1/2 h-screen flex flex-col justify-center items-center space-y-8 p-4 ">
            <div className="flex flex-col items-center space-y-2 px-4 w-full max-w-[500px]">
              <div className="w-40">{ad_logo_long}</div>
              <div className="uppercase font-bold text-7xl">Hello</div>
              <div className="text-center w-[480px]">We're excited you're interested in Atlas Digital! Our application opens on {appOpen}.</div>
            </div>
            <div onClick={logout} className="bold uppercase shadow-light rounded-full bg-ad-blue text-white hover:cursor-pointer hover:text-ad-dark duration-100 px-14 hover:bg-white py-2 text-center w-fit">LOG OUT</div>
          </div>
          <div className="w-1/2 h-screen left-0 top-0 bg-ad-light-blue hidden md:flex justify-center items-center flex-col p-4">
            <div className="size-28">{ad_logo}</div>
            <div className="">
              <img
                src={theme0Image}
                className="w-96"
                alt="Theme pic"
                draggable={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col md:flex-row font-kanit text-ad-dark w-screen h-screen">
          <div className=" md:w-1/2 h-screen flex flex-col justify-center items-center space-y-8 p-4 ">
            <div className="flex flex-col items-center space-y-2 px-4 w-full max-w-[500px]">
              <div className="w-40">{ad_logo_long}</div>
              <div className="uppercase font-bold text-6xl">Thank You</div>
              <div className="text-center w-[480px]">We reallly appreciate your interest in Atlas Digital. Our application is now closed. We will be in touch if you've been selected to move forward in our recruitment process. Hope to see you soon!</div>
            </div>
            <div onClick={logout} className="bold uppercase shadow-light rounded-full bg-ad-blue text-white hover:cursor-pointer hover:text-ad-dark duration-100 px-14 hover:bg-white py-2 text-center w-fit">LOG OUT</div>
          </div>
          <div className="w-1/2 h-screen left-0 top-0 bg-ad-light-blue hidden md:flex justify-center items-center flex-col p-4">
            <div className="size-28">{ad_logo}</div>
            {/* <div className="w-48 pb-8">{ad_logo_stacked}</div> */}
            <div className="">
              <img
                src={theme0Image}
                className="w-96"
                alt="Theme pic"
                draggable={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
