import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getUserProfile,
  getApplicantScores,
  getExecutive,
} from "../libs/firebaseCalls";
import { AuthData } from "./AuthWrapper";
import { FeedbackProfileView } from "./FeedbackProfileView.js";
import { FeedbackFeedbackView } from "./FeedbackFeedbackView.js";
import { Loading } from "./Loading.js"
import { Popup } from "./Popup.js";
import { Comments } from "./Comments.js"
import "../css/Feedback.css";

export const Feedback = () => {
  const { user } = AuthData();
  const { applicant } = useParams();
  const [loading, setLoading] = useState(true);
  const [feedbackMode, setFeedbackMode] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [isAnalyst, setIsAnalyst] = useState(true);
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [coursework, setCoursework] = useState([]);
  const [involvement, setInvolvement] = useState([]);
  const [links, setLinks] = useState([]);
  const [pfp, setPFP] = useState("");
  const [applicantScores, setApplicantScores] = useState();
  const [applicantComment, setApplicantComment] = useState("");
  const [executive, setExecutive] = useState({});
  const [scores, setScores] = useState([0])
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [commentRound, setCommentRound] = useState(0);
  const [appEmail, setAppEmail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const email = applicant + "@umich.edu";
        setAppEmail(email);
        const newProfile = await getUserProfile(email, false);
        setFirst(newProfile.first);
        setLast(newProfile.last);
        setIsAnalyst(newProfile.isAnalyst);
        setMajor(newProfile.major);
        setMinor(newProfile.minor);
        setCoursework(newProfile.coursework);
        setInvolvement(newProfile.involvement);
        setLinks(newProfile.links);
        setPFP(newProfile.pfpURL);
        setYear(newProfile.class);
        setScores(newProfile.normScores);
        const exec = await getExecutive();
        setExecutive(exec);
        const tempApplicantResults = await getApplicantScores(email, user.email, exec.round);
        console.log(tempApplicantResults)
        setApplicantScores(tempApplicantResults ? tempApplicantResults.scores : []);
        setApplicantComment(tempApplicantResults ? tempApplicantResults.comment : "");
      } catch (e) {
        console.error("failed fetching applicant data")
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [applicant, user.email]);

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <div className="font-kanit space-y-12">
      <div className="bold text-5xl">{isAnalyst ? "Analyst" : "Designer"}</div>
      <div className="viewProfileInfo flex flex-col">
        <FeedbackProfileView
          appEmail={appEmail}
          first={first}
          last={last}
          year={year}
          isAnalyst={isAnalyst}
          major={major}
          minor={minor}
          coursework={coursework}
          involvement={involvement}
          links={links}
          pfp={pfp}
          setFeedbackMode={setFeedbackMode}
          scores={scores}
          setCommentsOpen={setCommentsOpen}
          setCommentRound={setCommentRound}
          executive={executive}
        />
        <div className={`${feedbackMode ? 'opacity-100' : 'opacity-0 pointer-events-none'} duration-200`}>
          <Popup closePopup={() => { setFeedbackMode(false) }} customContainerClass="h-[50%] w-[80%] md:w-[40%] bg-white px-12 py-8 rounded-xl shadow-light relative overflow-y-scroll">
            <FeedbackFeedbackView
              applicantScores={applicantScores}
              applicantComment={applicantComment}
              executive={executive}
              name={first + ' ' + last}
              applicantEmail={applicant + "@umich.edu"}
              graderEmail={user.email}
              handleClose={() => setFeedbackMode(false)}
            />
          </Popup>
        </div>
        <div className={`${commentsOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} duration-200`}>
          <Popup closePopup={() => { setCommentsOpen(false) }} customContainerClass={"h-[50%] w-[80%] md:w-[40%] bg-white px-12 py-8 rounded-xl shadow-light relative overflow-y-scroll flex flex-col"}>
            <Comments
              applicant={applicant}
              round={commentRound}
            />
          </Popup>
        </div>
      </div>

    </div>
  );
};
