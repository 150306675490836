import React from "react";
import "./css/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Layout } from "./components/Layout";
import { LoggedIn } from "./components/LoggedIn";
import { Home } from "./components/Home";
import { Apply } from "./components/Apply";
import { Profile } from "./components/Profile";
import { Feedback } from "./components/Feedback";
import { Search } from "./components/Search";
import { FullFeedback } from "./components/FullFeedback";
import { Selection } from "./components/Selection";
import { Login } from "./components/Login";
import { SignUp } from "./components/SignUp";
import { Error } from "./components/Error";
import { ApplicationGrading } from "./components/ApplicationGrading";
import { Admin } from "./components/Admin";
import { ApplicantSchedule } from "./components/ApplicantSchedule";
import { MemberSchedule } from "./components/MemberSchedule";
import {
  MemberElement,
  ApplicantElement,
  // BoardElement,
  CommitteeElement,
  ExecutiveElement,
  PostApplicationElement,
  //PresidentElement,
} from "./components/Access";
import MovedOn from "./components/MovedOn";


function App() {
  const router = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        {
          path: "",
          element: <LoggedIn />,
          children: [
            {
              index: true,
              element: <Home />,
            },
            {
              path: "apply",
              element: (
                <ApplicantElement>
                  <Apply />
                </ApplicantElement>
              ),
            },
            {
              path: "applicant-schedule",
              element: (
                <PostApplicationElement>
                  <ApplicantSchedule />
                </PostApplicationElement>
              ),
            },
            {
              path: "profile",
              element: <Profile />,
            },
            {
              path: "search",
              element: (
                <MemberElement>
                  <Search />
                </MemberElement>
              ),
            },
            {
              path: "feedback/:applicant",
              element: (
                <MemberElement>
                  <Feedback />
                </MemberElement>
              ),
            },
            {
              path: "fullfeedback",
              element: (
                <MemberElement>
                  <FullFeedback />
                </MemberElement>
              ),
            },
            {
              path: "member-schedule",
              element: (
                <CommitteeElement>
                  <MemberSchedule />
                </CommitteeElement>
              ),
            },
            {
              path: "selection",
              element: (
                <ExecutiveElement>
                  <Selection />
                </ExecutiveElement>
              ),
            },
            {
              path: "continued",
              element: (
                <ExecutiveElement>
                  <MovedOn />
                </ExecutiveElement>
              ),
            },
            {
              path: "applicantgrading",
              element: (
                <CommitteeElement>
                  <ApplicationGrading />
                </CommitteeElement>
              ),
            },
            {
              path: "admin",
              element: (
                <ExecutiveElement>
                  <Admin />
                </ExecutiveElement>
              )
            }
          ],
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
      ],
    },
    {
      path: "*",
      element: <Error />,
    },
  ]);

  return (
    <div className="">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
