import React from "react";
import { Navigate } from "react-router-dom";
import { AuthData } from "./AuthWrapper";
import { members } from "../data/data";
import { MemberSignUp } from "./MemberSignUp";
import { ApplicantSignUp } from "./ApplicantSignUp";
import { majors, minors, years } from "../data/data.js"

export const SignUp = () => {
  const { user } = AuthData();

  return (
    <>
      {user.uniqname === "" ? (
        <Navigate to="/login" />
      ) : user.completeSignUp ? (
        <Navigate to="/" />
      ) : (
        <>
          <div className="font-kanit px-12 py-16 space-y-8">
            <div className="flex flex-row gap-[30px] items-end">
              <div className="text-5xl font-bold">Account Creation Page</div>
              <div className="text-xl font-bold text-red-600">*You will be able to modify this information later*</div>
            </div>
            {/* <div className="text-5xl font-bold">Hi {user.uniqname}!</div> */}
            {members.has(user.uniqname) ? (
              <MemberSignUp years={years} majors={majors} minors={minors} />
            ) : (
              <ApplicantSignUp years={years} majors={majors} minors={minors} />
            )}
          </div>
        </>
      )}
    </>
  );
};
