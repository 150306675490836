import React from "react";
import { AuthData } from "./AuthWrapper";
import { roundNames } from "../data/data";
import { Link } from "react-router-dom";
import { theme1Image } from "../data/data";
// import { Timeline } from "./Timeline";

export const PostCloseApplicantHome = () => {
  const { user } = AuthData();

  return (
    <div className="space-y-12 font-kanit">
      <div className="text-5xl font-bold">Hi {user.uniqname}!</div>

      <div className="flex space-x-8 justify-around">


        <div className="bg-ad-light-blue/50 shadow-lg rounded-xl px-8 py-4 w-fit h-fit">
          <div className="bold">Congrats on making it to the next round!</div>
          {roundNames[user.round].includes("Interview") ? (
            <div className="flex flex-col gap-[40px]">
              <div className="bold">
                Please sign up for a time to complete your{" "}
                {`${roundNames[user.round]}`.toLowerCase()} in the Schedule tab!
              </div>
              <Link to="/applicant-schedule" className="button1">
                <b>Schedule</b>
              </Link>
            </div>
          ) : (
            <div className="bold">Keep watch for an email detailing your next steps.</div>
          )}
        </div>
        <div className="pr-8">
          <img src={theme1Image} alt="theme1" className="w-52" draggable={false} />
        </div>
      </div>
    </div>
  );
};
