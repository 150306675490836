import { React, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { AuthData } from "./AuthWrapper";
import { getExecutive, getApplicantsByRound } from "../libs/firebaseCalls";
import { defaultPfp, numRounds, roundNames, theme0Image } from "../data/data";
import { Link } from "react-router-dom";
import { Loading } from "./Loading.js"
import { Popup } from "./Popup.js";
import { Flags } from "./Flags.js";
import '../css/MemberHome.css'

export const MemberHome = () => {
  const { user } = AuthData();
  const [exec, setExec] = useState(null);
  const [loading, setLoading] = useState(true);
  const [finalApplicants, setFinalApplicants] = useState([]);
  const [flagPopupOpen, setFlagPopupOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const eData = await getExecutive();
      setExec(eData);
      if (eData.round === numRounds) {
        const newApplicants = await getApplicantsByRound(numRounds);
        setFinalApplicants(newApplicants);
      }
      // if (eData.round === 0) {
      //   const newApplicants = await getApplicantsByRound(0);
      //   setFinalApplicants(newApplicants);
      // }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ?
        (
          <Loading />
        )
        :
        (
          <div className="font-kanit">
            {exec && exec.round === numRounds &&
              <Confetti
                recycle={false}
                numberOfPieces={600}
                initialVelocityY={15}
                gravity={0.3}
                colors={["#1AADE3", "#000000", "#FFFFFF", "#DDE9FF"]}
              />
            }
            {flagPopupOpen && (
              <Popup closePopup={() => setFlagPopupOpen(false)} title="Flags Form">
                <Flags></Flags>
              </Popup>
            )}
            <div className="space-y-12">
              <div className="text-5xl font-bold">Hi {user.uniqname}!</div>

              {/* Progress Bar */}
              <div className="flex space-x-4 items-center">
                <div className="font-bold uppercase ">Progress</div>
                <div className="bg-ad-light-blue/50 rounded-full w-full shadow-lg">
                  <div
                    className="bg-gradient-to-r from-ad-dark-blue to-ad-blue font-bold rounded-full text-center text-white flex items-center justify-center py-1 px-4"
                    style={{ width: (exec ? exec.round : 0) === 0 ? ("50px") : `${(((exec ? exec.round : 0)) / numRounds * 100)}%` }}
                  >
                    {((exec ? exec.round : 0)) / numRounds * 100}%
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8">
                <div className="md:w-1/2 space-y-4">
                  <div className="flex space-x-4 ">
                    <div className="bg-ad-light-blue/50 w-1/2 rounded-xl p-4 text-center shadow-lg">
                      <div className="bold text-5xl" >{exec ? (exec.selectionStats && exec.selectionStats[0] && exec.selectionStats[0].total) || 0 : 0}</div>
                      <div>Total Applicants</div>
                    </div>
                    <div className="bg-ad-light-blue/50 w-1/2 rounded-xl p-4 text-center shadow-lg">
                      <div className="bold text-5xl">{exec ? (exec.selectionStats && exec.selectionStats[exec.round] && exec.selectionStats[exec.round].total) || 0 : 0}</div>
                      <div>Applicants Remaining</div>
                    </div>
                  </div>
                  <div className="p-4 flex bg-ad-light-blue/50 rounded-xl justify-between shadow-lg">
                    <div>Current Round:</div>
                    <div className="bold">{roundNames[(exec ? exec.round : 0)]}</div>
                  </div>
                  {/* <div className="bg-ad-light-blue/50 rounded-xl justify-between flex p-4 shadow-lg">
                    <Link to="/search" className="text-white bg-ad-blue rounded-full w-1/5 flex flex-col justify-center items-center">
                      <IoSearchOutline
                        style={{ userDrag: "none", WebkitUserDrag: "none", width: "50%", height: "50%" }}
                      />
                      <div className="uppercase">Search</div>
                    </Link>
                    <Link to="/fullfeedback" className="text-white aspect-square bg-ad-blue rounded-full w-1/5 flex justify-center items-center">
                      <IoRibbonOutline
                        style={{ userDrag: "none", WebkitUserDrag: "none", width: "50%", height: "50%" }}
                      />
                    </Link>
                    <div className="text-white aspect-square bg-ad-blue rounded-full w-1/5 flex justify-center items-center hover:cursor-pointer" onClick={(() => { setFlagPopupOpen(!flagPopupOpen) })}>
                      <IoFlagOutline
                        style={{ userDrag: "none", WebkitUserDrag: "none", width: "50%", height: "50%" }}
                      />
                      
                    </div>
                    <Link to="/profile" className="text-white aspect-square bg-ad-blue rounded-full w-1/5 flex justify-center items-center">
                      <IoPersonOutline
                        style={{ userDrag: "none", WebkitUserDrag: "none", width: "50%", height: "50%" }}
                      />
                    </Link>
                  </div> */}
                </div>
                <div className="md:w-1/2 text-center bg-ad-light-blue/50 rounded-xl p-4 space-y-2 shadow-lg h-fit">
                  <div className="bold uppercase text-2xl">Newbie Class</div>
                  {exec && exec.round === numRounds ? 
                    // {true ?
                    (
                      <>
                        <div className="homePfpGridContainer">
                          <div className="homePfpGrid">
                            {finalApplicants.map((applicant, idx) => (
                              <div key={idx} className="homePfpGridItem">
                                <Link to={`/feedback/${applicant.id.split('@')[0]}`}>
                                  <img src={applicant.pfpURL || defaultPfp} alt={`Profile of ${applicant.id}`} className="homePfpGridPfp" />
                                  <b>{applicant.first} {applicant.last}</b>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )
                    :
                    (
                      <div>
                        <div>To Be Announced</div>
                        <div className="flex justify-center">
                          <img src={theme0Image} alt="theme0" className="w-64" draggable={false} />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

