import React from "react";
import { ad_logo } from "../data/svg";

export const Loading = ({ logged, customContainerClass }) => {
  const container = customContainerClass || `w-full ${logged ? "h-screen":"h-[calc(100vh-128px)]"} flex flex-col justify-center items-center`
  return (
    <div className={container}>
      <div className={`flex size-24 animate-spin -center`}>
        {ad_logo}
      </div>
    </div>
  );
};

export default Loading;
