export const ad_logo = (
  <svg
    viewBox="0 0 1000 645"
    fill="#121212"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_206_2)">
      <path d="M267 0H428.093L677 645H515.907L267 0Z" fill="#1DAFEC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M522.791 2.07672L522 0L1000 187.059L770.053 645L700.265 463.539L798.383 268.649L594.65 188.922L522.791 2.07672Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.212 126L0 645H160.159L298 323.059L222.655 126.975L222.212 126Z"
      />
    </g>
  </svg>
);


export const ad_logo_long = (
  <svg
    viewBox="0 0 2861 233"
    fill="#121212"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_255_107)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1132.96 113C1132.68 122.062 1130.85 131.003 1127.56 139.393C1123.96 148.553 1118.7 156.876 1112.06 163.887C1105.42 170.897 1097.54 176.459 1088.86 180.253C1080.2 184.041 1070.92 185.994 1061.55 186H1000V206V233H1062H1082C1089.12 233 1096.24 232.265 1103.27 230.789C1121.34 226.996 1138.2 218.434 1152.28 205.903C1166.36 193.372 1177.2 177.28 1183.79 159.131C1188.46 146.273 1190.88 132.678 1191 119H1191V118.018V117.981V113H1190.9H1132.96Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1058.04 120C1058.32 110.938 1060.15 101.997 1063.44 93.6074C1067.04 84.4473 1072.3 76.124 1078.94 69.1133C1085.58 62.1025 1093.46 56.5415 1102.14 52.7471C1110.8 48.9587 1120.08 47.0063 1129.45 47L1191 47V27V0L1129 0H1109C1101.88 -2.28882e-05 1094.76 0.735085 1087.73 2.21141C1069.66 6.00438 1052.8 14.5664 1038.72 27.0972C1024.64 39.6284 1013.8 55.7197 1007.21 73.8686C1002.54 86.7275 1000.12 100.322 1000 114H1000V114.982V115.019V120H1000.1L1058.04 120Z"
      />
      <path d="M96 0H153.758L243 233H185.242L96 0Z" />
      <path d="M823 0H880.758L970 233H912.242L823 0Z" />
      <path d="M2488 0H2545.76L2635 233H2577.24L2488 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9533 44.9888L0 233H57.6264L107.222 116.375L80.1128 45.3419L79.9533 44.9888Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M806.953 44.9888L727 233H784.626L834.222 116.375L807.113 45.3419L806.953 44.9888Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2471.95 44.9888L2392 233H2449.63L2499.22 116.375L2472.11 45.3419L2471.95 44.9888Z"
      />
      <rect x="323" y="74" width="58" height="159" />
      <rect x="2240" y="74" width="58" height="159" />
      <rect x="1401" y="74" width="58" height="159" />
      <rect x="1937" y="116" width="58" height="117" />
      <rect x="1673" width="58" height="233" />
      <rect x="2057" width="58" height="233" />
      <rect x="517" width="58" height="233" />
      <rect x="2690" width="58" height="233" />
      <rect
        x="517"
        y="233"
        width="47"
        height="171"
        transform="rotate(-90 517 233)"
      />
      <rect
        x="2690"
        y="233"
        width="47"
        height="171"
        transform="rotate(-90 2690 233)"
      />
      <rect
        x="253"
        y="47"
        width="47"
        height="198"
        transform="rotate(-90 253 47)"
      />
      <rect
        x="2170"
        y="47"
        width="47"
        height="198"
        transform="rotate(-90 2170 47)"
      />
      <rect
        x="1400"
        y="47"
        width="47"
        height="106"
        transform="rotate(-90 1400 47)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1462.32 10.498C1478.25 2.55001 1495.74 -0.976153 1513.22 0.231827C1530.69 1.43981 1547.63 7.34464 1562.53 17.4233C1577.42 27.5019 1589.81 41.4433 1598.61 58.0125C1607.4 74.5816 1612.32 93.2672 1612.93 112.414C1613.55 131.561 1609.84 150.578 1602.13 167.78C1594.42 184.983 1582.95 199.84 1568.74 211.036C1554.53 222.233 1538.01 229.422 1520.65 231.967C1509.08 233.664 1497.38 233.263 1486 230.821V185.581C1493.26 184.862 1500.38 183.226 1507.16 180.71C1516.56 177.217 1525.1 172.098 1532.3 165.644C1539.5 159.19 1545.21 151.529 1549.1 143.097C1553 134.664 1555 125.627 1555 116.5C1555 107.373 1553 98.3356 1549.1 89.9035C1545.21 81.4714 1539.5 73.8098 1532.3 67.3561C1525.1 60.9024 1516.56 55.7831 1507.16 52.2904C1497.77 48.8014 1487.7 47.0038 1477.53 47L1462.32 10.498Z"
      />
      <rect
        x="1995"
        y="186"
        width="47"
        height="106"
        transform="rotate(90 1995 186)"
      />
      <rect
        x="1995"
        width="47"
        height="106"
        transform="rotate(90 1995 0)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1932.68 222.502C1916.47 230.59 1898.65 234.097 1880.88 232.701C1863.1 231.306 1845.93 225.052 1830.93 214.514C1815.94 203.976 1803.6 189.491 1795.05 172.384C1786.5 155.278 1782.02 136.098 1782 116.603C1781.98 97.1071 1786.44 77.9188 1794.96 60.7961C1803.48 43.6734 1815.8 29.1639 1830.78 18.5969C1845.75 8.03 1862.91 1.74359 1880.69 0.313737C1892.95 -0.672637 1905.23 0.673905 1917 4.24892V47H1917.33L1917.33 47.0002C1907.21 47.0198 1897.19 48.8168 1887.84 52.2904C1878.44 55.7831 1869.9 60.9024 1862.7 67.3561C1855.5 73.8097 1849.79 81.4714 1845.9 89.9035C1842 98.3356 1840 107.373 1840 116.5C1840 125.627 1842 134.664 1845.9 143.096C1849.79 151.529 1855.5 159.19 1862.7 165.644C1869.9 172.098 1878.44 177.217 1887.84 180.71C1897.23 184.199 1907.3 185.996 1917.47 186L1932.68 222.502Z"
      />
      <rect
        x="1486"
        y="233"
        width="47"
        height="20"
        transform="rotate(-90 1486 233)"
      />
    </g>
    <defs>
      <clipPath id="clip0_255_107">
        <rect width="2861" height="233" />
      </clipPath>
    </defs>
  </svg>
);

export const ad_logo_stacked = (
  <svg
    viewBox="0 0 1461 588"
    fill="#121212"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_299_92)">
      <path d="M1088 355H1145.76L1235 588H1177.24L1088 355Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1071.95 399.989L992 588H1049.63L1099.22 471.375L1071.95 399.989Z"
      />
      <rect x="840" y="429" width="58" height="159" />
      <path d="M0 429H58V588H0V429Z" />
      <rect x="537" y="471" width="58" height="117" />
      <rect x="273" y="355" width="58" height="233" />
      <rect x="657" y="355" width="58" height="233" />
      <rect x="1290" y="355" width="58" height="233" />
      <rect
        x="1290"
        y="588"
        width="47"
        height="171"
        transform="rotate(-90 1290 588)"
      />
      <rect
        x="770"
        y="402"
        width="47"
        height="198"
        transform="rotate(-90 770 402)"
      />
      <rect y="402" width="47" height="106" transform="rotate(-90 0 402)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.3184 365.498C78.2542 357.55 95.7359 354.024 113.215 355.232C130.694 356.44 147.631 362.345 162.526 372.423C177.421 382.502 189.813 396.443 198.606 413.012C207.399 429.582 212.32 448.267 212.935 467.414C213.549 486.561 209.837 505.578 202.128 522.78C194.419 539.983 182.95 554.84 168.738 566.036C154.526 577.233 138.009 584.422 120.65 586.967C109.081 588.664 97.3771 588.263 86 585.821V540.581C93.2596 539.862 100.384 538.226 107.158 535.71C116.561 532.217 125.104 527.098 132.301 520.644C139.497 514.19 145.206 506.529 149.101 498.097C152.995 489.664 155 480.627 155 471.5C155 462.373 152.995 453.336 149.101 444.904C145.206 436.471 139.497 428.81 132.301 422.356C125.104 415.902 116.561 410.783 107.158 407.29C97.7653 403.801 87.6989 402.004 77.5324 402L62.3184 365.498Z"
      />
      <rect
        x="595"
        y="541"
        width="47"
        height="106"
        transform="rotate(90 595 541)"
      />
      <rect
        x="595"
        y="355"
        width="47"
        height="106"
        transform="rotate(90 595 355)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M532.682 577.502C516.465 585.59 498.653 589.097 480.876 587.701C463.1 586.306 445.928 580.052 430.933 569.514C415.939 558.976 403.602 544.491 395.053 527.384C386.504 510.278 382.016 491.098 382 471.603C381.984 452.107 386.441 432.919 394.963 415.796C403.485 398.673 415.798 384.164 430.775 373.597C445.753 363.03 462.915 356.744 480.689 355.314C492.95 354.327 505.233 355.674 517 359.249V402H517.332L517.332 402C507.212 402.02 497.193 403.817 487.842 407.29C478.439 410.783 469.896 415.902 462.699 422.356C455.503 428.81 449.794 436.471 445.899 444.903C442.005 453.336 440 462.373 440 471.5C440 480.627 442.005 489.664 445.899 498.096C449.794 506.529 455.503 514.19 462.699 520.644C469.896 527.098 478.439 532.217 487.842 535.71C497.235 539.199 507.301 540.996 517.468 541L532.682 577.502Z"
      />
      <rect
        x="86"
        y="588"
        width="47"
        height="20"
        transform="rotate(-90 86 588)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1260.96 113C1260.68 122.062 1258.85 131.003 1255.56 139.393C1251.96 148.553 1246.7 156.876 1240.06 163.887C1233.42 170.897 1225.54 176.459 1216.86 180.253C1208.2 184.041 1198.92 185.994 1189.55 186H1128V206V233H1190H1210C1217.12 233 1224.24 232.265 1231.27 230.789C1249.34 226.996 1266.2 218.434 1280.28 205.903C1294.36 193.372 1305.2 177.28 1311.79 159.131C1316.46 146.273 1318.88 132.678 1319 119H1319V118.018V117.981V113H1318.9H1260.96Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1186.04 120C1186.32 110.938 1188.15 101.997 1191.44 93.6074C1195.04 84.4473 1200.3 76.124 1206.94 69.1133C1213.58 62.1025 1221.46 56.5415 1230.14 52.7471C1238.8 48.9587 1248.08 47.0063 1257.45 47L1319 47V27V0L1257 0H1237C1229.88 -2.28882e-05 1222.76 0.735085 1215.73 2.21141C1197.66 6.00438 1180.8 14.5664 1166.72 27.0972C1152.64 39.6284 1141.8 55.7197 1135.21 73.8686C1130.54 86.7275 1128.12 100.322 1128 114H1128V114.982V115.019V120H1128.1L1186.04 120Z"
      />
      <path d="M224 0H281.758L371 233H313.242L224 0Z" />
      <path d="M951 0H1008.76L1098 233H1040.24L951 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.953 44.9888L128 233H185.626L235.222 116.375L207.953 44.9888Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M934.953 44.9888L855 233H912.626L962.222 116.375L934.953 44.9888Z"
      />
      <rect x="451" y="74" width="58" height="159" />
      <rect x="645" width="58" height="233" />
      <rect
        x="645"
        y="233"
        width="47"
        height="171"
        transform="rotate(-90 645 233)"
      />
      <rect
        x="381"
        y="47"
        width="47"
        height="198"
        transform="rotate(-90 381 47)"
      />
    </g>
    <defs>
      <clipPath id="clip0_299_92">
        <rect width="1461" height="588" />
      </clipPath>
    </defs>
  </svg>
);

export const ad_logo_stacked_front = (
  <svg viewBox="0 0 1730 672" fill="#121212" xmlns="http://www.w3.org/2000/svg">
    <path d="M1342.56 133.806C1342.23 144.536 1340.06 155.123 1336.16 165.058C1331.91 175.904 1325.67 185.76 1317.81 194.062C1309.95 202.363 1300.62 208.948 1290.34 213.441C1280.09 217.928 1269.09 220.24 1257.99 220.246H1185.12V275.9H1282.22C1290.64 275.9 1299.08 275.03 1307.41 273.282C1328.8 268.79 1348.77 258.652 1365.44 243.814C1382.11 228.975 1394.95 209.921 1402.75 188.431C1408.28 173.204 1411.15 157.107 1411.28 140.91H1411.29V133.806H1411.17C1411.17 133.806 1411.17 133.806 1411.17 133.806H1342.56Z" />
    <path d="M1253.85 142.094C1254.18 131.364 1256.35 120.777 1260.24 110.842C1264.5 99.9957 1270.73 90.14 1278.6 81.8384C1286.46 73.5369 1295.79 66.9519 1306.06 62.4589C1316.32 57.9725 1327.31 55.6605 1338.41 55.6537H1411.29V3.8147e-06H1314.19C1305.76 -2.67029e-05 1297.33 0.870441 1289 2.61858C1267.61 7.10991 1247.64 17.2484 1230.97 32.0863C1214.3 46.9248 1201.46 65.9789 1193.66 87.4694C1188.13 102.696 1185.26 118.793 1185.13 134.99H1185.12V142.094H1185.24C1185.24 142.094 1185.24 142.094 1185.24 142.094L1253.85 142.094Z" />
    <path d="M114.676 3.60688e-06H183.068L288.741 275.9H220.349L114.676 3.60688e-06Z" />
    <path d="M975.531 3.60688e-06H1043.92L1149.6 275.9H1081.2L975.531 3.60688e-06Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M95.6744 53.2721L1 275.9H69.2366L127.964 137.802L95.6744 53.2721Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M956.53 53.2721L861.856 275.9H930.092L988.82 137.802L956.53 53.2721Z" />
    <path d="M383.471 87.6249H452.15V275.9H383.471V87.6249Z" />
    <path d="M613.19 3.60688e-06H681.869V275.9H613.19V3.60688e-06Z" />
    <path d="M613.19 275.9V220.246H815.675V275.9H613.19Z" />
    <path d="M300.582 55.6537V0H535.038V55.6537H300.582Z" />
    <path d="M1288.32 395.496H1356.72L1462.39 671.396H1394L1288.32 395.496Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1269.32 448.768L1174.65 671.396H1242.88L1301.61 533.298L1269.32 448.768Z" />
    <path d="M994.661 483.121H1063.34V671.396H994.661V483.121Z" />
    <path d="M1.18412 483.121H69.8631V671.396H1.18412V483.121Z" />
    <path d="M635.873 532.854H704.552V671.396H635.873V532.854Z" />
    <path d="M323.265 395.496H391.944V671.396H323.265V395.496Z" />
    <path d="M777.967 395.496H846.646V671.396H777.967V395.496Z" />
    <path d="M1527.52 395.496H1596.19V671.396H1527.52V395.496Z" />
    <path d="M1527.52 671.396V615.743H1730V671.396H1527.52Z" />
    <path d="M911.773 451.15V395.496H1146.23V451.15H911.773Z" />
    <path d="M0 451.15V395.496H125.517V451.15H0Z" />
    <path d="M73.7924 407.927C92.6624 398.516 113.363 394.34 134.06 395.771C154.758 397.201 174.813 404.193 192.45 416.127C210.088 428.062 224.762 444.57 235.174 464.19C245.585 483.81 251.413 505.936 252.14 528.608C252.868 551.28 248.472 573.798 239.344 594.168C230.215 614.538 216.635 632.131 199.806 645.389C182.977 658.646 163.419 667.159 142.864 670.173C129.166 672.182 115.306 671.707 101.834 668.816V615.246C110.431 614.396 118.867 612.458 126.888 609.478C138.022 605.342 148.139 599.28 156.66 591.639C165.182 583.997 171.941 574.924 176.553 564.94C181.165 554.955 183.539 544.254 183.539 533.446C183.539 522.639 181.165 511.937 176.553 501.953C171.941 491.968 165.182 482.896 156.66 475.254C148.139 467.612 138.022 461.55 126.888 457.414C115.766 453.283 103.846 451.154 91.8078 451.15L73.7924 407.927Z" />
    <path d="M704.552 615.743L704.552 671.396L579.035 671.396V615.743H704.552Z" />
    <path d="M704.552 395.496V451.15H579.035V395.496H704.552Z" />
    <path d="M630.759 658.965C611.557 668.542 590.465 672.695 569.416 671.042C548.366 669.39 528.032 661.985 510.277 649.507C492.522 637.029 477.914 619.876 467.79 599.62C457.667 579.364 452.353 556.653 452.334 533.568C452.315 510.483 457.593 487.761 467.684 467.486C477.774 447.211 492.355 430.03 510.09 417.517C527.825 405.005 548.147 397.561 569.194 395.868C583.713 394.7 598.256 396.294 612.19 400.527V451.15H612.584C600.6 451.173 588.736 453.301 577.664 457.414C566.53 461.55 556.413 467.612 547.891 475.254C539.37 482.896 532.61 491.968 527.998 501.953C523.387 511.937 521.013 522.639 521.013 533.446C521.013 544.253 523.387 554.955 527.998 564.94C532.61 574.924 539.37 583.997 547.891 591.638C556.413 599.28 566.53 605.342 577.664 609.478C588.786 613.609 600.706 615.738 612.744 615.742L630.759 658.965Z" />
    <path d="M101.834 671.396V615.743H125.517V671.396H101.834Z" />
  </svg>
);

export const event_complete = (
  <svg viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37.5" cy="37.5" r="37.5" fill="#1DAFEC" />
    <path
      d="M35.075 43.3253L41.4458 36.9545C41.8125 36.5878 42.2556 36.4045 42.775 36.4045C43.2944 36.4045 43.7375 36.5878 44.1042 36.9545C44.4708 37.3212 44.6542 37.7642 44.6542 38.2837C44.6542 38.8031 44.4708 39.2462 44.1042 39.6128L36.3583 47.3587C35.9917 47.7253 35.5639 47.9087 35.075 47.9087C34.5861 47.9087 34.1583 47.7253 33.7917 47.3587L29.8958 43.4628C29.5292 43.0962 29.3458 42.6531 29.3458 42.1337C29.3458 41.6142 29.5292 41.1712 29.8958 40.8045C30.2625 40.4378 30.7056 40.2545 31.225 40.2545C31.7444 40.2545 32.1875 40.4378 32.5542 40.8045L35.075 43.3253ZM24.1667 55.3337C23.1583 55.3337 22.2954 54.9749 21.578 54.2575C20.8606 53.5401 20.5012 52.6766 20.5 51.667V26.0003C20.5 24.992 20.8593 24.1291 21.578 23.4117C22.2967 22.6942 23.1596 22.3349 24.1667 22.3337H26V20.5003C26 19.9809 26.176 19.5458 26.528 19.195C26.88 18.8442 27.3151 18.6682 27.8333 18.667C28.3516 18.6658 28.7873 18.8418 29.1405 19.195C29.4937 19.5482 29.6691 19.9833 29.6667 20.5003V22.3337H44.3333V20.5003C44.3333 19.9809 44.5093 19.5458 44.8613 19.195C45.2133 18.8442 45.6484 18.6682 46.1667 18.667C46.6849 18.6658 47.1206 18.8418 47.4738 19.195C47.8271 19.5482 48.0024 19.9833 48 20.5003V22.3337H49.8333C50.8417 22.3337 51.7052 22.693 52.4238 23.4117C53.1425 24.1303 53.5012 24.9932 53.5 26.0003V51.667C53.5 52.6753 53.1413 53.5388 52.4238 54.2575C51.7064 54.9762 50.8429 55.3349 49.8333 55.3337H24.1667ZM24.1667 51.667H49.8333V33.3337H24.1667V51.667ZM24.1667 29.667H49.8333V26.0003H24.1667V29.667Z"
      fill="white"
    />
  </svg>
);

export const event_future = (
  <svg viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37.5" cy="37.5" r="37.5" fill="#121212" fillOpacity="0.5" />
    <path
      d="M22 31H51.3333M22 31V47.1333C22 49.1867 22 50.2133 22.3997 50.998C22.7512 51.6879 23.3121 52.2488 24.002 52.6003C24.7848 53 25.8115 53 27.8612 53H45.4722C47.5218 53 48.5467 53 49.3295 52.6003C50.0207 52.2483 50.5817 51.6873 50.9337 50.998C51.3333 50.2133 51.3333 49.1903 51.3333 47.1407V31M22 31V29.5333C22 27.48 22 26.4533 22.3997 25.6687C22.7517 24.9775 23.3108 24.4183 24.002 24.0663C24.7867 23.6667 25.8133 23.6667 27.8667 23.6667H29.3333M51.3333 31V29.5278C51.3333 27.4782 51.3333 26.4515 50.9337 25.6687C50.5816 24.9786 50.02 24.4177 49.3295 24.0663C48.5467 23.6667 47.52 23.6667 45.4667 23.6667H44M29.3333 23.6667H44M29.3333 23.6667V20M44 23.6667V20M36.2083 45.6667C36.3299 45.6667 36.4465 45.6184 36.5324 45.5324C36.6184 45.4465 36.6667 45.3299 36.6667 45.2083V38.7917C36.6667 38.6701 36.6184 38.5535 36.5324 38.4676C36.4465 38.3816 36.3299 38.3333 36.2083 38.3333H29.7917C29.6701 38.3333 29.5535 38.3816 29.4676 38.4676C29.3816 38.5535 29.3333 38.6701 29.3333 38.7917V45.2083C29.3333 45.4613 29.5387 45.6667 29.7917 45.6667H36.2083Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


export const defaultPfpSvg = (
  <svg width="517" height="602" viewBox="0 0 517 602" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M85.1727 458.624C78.4974 470.84 88.0786 484.729 101.999 484.729H414.178C428.098 484.729 437.68 470.84 431.004 458.624C397.589 397.476 332.682 356 258.089 356C183.495 356 118.588 397.476 85.1727 458.624Z" fill="white" />
    <circle cx="258.5" cy="210.5" r="92.5" fill="white" />
  </svg>
)
