import { memberPositions, boardPositions, interviewCommittee } from "../data/data";
import { AuthData } from "./AuthWrapper";

export function ApplicantElement({ children }) {
  const { user } = AuthData();
  if (user.position === "Applicant") {
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}

export function PostApplicationElement({ children }) {
  const { user } = AuthData();
  if (user.position === "Applicant" && user.round > 0) {
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}

export function MemberElement({ children }) {
  const { user } = AuthData();
  if (memberPositions.has(user.position) || boardPositions.has(user.position)) {
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}

export function BoardElement({ children }) {
  const { user } = AuthData();
  if (boardPositions.has(user.position)) { //FIXME
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}

export function CommitteeElement({ children }) {
  const { user } = AuthData();
  if (interviewCommittee.has(user.email.split('@')[0]) || boardPositions.has(user.position)) {
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}

export function ExecutiveElement({ children }) { //President or director of recruiting
  const { user } = AuthData();
  if (user.position === "President" || user.position === "Director of Recruitment") {
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}

export function PresidentElement({ children }) {
  const { user } = AuthData();
  if (user.position === "President") {
    return <>{children}</>;
  } else {
    return <div>You do not have access to this page</div>;
  }
}
