import React, { useEffect, useState } from "react";
// import { recruitingEvents } from "../data/data";
import { getExecutive, getUserProfile } from "../libs/firebaseCalls";
import { AuthData } from "./AuthWrapper";
import { Loading } from "./Loading"
import "../css/ApplicantHome.css";
// import { defaultPfp } from "../data/data.js";
// import { Timeline } from "./Timeline.js";

const MAX_TIMEOUT = 2 ** 31 - 1; // Maximum value for setTimeout (2147483647 ms)

export const ApplicantHome = () => {
  const { user } = AuthData();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [appStatus, setAppStatus] = useState("");
  const [applicationOpenTime, setApplicationOpenTime] = useState(null);
  const [applicationCloseTime, setApplicationCloseTime] = useState(null);
  const [toDo, setToDo] = useState([]);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const scheduleTimeout = (delay, callback) => {
      if (delay > MAX_TIMEOUT) {
        setTimeout(() => scheduleTimeout(delay - MAX_TIMEOUT, callback), MAX_TIMEOUT);
      } else {
        setTimeout(callback, delay);
      }
    };

    const fetchExecutiveData = async () => {
      try {
        setLoading(true);
        const executiveDetails = await getExecutive();
        const applicationOpen = executiveDetails.applicationOpen.toDate();
        const applicationClose = executiveDetails.applicationClose.toDate();
        const currentTime = new Date();
        setApplicationOpenTime(applicationOpen);
        setApplicationCloseTime(applicationClose);

        if (currentTime < applicationOpen) {
          setAppStatus("notOpen");
          scheduleTimeout(applicationOpen - currentTime, () => setAppStatus("open"));
        } else if (currentTime >= applicationOpen && currentTime <= applicationClose) {
          setAppStatus("open");
          scheduleTimeout(applicationClose - currentTime, () => setAppStatus("closed"));
        } else {
          setAppStatus("closed");
        }

        setLoadingProfile(true)
        const userProfile = await getUserProfile(user.email, user.position !== "Applicant");
        setProfile(userProfile);
        let newTasks = [];
        let counter = 0;
        let total = 0;
        if (!userProfile.pfpURL) {
          newTasks.push("Upload Your Profile Picture");
          counter += 1;
        }
        total += 1
        if (!userProfile.resumeURL) {
          newTasks.push("Upload Your Resume");
          counter += 1;
        }
        total += 1;
        if (!userProfile.first) {
          newTasks.push("Add Your First Name");
          counter += 0.5;
        }
        total += 0.5;
        if (!userProfile.last) {
          newTasks.push("Add Your Last Name");
          counter += 0.5;
        }
        total += 0.5;
        for (let i = 0; i < executiveDetails.essayQuestions.length; i++) {
          if (!userProfile.essayResponses[i]) {
            newTasks.push(`Write a Response for the "${executiveDetails.essayQuestions[i].prompt}" Essay`);
            counter += 1;
          }
          total += 1;
        }
        if (!userProfile.major || !userProfile.major.length) {
          newTasks.push("Add Your Major");
          counter += 1;
        }
        total += 1;
        setPercent(Math.round((total - counter) / total * 100));
        setToDo(newTasks);
      } catch (error) {
        console.error("Error fetching executive details:", error);
        setAppStatus("closed");
      } finally {
        setLoading(false);
        setLoadingProfile(false);
      }
    };

    fetchExecutiveData();
  }, [user.email, user.position]);

  useEffect(() => {

  }, [profile])

  const formatDateTime = (date) => {
    const options = {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  if (loading || loadingProfile) {
    // if(true){
    return <Loading />
  }

  return (
    <div className="space-y-12 font-kanit ">
      <div className="text-5xl font-bold">Hi {user.uniqname}!</div>
      {appStatus === "notOpen" ? ( 
      // {appStatus !== "notOpen" ? (
        <div className="text-2xl bold uppercase bg-ad-light-blue/50 rounded-xl shadow-lg p-4 w-fit">Application is not yet open</div>
      ) : appStatus === "closed" ? (
        <div className="text-2xl bold uppercase bg-ad-light-blue/50 rounded-xl shadow-lg p-4 w-fit">Application has closed</div>
      ) : (
        <>
          {/* Progress Bar */}
          <div className="flex space-x-4 items-center">
            <div className="font-bold uppercase ">Progress</div>
            <div className="bg-ad-light-blue/50 rounded-full w-full shadow-lg">
              <div
                className="bg-gradient-to-r from-ad-dark-blue to-ad-blue font-bold rounded-full text-center text-white flex items-center justify-center py-1 px-4"
                style={{ width: `${percent}%` }}
              >
                {percent}%
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 ">
            <div className="md:w-1/2 text-center bg-ad-light-blue/50 rounded-xl p-4 space-y-2 shadow-lg">


              {toDo.length === 0 ?
                (
                  <div className="flex flex-row justify-between h-[100%] items-center">
                    <div className="flex flex-col w-[100%] gap-4">
                      {/* <p className="w-[100%] text-left" style={{ fontSize: "35px", textDecorationLine: 'underline' }}>TO DO:</p> */}
                      <div className="uppercase bold text-2xl">All Tasks Complete</div>
                      <div className="">Thanks for taking the time to apply! Feel free to update your responses or profile until the application deadline.</div>
                    </div>
                    {/* <img src="/Theme0.png" alt="theme" className="w-[50%]" /> */}
                  </div>
                )
                :
                (
                  <>
                    <div className="uppercase bold text-2xl">Tasks Remaining</div>
                    {toDo.map((task, idx) => {
                      return <div key={idx}>
                        <div className="flex space-x-4"><div>-</div>
                          <div className="text-left">{task}</div>
                        </div>
                      </div>
                    })}
                  </>
                )
              }

            </div>
            <div className="md:w-1/2 space-y-4">

              <div className="bg-ad-light-blue/50 shadow-lg rounded-xl p-4">
                <div className="flex space-x-4">
                  <div className="bold">Application Opens: </div>
                  <div>{applicationOpenTime ? formatDateTime(applicationOpenTime) : "Loading..."}</div>
                </div>
                <div className="flex space-x-4">
                  <div className="bold">Application Deadline: </div>
                  <div>{applicationCloseTime ? formatDateTime(applicationCloseTime) : "Loading..."}</div>
                </div>
              </div>


              <div className="bg-ad-light-blue/50 shadow-lg rounded-xl p-4 text-red-500">
                You may modify your essays responses and application profile up to (but not past) the application deadline.
              </div>
              <div className="bg-ad-light-blue/50 shadow-lg rounded-xl p-4">
                We're excited to have you as the first users of our new portal this semester! As it's still in beta, there may be a few bugs or glitches. If you encounter any, please let us know at <a href="mailto:seojuny@umich.edu" className="text-blue-600 underline"> seojuny@umich.edu</a>.
                To ensure we receive your application, please fill out our <a href="https://docs.google.com/forms/d/e/1FAIpQLSexjbp_9O0Wtg4SMoW1HzIaCcaDxKGfHjQiy_PcKDA-lX9tow/viewform?usp=sf_link" target="_blank" rel="noreferrer noopener" className="text-blue-600 underline">Google form</a> in addition to submitting it on the portal.
              </div>


            </div>
          </div>
        </>
      )}
    </div>
  );
};
